import request from '@/utils/request';
import jsonToFormData from 'json-form-data';

export function getNoticeList() {
  return request.post('/api/permission/noticerecord/page', {});
}

export function saveNotice(data: any) {
  const api = data.noticeId ? '/api/permission/noticerecord/update' : '/api/permission/noticerecord/add'
  console.log('data: ', data)
  return request.post(api, data);
}

export function getNoticeDetail(noticeId: string) {
  return request.post('/api/permission/noticerecord/detail', { noticeId })
}

export function deleteNotice(noticeId: string) {
  return request.post('/api/permission/noticerecord/delete', [noticeId])
}