import { useEffect, useState } from 'react';
import { Space, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { getDeviceList } from '@/services/energy';
import styled from 'styled-components';
import AirConditioningCtrl from '@/screens/EnergyManagement/RemoteControl/AirConditioning';
import WifiCtrl from '@/screens/EnergyManagement/RemoteControl/Wifi';
import LightCtrl from '@/screens/EnergyManagement/RemoteControl/Light';
import { turnOffDeviceByRoomNo, turnOnDeviceByRoomNo } from '@/services/device';

const Container = styled.div`
  
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 17px;
`

const DeviceListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const BtnClose = styled.div`
  cursor: pointer;
`

const BtnCircle = styled.div`
  border-radius: 11px;
  width: 76px;
  height: 22px;
  font-size: 14px;
  border: 1px solid #C2C5CA;
  text-align: center;
  color: #8F959E;
  line-height: 20px;
  cursor: pointer;
`

const BtnText = styled.div`

`

const DeviceContainer = styled.div`
  padding: 6px;
`

interface Props {
  roomNo: string;
  deviceList: any[];
  onClose: () => void;
}

const RoomInfo: React.FC<Props> = ({
  roomNo,
  onClose
}) => {
  const [deviceList, setDeviceList] = useState<any>([]);

  const turnOff = () => {
    Modal.confirm({
      title: '一键关闭',
      content: '是否关闭房间内所有设备?',
      onOk: () => {
        turnOffDeviceByRoomNo(roomNo)
        fetchData()
      }
    })
  }

  const turnOn = () => {
    Modal.confirm({
      title: '一键开启',
      content: '是否开启房间内所有设备?',
      onOk: () => {
        turnOnDeviceByRoomNo(roomNo);
        fetchData()
      }
    })
  }

  const fetchData = () => {
    getDeviceList(roomNo).then((res: any) => {
      console.log('getDeviceList: ', res);
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDeviceList(data);
      }
    })
  }

  useEffect(() => {
    if (roomNo) {
      fetchData();
    }
    
  }, [roomNo])

  return (
    <Container>
      <Header>
        <BtnClose onClick={onClose}><CloseOutlined /></BtnClose>
        <Space>
          <BtnCircle onClick={turnOn}>一键开启</BtnCircle>
          <BtnCircle onClick={turnOff}>一键关闭</BtnCircle>
        </Space>
        <Space>
          <BtnText />
        </Space>
      </Header>
      <DeviceListContainer>
        {
          deviceList.map((item: any) => {
            // 门禁
            if (item.deviceType === 2) {
              return 
            }

            let ctrl = <></>

            switch(item.deviceType) {
              case 4:
                ctrl = <AirConditioningCtrl deviceId={item.deviceId} deviceNo={item.deviceNo} isOpen={item.switchStatus == 'on'} onToggle={fetchData}  />;
                break;
              case 3:
                ctrl = <LightCtrl deviceId={item.deviceId} deviceNo={item.deviceNo} isOpen={item.switchStatus == 'on'} onToggle={fetchData} />;
                break;
              case 0:
                ctrl = <WifiCtrl deviceId={item.deviceId} deviceNo={item.deviceNo} />;
                break;
            }

            return (
              <DeviceContainer>{ctrl}</DeviceContainer>
            )
          })
        }
      </DeviceListContainer>
    </Container>
  )
}

export default RoomInfo;