import { Column, ColumnConfig } from '@ant-design/charts';
import { Card, DatePicker, Space, Tabs } from 'antd';
import styled from 'styled-components';
import { getElectricChargeList } from '@/services/electric';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
// import CubeSaveCost from './CubeSaveCost';
// import CubeSaveEnergy from './CubeSaveEnergy';
// import CubeElectric from './CubeElectric';
import Cube from './Cube2';
import { getMonthlyBillStat, getDailyBillStatOfMonth } from '@/services/bill';
import { billItemTypeMap } from '@/common/constants';
import { last } from 'lodash';

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;
`

const CubesBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify_content: flex-start;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
`

const Dashboard: React.FC = () => {
  const config: ColumnConfig = {
    data: [],
    padding: 'auto',
    // stack: {
    //   groupBy: ['x', 'series'],
    //   series: false,
    // },
    group: true,
    seriesField: 'type',
    xField: 'billDateSign',
    colorField: 'type',
    yField: 'value',
    meta: {
      date: { alias: '日期' },
      value: { alias: '金额' },
    },
  };
  
  const [queryDate, setQueryDate] = useState<dayjs.Dayjs | null>(dayjs())
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [chartDataSource, setChartDataSource] = useState<any[]>([]);

  const fetchData = async () => {
    // TODO: 取当前月份数据和上月的数据，按照mock的数据拼接出列表
    const res1 = await getMonthlyBillStat(queryDate!.subtract(1, 'month').format('YYYYMM'));
    let lastMonthBillList = [];
    if (res1?.data?.resultCode == 0) {
      lastMonthBillList = res1.data.data
    }

    const res2 = await getMonthlyBillStat(queryDate!.format('YYYYMM'));
    let currentMonthBillList = [];
    if (res2?.data.resultCode == 0) {
      currentMonthBillList = res2.data.data;
    }

    const billTypeList: Array<string> = [];
    for(let t of lastMonthBillList.concat(currentMonthBillList).map((item: any) => item.itemType)) {
      if (!billTypeList.includes(t)) {
        billTypeList.push(t);
      }
    }

    console.log('billTypeList: ', billTypeList);
    const result = [];

    for(let t of billTypeList) {
      let lastValue = lastMonthBillList.filter((item: any) => item.itemType === t).reduce((prev: number, next: any) => prev + next.payableAmount, 0)
      let currentValue = currentMonthBillList.filter((item: any) => item.itemType === t).reduce((prev: number, next: any) => prev + next.payableAmount, 0)
      let increaseRate = (currentValue - lastValue) / lastValue * 100;
      result.push({
        itemType: t,
        lastMonth: {
          label: queryDate!.subtract(1, 'month').format('M月'),
          value: lastValue?.toFixed(2) ?? 0.00
        },
        currentMonth: {
          label: queryDate!.format('M月'),
          value: currentValue?.toFixed(2) ?? 0.00
        },
        increaseRate: isNaN(increaseRate) ? '0.00' : increaseRate.toFixed(2)
      })
    }

    setDataSource(result)
  }

  const fetchChartData = () => {
    getDailyBillStatOfMonth(queryDate!.format('YYYYMM')).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode == 0) {
        const result: Array<any> = []
        const billTypeList: Array<string> = []
        for (let d of data) {
          if (!billTypeList.includes(d.itemType)) {
            billTypeList.push(d.itemType)
          }
        }
        const bb = [];
        for (let d of data) {
          bb.push({
            billDateSign: d.billDateSign?.toString().substring(4),
            itemType: d.itemType,
            type: `${billItemTypeMap[d.itemType]}-减免费用`,
            value: d.freeAmount,
          })
          bb.push({
            billDateSign: d.billDateSign?.toString().substring(4),
            itemType: d.itemType,
            type: `${billItemTypeMap[d.itemType]}-应付费用`,
            value: d.payableAmount,
          })
        }

        for (let t of billTypeList) {
          result.push({
            itemType: t,
            data: bb.filter((item: any) => item.itemType === t)
          })
        }

        setChartDataSource(result)
      }
    })
  }

  useEffect(() => {
    if (queryDate) {
      fetchData();
      fetchChartData();
    }
  }, [queryDate]);

  return (
    <>
      <SearchBar>
        <DatePicker picker="month" value={queryDate} onChange={val => setQueryDate(val)} />
      </SearchBar>
      <CubesBar>
        {dataSource.map((item: any, index: number) => {
          return (
            <Cube
              key={`cube_${index}`}
              title={billItemTypeMap[item.itemType]}
              desc={`环比 ${item.increaseRate}%`}
              data={[
                item.lastMonth,
                // item.currentMonth,
              ]}
              increaseRate={item.increaseRate}
            />
          )
        })}
      </CubesBar>
      <Card>
        <Tabs>
          <Tabs.TabPane tab="费用统计" key="dashboard_tab_1">
            {
              chartDataSource.map((item: any, index: number) => {
                return <Column key={`dashboard_column_chart_${index}`} {...config} data={item.data} />
              })
            }
          </Tabs.TabPane>
        </Tabs>
        
      </Card>
    </>
  );
}

export default Dashboard