// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import { composeWithDevTools } from '@redux-devtools/extension'
// import rootReducer from './reducers';

// export default function configureStore() {
//   const middlewares = [thunk];

//   // if (process.env.NODE_ENV === 'development') {
//   //   const { createLogger } = require('redux-logger');
//   //   middlewares.push(createLogger());
//   // }

//   return createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
// }

import { configureStore } from '@reduxjs/toolkit'
import commonReducer from './features/common'
import companyReducer from './features/company'
import employeeReducer from './features/employee'
import knowledgeReducer from './features/knowledge'
import userReducer from './features/user'
import workspaceReducer from './features/workspace'

const store = configureStore({
  reducer: {
    common: commonReducer,
    company: companyReducer,
    employee: employeeReducer,
    knowledge: knowledgeReducer,
    user: userReducer,
    workspace: workspaceReducer,
  }
})

export default store