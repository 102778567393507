import bg from '@/assets/energy/floors/10/10.png';
import img_1000_1_common from '@/assets/energy/floors/10/common/1000-1.png';
import img_1000_2_common from '@/assets/energy/floors/10/common/1000-2.png';
import img_1000_3_common from '@/assets/energy/floors/10/common/1000-3.png';
import img_1000_4_common from '@/assets/energy/floors/10/common/1000-4.png';
import img_1000_5_common from '@/assets/energy/floors/10/common/1000-5.png';
import img_1000_6_common from '@/assets/energy/floors/10/common/1000-6.png';
import img_1000_7_common from '@/assets/energy/floors/10/common/1000-7.png';
import img_1000_8_common from '@/assets/energy/floors/10/common/1000-8.png';
import img_1001_1_common from '@/assets/energy/floors/10/common/1001-1.png';
import img_1001_2_common from '@/assets/energy/floors/10/common/1001-2.png';
import img_1001_3_common from '@/assets/energy/floors/10/common/1001-3.png';
import img_1001_4_common from '@/assets/energy/floors/10/common/1001-4.png';
import img_1002_1_common from '@/assets/energy/floors/10/common/1002-1.png';
import img_1002_2_common from '@/assets/energy/floors/10/common/1002-2.png';
import img_1002_3_common from '@/assets/energy/floors/10/common/1002-3.png';
import img_1002_4_common from '@/assets/energy/floors/10/common/1002-4.png';
import img_1003_1_common from '@/assets/energy/floors/10/common/1003-1.png';
import img_1003_2_common from '@/assets/energy/floors/10/common/1003-2.png';
import img_1003_3_common from '@/assets/energy/floors/10/common/1003-3.png';
import img_1003_4_common from '@/assets/energy/floors/10/common/1003-4.png';
import img_1003_5_common from '@/assets/energy/floors/10/common/1003-5.png';
import img_1005_1_common from '@/assets/energy/floors/10/common/1005-1.png';
import img_1005_2_common from '@/assets/energy/floors/10/common/1005-2.png';
import img_1005_3_common from '@/assets/energy/floors/10/common/1005-3.png';
import img_1005_4_common from '@/assets/energy/floors/10/common/1005-4.png';
import img_1005_5_common from '@/assets/energy/floors/10/common/1005-5.png';
import img_1006_1_common from '@/assets/energy/floors/10/common/1006-1.png';
import img_1006_2_common from '@/assets/energy/floors/10/common/1006-2.png';
import img_1006_3_common from '@/assets/energy/floors/10/common/1006-3.png';
import img_1006_4_common from '@/assets/energy/floors/10/common/1006-4.png';
import img_1007_1_common from '@/assets/energy/floors/10/common/1007-1.png';
import img_1007_2_common from '@/assets/energy/floors/10/common/1007-2.png';
import img_1007_3_common from '@/assets/energy/floors/10/common/1007-3.png';
import img_1007_4_common from '@/assets/energy/floors/10/common/1007-4.png';
import img_1007_5_common from '@/assets/energy/floors/10/common/1007-5.png';
import img_1007_6_common from '@/assets/energy/floors/10/common/1007-6.png';
import img_1007_7_common from '@/assets/energy/floors/10/common/1007-7.png';
import img_1030_common from '@/assets/energy/floors/10/common/1030.png';
import img_1031_common from '@/assets/energy/floors/10/common/1031.png';
import img_1032_common from '@/assets/energy/floors/10/common/1032.png';
import img_1033_common from '@/assets/energy/floors/10/common/1033.png';
import img_1034_common from '@/assets/energy/floors/10/common/1034.png';
import img_1035_common from '@/assets/energy/floors/10/common/1035.png';
import img_1036_common from '@/assets/energy/floors/10/common/1036.png';
import img_1037_common from '@/assets/energy/floors/10/common/1037.png';
import img_1038_common from '@/assets/energy/floors/10/common/1038.png';
import img_1039_common from '@/assets/energy/floors/10/common/1039.png';
import img_1040_common from '@/assets/energy/floors/10/common/1040.png';
import img_1041_common from '@/assets/energy/floors/10/common/1041.png';

import img_1000_1_selected from '@/assets/energy/floors/10/selected/1000-1.png';
import img_1000_2_selected from '@/assets/energy/floors/10/selected/1000-2.png';
import img_1000_3_selected from '@/assets/energy/floors/10/selected/1000-3.png';
import img_1000_4_selected from '@/assets/energy/floors/10/selected/1000-4.png';
import img_1000_5_selected from '@/assets/energy/floors/10/selected/1000-5.png';
import img_1000_6_selected from '@/assets/energy/floors/10/selected/1000-6.png';
import img_1000_7_selected from '@/assets/energy/floors/10/selected/1000-7.png';
import img_1000_8_selected from '@/assets/energy/floors/10/selected/1000-8.png';
import img_1001_1_selected from '@/assets/energy/floors/10/selected/1001-1.png';
import img_1001_2_selected from '@/assets/energy/floors/10/selected/1001-2.png';
import img_1001_3_selected from '@/assets/energy/floors/10/selected/1001-3.png';
import img_1001_4_selected from '@/assets/energy/floors/10/selected/1001-4.png';
import img_1002_1_selected from '@/assets/energy/floors/10/selected/1002-1.png';
import img_1002_2_selected from '@/assets/energy/floors/10/selected/1002-2.png';
import img_1002_3_selected from '@/assets/energy/floors/10/selected/1002-3.png';
import img_1002_4_selected from '@/assets/energy/floors/10/selected/1002-4.png';
import img_1003_1_selected from '@/assets/energy/floors/10/selected/1003-1.png';
import img_1003_2_selected from '@/assets/energy/floors/10/selected/1003-2.png';
import img_1003_3_selected from '@/assets/energy/floors/10/selected/1003-3.png';
import img_1003_4_selected from '@/assets/energy/floors/10/selected/1003-4.png';
import img_1003_5_selected from '@/assets/energy/floors/10/selected/1003-5.png';
import img_1005_1_selected from '@/assets/energy/floors/10/selected/1005-1.png';
import img_1005_2_selected from '@/assets/energy/floors/10/selected/1005-2.png';
import img_1005_3_selected from '@/assets/energy/floors/10/selected/1005-3.png';
import img_1005_4_selected from '@/assets/energy/floors/10/selected/1005-4.png';
import img_1005_5_selected from '@/assets/energy/floors/10/selected/1005-5.png';
import img_1006_1_selected from '@/assets/energy/floors/10/selected/1006-1.png';
import img_1006_2_selected from '@/assets/energy/floors/10/selected/1006-2.png';
import img_1006_3_selected from '@/assets/energy/floors/10/selected/1006-3.png';
import img_1006_4_selected from '@/assets/energy/floors/10/selected/1006-4.png';
import img_1007_1_selected from '@/assets/energy/floors/10/selected/1007-1.png';
import img_1007_2_selected from '@/assets/energy/floors/10/selected/1007-2.png';
import img_1007_3_selected from '@/assets/energy/floors/10/selected/1007-3.png';
import img_1007_4_selected from '@/assets/energy/floors/10/selected/1007-4.png';
import img_1007_5_selected from '@/assets/energy/floors/10/selected/1007-5.png';
import img_1007_6_selected from '@/assets/energy/floors/10/selected/1007-6.png';
import img_1007_7_selected from '@/assets/energy/floors/10/selected/1007-7.png';
import img_1030_selected from '@/assets/energy/floors/10/selected/1030.png';
import img_1031_selected from '@/assets/energy/floors/10/selected/1031.png';
import img_1032_selected from '@/assets/energy/floors/10/selected/1032.png';
import img_1033_selected from '@/assets/energy/floors/10/selected/1033.png';
import img_1034_selected from '@/assets/energy/floors/10/selected/1034.png';
import img_1035_selected from '@/assets/energy/floors/10/selected/1035.png';
import img_1036_selected from '@/assets/energy/floors/10/selected/1036.png';
import img_1037_selected from '@/assets/energy/floors/10/selected/1037.png';
import img_1038_selected from '@/assets/energy/floors/10/selected/1038.png';
import img_1039_selected from '@/assets/energy/floors/10/selected/1039.png';
import img_1040_selected from '@/assets/energy/floors/10/selected/1040.png';
import img_1041_selected from '@/assets/energy/floors/10/selected/1041.png';

const width = (w: number) => `${100 * (w / 768)}%`;
const left = (l: number) => `${100 * (l - 398) / 768}%`;
const top = (t: number) => `${100 * (t - 277) / 408}%`;
const fontSize = (w: number, f: number) => f > 12 ? '12px' : '9px'
// {
//   title: '10xx-',
//   roomNo: '10xx-',
//   width: width(w),
//   left: left(),
//   top: top(),
//   fontSize: fontSize(w, 12),
//   color: '#808080',
//   writingMode: 'vertical-lr',
//   image: img_10xx__common,
//   selectedImage: img_10xx__selected,
// },

const initialRoomList = [
  {
    title: '空调机房',
    roomNo: '1030',
    width: width(44),
    left: left(401),
    top: top(280),
    fontSize: fontSize(44, 12),
    writingMode: 'vertical-lr',
    image: img_1030_common,
    selectedImage: img_1030_selected,
  },
  {
    title: '1001-1',
    titleLeft: `${100 * (559 - 447) / 214}%`,
    titleWidth: 'atuo',
    roomNo: '1001-1',
    width: width(214),
    left: left(447),
    top: top(280),
    fontSize: fontSize(214, 16),
    image: img_1001_1_common,
    selectedImage: img_1001_1_selected,
  },
  {
    title: '1001-2',
    roomNo: '1001-2',
    width: width(46),
    left: left(495),
    top: top(280),
    fontSize: fontSize(46, 12),
    image: img_1001_2_common,
    // selectedImage: img_1001_2_selected,
  },
  {
    title: '1001-3',
    roomNo: '1001-3',
    width: width(46),
    left: left(447),
    top: top(280),
    fontSize: fontSize(46, 12),
    image: img_1001_3_common,
    // selectedImage: img_1001_3_selected,
  },
  {
    title: '1001-4',
    roomNo: '1001-4',
    width: width(60),
    left: left(447),
    top: top(328),
    fontSize: fontSize(60, 12),
    image: img_1001_4_common,
    // selectedImage: img_1001_4_selected,
  },
  {
    title: '1002-1',
    titleLeft: `${100 * (714 - 663) / 234}%`,
    titleWidth: 'atuo',
    roomNo: '1002-1',
    width: width(234),
    left: left(663),
    top: top(280),
    fontSize: fontSize(234, 16),
    image: img_1002_1_common,
    selectedImage: img_1002_1_selected,
  },
  {
    title: '1002-2',
    roomNo: '1002-2',
    width: width(46),
    left: left(803),
    top: top(280),
    fontSize: fontSize(46, 12),
    image: img_1002_2_common,
    // selectedImage: img_1002_2_selected,
  },
  {
    title: '1002-3',
    roomNo: '1002-3',
    width: width(46),
    left: left(851),
    top: top(280),
    fontSize: fontSize(46, 12),
    image: img_1002_3_common,
    // selectedImage: img_1002_3_selected,
  },
  {
    title: '1002-4',
    roomNo: '1002-4',
    width: width(60),
    left: left(837),
    top: top(328),
    fontSize: fontSize(60, 12),
    image: img_1002_4_common,
    // selectedImage: img_1002_4_selected,
  },
  {
    title: '1003-1',
    titleLeft: `${100 * (1039 - 899) / 264}%`,
    titleWidth: 'atuo',
    roomNo: '1003-1',
    width: width(264),
    left: left(899),
    top: top(280),
    fontSize: fontSize(264, 16),
    image: img_1003_1_common,
    selectedImage: img_1003_1_selected,
  },
  {
    title: '1003-2',
    roomNo: '1003-2',
    width: width(46),
    left: left(1117),
    top: top(318),
    fontSize: fontSize(46, 12),
    image: img_1003_2_common,
    // selectedImage: img_1003_2_selected,
  },
  {
    title: '1003-3',
    roomNo: '1003-3',
    width: width(50),
    left: left(899),
    top: top(280),
    fontSize: fontSize(50, 12),
    image: img_1003_3_common,
    // selectedImage: img_1003_3_selected,
  },
  {
    title: '1003-4',
    roomNo: '1003-4',
    width: width(60),
    left: left(951),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1003_4_common,
    // selectedImage: img_1003_4_selected,
  },
  {
    title: '1003-5',
    roomNo: '1003-5',
    width: width(46),
    left: left(1117),
    top: top(280),
    fontSize: fontSize(46, 12),
    image: img_1003_5_common,
    // selectedImage: img_1003_5_selected,
  },
  {
    title: '空调机房',
    roomNo: '1036',
    width: width(108),
    left: left(1055),
    top: top(390),
    fontSize: fontSize(108, 12),
    image: img_1036_common,
    selectedImage: img_1036_selected,
  },
  {
    title: '1005-1',
    titleLeft: `${100 * (1084 - 1016) / 147}%`,
    titleTop: `${100 * (504 - 434) / 147}%`,
    titleWidth: 'atuo',
    roomNo: '1005-1',
    width: width(147),
    left: left(1016),
    top: top(434),
    fontSize: fontSize(147, 16),
    image: img_1005_1_common,
    selectedImage: img_1005_1_selected,
  },
  {
    title: '1005-2',
    roomNo: '1005-2',
    width: width(46),
    left: left(1117),
    top: top(596),
    fontSize: fontSize(46, 12),
    image: img_1005_2_common,
    // selectedImage: img_1005_2_selected,
  },
  {
    title: '1005-3',
    roomNo: '1005-3',
    width: width(46),
    left: left(1079),
    top: top(640),
    fontSize: fontSize(46, 12),
    image: img_1005_3_common,
    // selectedImage: img_1005_3_selected,
  },
  {
    title: '1005-4',
    roomNo: '1005-4',
    width: width(46),
    left: left(1031),
    top: top(640),
    fontSize: fontSize(46, 12),
    image: img_1005_4_common,
    // selectedImage: img_1005_4_selected,
  },
  {
    title: '1005-5',
    roomNo: '1005-5',
    width: width(36),
    left: left(1127),
    top: top(640),
    fontSize: fontSize(36, 12),
    image: img_1005_5_common,
    // selectedImage: img_1005_5_selected,
  },
  {
    title: '1006-1',
    titleLeft: `${100 * (790 - 723) / 336}%`,
    titleWidth: 'atuo',
    roomNo: '1006-1',
    width: width(336),
    left: left(723),
    top: top(574),
    fontSize: fontSize(336, 16),
    image: img_1006_1_common,
    selectedImage: img_1006_1_selected,
  },
  {
    title: '1006-2',
    roomNo: '1006-2',
    width: width(46),
    left: left(955),
    top: top(640),
    fontSize: fontSize(46, 12),
    image: img_1006_2_common,
    // selectedImage: img_1006_2_selected,
  },
  {
    title: '1006-3',
    roomNo: '1006-3',
    width: width(46),
    left: left(907),
    top: top(640),
    fontSize: fontSize(46, 12),
    image: img_1006_3_common,
    // selectedImage: img_1006_3_selected,
  },
  {
    title: '1006-4',
    roomNo: '1006-4',
    width: width(68),
    left: left(986),
    top: top(578),
    fontSize: fontSize(68, 12),
    image: img_1006_4_common,
    // selectedImage: img_1006_4_selected,
  },
  {
    title: '1007-1',
    titleTop: `${100 * (618 - 492) / 320}%`,
    roomNo: '1007-1',
    width: width(320),
    left: left(401),
    top: top(492),
    fontSize: fontSize(320, 16),
    image: img_1007_1_common,
    selectedImage: img_1007_1_selected,
  },
  {
    title: '1007-2',
    roomNo: '1007-2',
    width: width(46),
    left: left(401),
    top: top(552),
    fontSize: fontSize(46, 12),
    image: img_1007_2_common,
    // selectedImage: img_1007_2_selected,
  },
  {
    title: '1007-3',
    roomNo: '1007-3',
    width: width(46),
    left: left(675),
    top: top(618),
    fontSize: fontSize(46, 12),
    image: img_1007_3_common,
    // selectedImage: img_1007_3_selected,
  },
  {
    title: '1007-4',
    roomNo: '1007-4',
    width: width(70),
    left: left(440),
    top: top(499),
    fontSize: fontSize(70, 12),
    image: img_1007_4_common,
    // selectedImage: img_1007_4_selected,
  },
  {
    title: '1007-5',
    roomNo: '1007-5',
    width: width(46),
    left: left(401),
    top: top(640),
    fontSize: fontSize(46, 12),
    image: img_1007_5_common,
    // selectedImage: img_1007_5_selected,
  },
  {
    title: '1007-6',
    roomNo: '1007-6',
    width: width(46),
    left: left(401),
    top: top(596),
    fontSize: fontSize(46, 12),
    image: img_1007_6_common,
    // selectedImage: img_1007_6_selected,
  },
  {
    title: '1007-7',
    roomNo: '1007-7',
    width: width(46),
    left: left(675),
    top: top(574),
    fontSize: fontSize(46, 12),
    image: img_1007_7_common,
    // selectedImage: img_1007_7_selected,
  },
  {
    title: '1041',
    titleTop: `${100 * (479 - 467) / 68}%`,
    roomNo: '1041',
    width: width(68),
    left: left(401),
    top: top(467),
    fontSize: fontSize(68, 16),
    image: img_1041_common,
    selectedImage: img_1041_selected,
  },
  {
    title: '1031',
    roomNo: '1031',
    width: width(47),
    left: left(476),
    top: top(424),
    fontSize: fontSize(47, 12),
    image: img_1031_common,
    selectedImage: img_1031_selected,
  },
  {
    title: '1032',
    roomNo: '1032',
    width: width(47),
    left: left(505),
    top: top(453),
    fontSize: fontSize(47, 12),
    image: img_1032_common,
    selectedImage: img_1032_selected,
  },
  {
    title: '1034',
    roomNo: '1034',
    width: width(69),
    left: left(657),
    top: top(418),
    fontSize: fontSize(69, 12),
    image: img_1034_common,
    selectedImage: img_1034_selected,
  },
  {
    title: '1039',
    roomNo: '1039',
    width: width(69),
    left: left(657),
    top: top(496),
    fontSize: fontSize(69, 12),
    image: img_1039_common,
    selectedImage: img_1039_selected,
  },
  {
    title: '1040',
    roomNo: '1040',
    width: width(40),
    left: left(686),
    top: top(468),
    fontSize: fontSize(40, 12),
    image: img_1040_common,
    selectedImage: img_1040_selected,
  },
  {
    title: '1038',
    roomNo: '1038',
    width: width(41),
    left: left(777),
    top: top(513),
    fontSize: fontSize(41, 12),
    image: img_1038_common,
    selectedImage: img_1038_selected,
  },
  {
    title: '1035',
    roomNo: '1035',
    width: width(24),
    left: left(914),
    top: top(418),
    fontSize: fontSize(24, 12),
    image: img_1035_common,
    selectedImage: img_1035_selected,
  },
  {
    title: '1037',
    roomNo: '1037',
    width: width(32),
    left: left(910),
    top: top(497),
    fontSize: fontSize(32, 12),
    image: img_1037_common,
    selectedImage: img_1037_selected,
  },
  {
    title: '1000-4',
    roomNo: '1000-4',
    width: width(22),
    left: left(1029),
    top: top(418),
    fontSize: fontSize(22, 12),
    writingMode: 'vertical-lr',
    color: '#808080',
    image: img_1000_4_common,
    selectedImage: img_1000_4_selected,
  },
  {
    title: '1000-5',
    roomNo: '1000-5',
    width: width(43),
    left: left(823),
    top: top(418),
    fontSize: fontSize(43, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_1000_5_common,
    selectedImage: img_1000_5_selected,
  },
  {
    title: '1000-6',
    roomNo: '1000-6',
    width: width(22),
    left: left(631),
    top: top(418),
    fontSize: fontSize(22, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_1000_6_common,
    selectedImage: img_1000_6_selected,
  },
  {
    title: '1000-1',
    roomNo: '1000-1',
    width: width(480),
    left: left(556),
    top: top(548),
    fontSize: fontSize(480, 12),
    color: '#808080',
    image: img_1000_1_common,
    selectedImage: img_1000_1_selected,
  },
  {
    title: '1000-2',
    titleLeft: `${100 * (826 - 401) / 650}%`,
    titleTop: `${100 * (396 - 392) / 650}%`,
    titleWidth: 'atuo',
    roomNo: '1000-2',
    width: width(650),
    left: left(401),
    top: top(392),
    fontSize: fontSize(650, 12),
    color: '#808080',
    image: img_1000_2_common,
    selectedImage: img_1000_2_selected,
  },
  {
    title: '储藏室',
    roomNo: '1033',
    width: width(95),
    left: left(511),
    top: top(423),
    fontSize: fontSize(95, 12),
    image: img_1033_common,
    selectedImage: img_1033_selected,
  },
  {
    title: '1000-7',
    roomNo: '1000-7',
    width: width(89),
    left: left(532),
    top: top(440),
    fontSize: fontSize(89, 12),
    color: '#808080',
    rotate: '-45deg',
    image: img_1000_7_common,
    selectedImage: img_1000_7_selected,
  },
  {
    title: '1000-8',
    roomNo: '1000-8',
    width: width(101),
    left: left(450),
    top: top(452),
    fontSize: fontSize(101, 12),
    color: '#808080',
    rotate: '45deg',
    image: img_1000_8_common,
    selectedImage: img_1000_8_selected,
  },
  {
    title: '茶水休闲区',
    titleTop: `${100 * 36 / 71}%`,
    roomNo: '1000-3',
    width: width(71),
    left: left(556),
    top: top(468),
    fontSize: fontSize(71, 12),
    rotate: '-45deg',
    image: img_1000_3_common,
    selectedImage: img_1000_3_selected,
  },
]

export default {
  bg,
  initialRoomList
}