import { Outlet, createBrowserRouter } from 'react-router-dom';
import { Card } from 'antd';
import Login from './screens/Login';
import QRLogin from './screens/Login/QRLogin';
import Register from './screens/Register';
import MainFrame from './screens/MainFrame';
import CompanyManagement from '@/screens/CompanyManagement';
import CompanyDetail from '@/screens/CompanyManagement/Company/Detail';
import CompanyEdit from '@/screens/CompanyManagement/Company/Edit';
import CompanyList from '@/screens/CompanyManagement/Company/List';
import EmployeeList from '@/screens/CompanyManagement/Employee/List';
import EmployeeDetail from '@/screens/CompanyManagement/Employee/Detail.discard';
import EmployeeEdit from '@/screens/CompanyManagement/Employee/Edit';
import Invitation from '@/screens/CompanyManagement/Employee/Invitation';
import ApplicantList from '@/screens/CompanyManagement/Employee/ApplicantList';
import DataManagement from '@/screens/DataManagement';
// import Invitation from '@/screens/CompanyManagement/Employee/Invitation';
import Breadcrumb from '@/components/Breadcrumb'
// import ApplicantList from '@/screens/CompanyManagement/Employee/ApplicantList';
import Workspace from '@/screens/WorkspaceManagement';
import WorkspaceDetail from '@/screens/WorkspaceManagement/Detail';
import WOrkspaceEdit from '@/screens/WorkspaceManagement/Edit';
import DeviceList from '@/screens/DeviceManagement';
import DeviceEdit from '@/screens/DeviceManagement/Edit';
import DeviceDetail from '@/screens/DeviceManagement/Detail';
import Calendar from '@/screens/WorkspaceManagement/Calendar.discard';
import MeetingRoomManagement from '@/screens/MeetingRoomManagement';
import EnergyManagement from '@/screens/EnergyManagement';
import DailyBill from '@/screens/EnergyManagement/Bill/Daily';
import MonthlyBill from '@/screens/EnergyManagement/Bill/Monthly/MonthlyBill';
import MonthlyBillCalendarMode from '@/screens/EnergyManagement/Bill/Monthly/Calendar';
import InvoiceList from '@/screens/EnergyManagement/Invoice'
import ClearRecord from '@/screens/EnergyManagement/ClearRecord';
import PersonnelFiling from '@/screens/OperationManagement/PersonnelFiling';
import OperateLog from '@/screens/OperationManagement/OperateLog';
import CardManagent from '@/screens/CompanyManagement/Card';
import Knowledge from '@/screens/Knowledge';
import TaskManagement from '@/screens/TaskManagement';
import TaskDetail from '@/screens/TaskManagement/Detail';
import ActivityList from '@/screens/ActivityManagement';
import ActivityEdit from '@/screens/ActivityManagement/Edit';
import ActivityDetail from '@/screens/ActivityManagement/Detail';
import AdvertiseList from '@/screens/AdvertiseManagement';
import AdvertiseEdit from '@/screens/AdvertiseManagement/Edit';
import AdvertiseDetail from '@/screens/AdvertiseManagement/Detail';
import Dashboard from '@/screens/Dashboard';
import TaskEdit from './screens/TaskManagement/Edit';
import DailyBill2 from './screens/EnergyManagement/Bill/Daily2';
import NoticeList from '@/screens/NoticeManagement';
import NoticeEdit from './screens/NoticeManagement/Edit';
import PermissionEdit from './screens/OperationManagement/PermissonManagement/Edit';
import PermissionManagement from './screens/OperationManagement/PermissonManagement';

const routes = [
  { path: '/login', element: <Login /> },
  { path: '/qrlogin', element: <QRLogin /> },
  { path: '/register', element: <Register /> },
  {
    path: '/',
    element: <MainFrame />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      {
        path: '',
        element: <Card><Outlet /></Card>,
        children: [
          { path: 'calendar',element: <Calendar /> },
          { path: "/device/detail/:id", element: <DeviceDetail /> },
          { path: "/device/add", element: <DeviceEdit type="add" /> },
          { path: "/device/edit/:id", element: <DeviceEdit type="edit" /> },
          { path: "/device", element: <DeviceList /> },
          { path: "/workspace/edit/:id", element: <WOrkspaceEdit /> },
          { path: "/workspace/detail/:id", element: <WorkspaceDetail /> },
          { path: "/salesspace", element: <Workspace isParentRoom={1} /> },
          { path: "/workspace", element: <Workspace isParentRoom={0} /> },
          { path: "/company/applicants", element: <ApplicantList /> },
          { path: "/company/invitation", element: <Invitation /> },
          {
            path: 'company',
            element: <CompanyManagement />,
            children: [
              { path: 'detail/:id', element: <CompanyDetail /> },
              { path: 'add', element: <CompanyEdit type="add" /> },
              { path: 'edit/:id', element: <CompanyEdit type="edit" /> },
              { path: 'list', element: <CompanyList /> },
              { path: 'employee', element: <EmployeeList /> },
              { path: 'addemployee/:corpId', element: <EmployeeEdit type="add" /> },
              { path: 'editemployee/:id', element: <EmployeeEdit type="edit" /> },
              { path: 'employee/:id', element: <EmployeeDetail /> },
              { path: 'invitation/:corpId', element: <Invitation /> },
              { path: 'applicants/:corpId', element: <ApplicantList /> },
            ]
          },
          { path: "/data", element: <DataManagement /> },
          { path: "/meetingroom", element: <MeetingRoomManagement /> },
          { path: "/energy/dailybill", element: <DailyBill2 /> },
          { path: "/energy/monthlybill", element: <MonthlyBill /> },
          { path: "/energy/monthlybillc", element: <MonthlyBillCalendarMode /> },
          { path: "/energy/invoice", element: <InvoiceList /> },
          { path: "/energy/clearrecord", element: <ClearRecord /> },
          { path: "/energy", element: <EnergyManagement /> },
          { path: "/personnelfiling/permission", element: <PermissionManagement /> },
          { path: '/personnelfiling/permission/edit/:userId', element: <PermissionEdit /> },
          { path: "/personnelfiling/log", element: <OperateLog /> },
          { path: "/card", element: <CardManagent /> },
          { path: "/knowledge/:bigCategoryId", element: <Knowledge /> },
          { path: "/task/add", element: <TaskEdit />},
          { path: "/task/edit/:id", element: <TaskEdit />},
          { path: "/task/:id", element: <TaskDetail /> },
          { path: "/task", element: <TaskManagement /> },
          { path: "/activity/add", element: <ActivityEdit type="add" /> },
          { path: "/activity/edit/:id", element: <ActivityEdit type="edit" /> },
          { path: "/activity/:id", element: <ActivityDetail /> },
          { path: "/activity", element: <ActivityList /> },
          { path: "/ad/add", element: <AdvertiseEdit type="add" /> },
          { path: "/ad/edit/:id", element: <AdvertiseEdit type="edit" /> },
          { path: "/ad/:id", element: <AdvertiseDetail /> },
          { path: "/ad", element: <AdvertiseList /> },
          { path: "/notice", element: <NoticeList /> },
          { path: '/notice/add', element: <NoticeEdit type="add" /> },
          { path: '/notice/edit/:id', element: <NoticeEdit type="edit" /> },
        ]
      }
    ]
  }
]

const router = createBrowserRouter(
  routes,
  { basename: '/web' }
)

export default router;