import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import img_flag from '@/assets/flag@2x.png';
import img_check from '@/assets/check@2x.png';
import img_clock from '@/assets/clock@2x.png';
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 19px 24px;
  border-radius: 5px;
  border: 1px solid #dee0e3;
  width: 100%;
  margin-bottom: 32px;
`;

const Logo = styled.img`
  width: 32px;
`;

const Title = styled.div`
  font-size: 18px;
  color: #1F2329;
  padding: 0 22px;
`;

const CountDown = styled.div`
  font-size: 12px;
  color: #646A73;
`

const getCountDownData = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  const now = dayjs();
  if (startDate.isAfter(now)) {
    return {
      logo: img_clock,
      title: '未开始',
      date: startDate,
    }
  }

  if (endDate.isBefore(now)) {
    return {
      logo: img_check,
      title: '已结束',
      date: null
    }
  }

  return {
    logo: img_flag,
    title: '进行中',
    date: endDate
  }
}

interface Props {
  startDate: string;
  endDate: string;
}

const StatusBar: React.FC<Props> = ({
  startDate,
  endDate
}) => {
  const [text, setText] = useState('');
  const { logo, title, date } = getCountDownData(dayjs(startDate), dayjs(endDate));

  useEffect(() => {
    if (date) {
      const hTimer = setInterval(() => {
        const d = dayjs.duration(dayjs(date).diff(dayjs()));
        const years = d.years();
        const months = d.months();
        const days = d.days();
        const hours = d.hours();
        const minutes = d.minutes();
        const seconds = d.seconds();
        let newText = '';

        if (title === '未开始') {
          newText = '距离开始 ';
        }
        else if (title === '进行中') {
          newText = '距离结束 ';
        }
        
        if (years > 0) {
          newText += `${years}年`;
        }
        if (months > 0) {
          newText += `${months}月`;
        }
        if (days > 0) {
          newText += `${days}天`;
        }
        if (hours > 0 || days > 0) {
          newText += `${hours}小时`;
        }
        if (minutes > 0 || days > 0 || hours > 0) {
          newText += `${minutes}分`;
        }
        newText += `${seconds}秒`;
  
        setText(newText);
      }, 1000);
  
      return () => clearInterval(hTimer);
    }
  }, [date]);
  return (
    <Container>
      <Logo src={logo} />
      <Title>{title}</Title>
      <CountDown>{text}</CountDown>
    </Container>
  );
}

export default StatusBar;