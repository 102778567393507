import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import InfiniteScorll from 'react-infinite-scroller';
import { getFileGroupList, getFileGroupCount } from '@/services/knowledge';
import { useSelector, useDispatch } from 'react-redux';
import { setKnowledgeGroupList } from '@/store/features/knowledge';

const Container = styled.div`
  width: 150px;
  background: #F7F8FA;
  border-radius: 4px 4px 0 0;
  flex: 1;
  padding: 16px 12px;
`

interface ListItemProps {
  selected: boolean;
}

const ListItem = styled.div<ListItemProps>`
  background: ${props => props.selected ? '#FFFFFF' : '#F7F8FA'};
  color: 1F2329;
  cursor: pointer;
  padding: 6px;
  ${props => props.selected ? 'border-radius: 2px' : ''}
`

interface Props {
  onChange: (groupId: number) => void;
}

const GroupList: React.ForwardRefRenderFunction<unknown, Props> = (props, ref) => {
  const { onChange } = props;
  // const [dataSource, setDataSource] = useState<any[]>([defaultGroup]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const groupList = useSelector((state: any) => state.knowledge.groupList);
  const dispatch = useDispatch();

  const getData = (page: number, pageSize = 30) => {
    setLoading(true)
    getFileGroupList({
      currentPage: page,
      pageSize
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0 ) {
        if (page === 1) {
          // setDataSource(data);
          dispatch(setKnowledgeGroupList(data));
        }
        else if (page > pageIndex) {
          // setDataSource(dataSource.concat(data));
          dispatch(setKnowledgeGroupList(groupList.concat(data)));
        }
        setPageIndex(page);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })

    getFileGroupCount().then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setHasMore(groupList.length < data)
      }
    })
  }

  const infiniteOnLoad = () => {
    setLoading(true);
    getData(pageIndex + 1);
  }

  useEffect(() => {
    console.log('hello grouplist');
    if (groupList.length === 0) {
      getData(1)
    }
  }, [groupList, dispatch])

  useImperativeHandle(ref, () => ({
    updateGroupList: () => {
      getData(1)
    }
  }))

  return (
    <Container>
      <InfiniteScorll
        initialLoad={false}
        pageStart={1}
        loadMore={infiniteOnLoad}
        hasMore={!loading && hasMore}
        useWindow={false}
      >
        <ListItem
          selected={0 === selectedGroupId}
          onClick={() => {
            setSelectedGroupId(0);
            onChange(0);
          }}
        >未分组</ListItem>
        {
          groupList.map((item: any) => (
            <ListItem
              key={`knowledge_group_${item.groupId}`}
              selected={item.groupId === selectedGroupId}
              onClick={() => {
                setSelectedGroupId(item.groupId);
                onChange(item.groupId);
              }}
            >{item.groupName}</ListItem>
          ))
        }
      </InfiniteScorll>
    </Container>
  )
}

export default forwardRef(GroupList);