import bg from '@/assets/energy/floors/8/8.png';
import img_800_1_common from '@/assets/energy/floors/8/common/800-1.png';
import img_800_2_common from '@/assets/energy/floors/8/common/800-2.png';
import img_800_3_common from '@/assets/energy/floors/8/common/800-3.png';
import img_800_4_common from '@/assets/energy/floors/8/common/800-4.png';
import img_800_5_common from '@/assets/energy/floors/8/common/800-5.png';
import img_800_6_common from '@/assets/energy/floors/8/common/800-6.png';
import img_800_7_common from '@/assets/energy/floors/8/common/800-7.png';
import img_800_8_common from '@/assets/energy/floors/8/common/800-8.png';
import img_801_1_common from '@/assets/energy/floors/8/common/801-1.png';
import img_801_2_common from '@/assets/energy/floors/8/common/801-2.png';
import img_801_3_common from '@/assets/energy/floors/8/common/801-3.png';
import img_802_1_common from '@/assets/energy/floors/8/common/802-1.png';
import img_802_2_common from '@/assets/energy/floors/8/common/802-2.png';
import img_802_3_common from '@/assets/energy/floors/8/common/802-3.png';
import img_803_1_common from '@/assets/energy/floors/8/common/803-1.png';
import img_803_2_common from '@/assets/energy/floors/8/common/803-2.png';
import img_803_3_common from '@/assets/energy/floors/8/common/803-3.png';
import img_803_4_common from '@/assets/energy/floors/8/common/803-4.png';
import img_805_1_common from '@/assets/energy/floors/8/common/805-1.png';
import img_805_2_common from '@/assets/energy/floors/8/common/805-2.png';
import img_805_3_common from '@/assets/energy/floors/8/common/805-3.png';
import img_805_4_common from '@/assets/energy/floors/8/common/805-4.png';
import img_806_1_common from '@/assets/energy/floors/8/common/806-1.png';
import img_806_2_common from '@/assets/energy/floors/8/common/806-2.png';
import img_806_3_common from '@/assets/energy/floors/8/common/806-3.png';
import img_806_4_common from '@/assets/energy/floors/8/common/806-4.png';
import img_807_1_common from '@/assets/energy/floors/8/common/807-1.png';
import img_807_2_common from '@/assets/energy/floors/8/common/807-2.png';
import img_807_3_common from '@/assets/energy/floors/8/common/807-3.png';
import img_808_1_common from '@/assets/energy/floors/8/common/808-1.png';
import img_808_2_common from '@/assets/energy/floors/8/common/808-2.png';
import img_808_3_common from '@/assets/energy/floors/8/common/808-3.png';
import img_809_1_common from '@/assets/energy/floors/8/common/809-1.png';
import img_809_2_common from '@/assets/energy/floors/8/common/809-2.png';
import img_809_3_common from '@/assets/energy/floors/8/common/809-3.png';
import img_810_1_common from '@/assets/energy/floors/8/common/810-1.png';
import img_810_2_common from '@/assets/energy/floors/8/common/810-2.png';
import img_810_3_common from '@/assets/energy/floors/8/common/810-3.png';
import img_812_1_common from '@/assets/energy/floors/8/common/812-1.png';
import img_812_2_common from '@/assets/energy/floors/8/common/812-2.png';
import img_812_3_common from '@/assets/energy/floors/8/common/812-3.png';
import img_812_4_common from '@/assets/energy/floors/8/common/812-4.png';
import img_811_1_common from '@/assets/energy/floors/8/common/811-1.png';
import img_811_2_common from '@/assets/energy/floors/8/common/811-2.png';
import img_811_3_common from '@/assets/energy/floors/8/common/811-3.png';
import img_813_1_common from '@/assets/energy/floors/8/common/813-1.png';
import img_813_2_common from '@/assets/energy/floors/8/common/813-2.png';
import img_813_3_common from '@/assets/energy/floors/8/common/813-3.png';
import img_813_4_common from '@/assets/energy/floors/8/common/813-4.png';
import img_815_1_common from '@/assets/energy/floors/8/common/815-1.png';
import img_815_2_common from '@/assets/energy/floors/8/common/815-2.png';
import img_815_3_common from '@/assets/energy/floors/8/common/815-3.png';
import img_815_4_common from '@/assets/energy/floors/8/common/815-4.png';
import img_830_common from '@/assets/energy/floors/8/common/830.png';
import img_831_common from '@/assets/energy/floors/8/common/831.png';
import img_832_common from '@/assets/energy/floors/8/common/832.png';
import img_833_common from '@/assets/energy/floors/8/common/833.png';
import img_834_common from '@/assets/energy/floors/8/common/834.png';
import img_835_common from '@/assets/energy/floors/8/common/835.png';
import img_836_common from '@/assets/energy/floors/8/common/836.png';
import img_837_common from '@/assets/energy/floors/8/common/837.png';
import img_838_common from '@/assets/energy/floors/8/common/838.png';
import img_839_common from '@/assets/energy/floors/8/common/839.png';
import img_840_common from '@/assets/energy/floors/8/common/840.png';
import img_841_common from '@/assets/energy/floors/8/common/841.png';
import img_842_common from '@/assets/energy/floors/8/common/842.png';
import img_843_common from '@/assets/energy/floors/8/common/843.png';
import img_844_common from '@/assets/energy/floors/8/common/844.png';
import img_845_common from '@/assets/energy/floors/8/common/845.png';
import img_846_common from '@/assets/energy/floors/8/common/846.png';
import img_847_common from '@/assets/energy/floors/8/common/847.png';
import img_848_common from '@/assets/energy/floors/8/common/848.png';
import img_849_common from '@/assets/energy/floors/8/common/849.png';

import img_800_1_selected from '@/assets/energy/floors/8/selected/800-1.png';
import img_800_2_selected from '@/assets/energy/floors/8/selected/800-2.png';
import img_800_3_selected from '@/assets/energy/floors/8/selected/800-3.png';
import img_800_4_selected from '@/assets/energy/floors/8/selected/800-4.png';
import img_800_5_selected from '@/assets/energy/floors/8/selected/800-5.png';
import img_800_6_selected from '@/assets/energy/floors/8/selected/800-6.png';
import img_800_7_selected from '@/assets/energy/floors/8/selected/800-7.png';
import img_800_8_selected from '@/assets/energy/floors/8/selected/800-8.png';
import img_801_1_selected from '@/assets/energy/floors/8/selected/801-1.png';
import img_801_2_selected from '@/assets/energy/floors/8/selected/801-2.png';
import img_801_3_selected from '@/assets/energy/floors/8/selected/801-3.png';
import img_802_1_selected from '@/assets/energy/floors/8/selected/802-1.png';
import img_802_2_selected from '@/assets/energy/floors/8/selected/802-2.png';
import img_802_3_selected from '@/assets/energy/floors/8/selected/802-3.png';
import img_803_1_selected from '@/assets/energy/floors/8/selected/803-1.png';
import img_803_2_selected from '@/assets/energy/floors/8/selected/803-2.png';
import img_803_3_selected from '@/assets/energy/floors/8/selected/803-3.png';
import img_803_4_selected from '@/assets/energy/floors/8/selected/803-4.png';
import img_805_1_selected from '@/assets/energy/floors/8/selected/805-1.png';
import img_805_2_selected from '@/assets/energy/floors/8/selected/805-2.png';
import img_805_3_selected from '@/assets/energy/floors/8/selected/805-3.png';
import img_805_4_selected from '@/assets/energy/floors/8/selected/805-4.png';
import img_806_1_selected from '@/assets/energy/floors/8/selected/806-1.png';
import img_806_2_selected from '@/assets/energy/floors/8/selected/806-2.png';
import img_806_3_selected from '@/assets/energy/floors/8/selected/806-3.png';
import img_806_4_selected from '@/assets/energy/floors/8/selected/806-4.png';
import img_807_1_selected from '@/assets/energy/floors/8/selected/807-1.png';
import img_807_2_selected from '@/assets/energy/floors/8/selected/807-2.png';
import img_807_3_selected from '@/assets/energy/floors/8/selected/807-3.png';
import img_808_1_selected from '@/assets/energy/floors/8/selected/808-1.png';
import img_808_2_selected from '@/assets/energy/floors/8/selected/808-2.png';
import img_808_3_selected from '@/assets/energy/floors/8/selected/808-3.png';
import img_809_1_selected from '@/assets/energy/floors/8/selected/809-1.png';
import img_809_2_selected from '@/assets/energy/floors/8/selected/809-2.png';
import img_809_3_selected from '@/assets/energy/floors/8/selected/809-3.png';
import img_810_1_selected from '@/assets/energy/floors/8/selected/810-1.png';
import img_810_2_selected from '@/assets/energy/floors/8/selected/810-2.png';
import img_810_3_selected from '@/assets/energy/floors/8/selected/810-3.png';
import img_811_1_selected from '@/assets/energy/floors/8/selected/811-1.png';
import img_811_2_selected from '@/assets/energy/floors/8/selected/811-2.png';
import img_811_3_selected from '@/assets/energy/floors/8/selected/811-3.png';
import img_811_4_selected from '@/assets/energy/floors/8/selected/811-4.png';
import img_812_1_selected from '@/assets/energy/floors/8/selected/812-1.png';
import img_812_2_selected from '@/assets/energy/floors/8/selected/812-2.png';
import img_812_3_selected from '@/assets/energy/floors/8/selected/812-3.png';
import img_813_1_selected from '@/assets/energy/floors/8/selected/813-1.png';
import img_813_2_selected from '@/assets/energy/floors/8/selected/813-2.png';
import img_813_3_selected from '@/assets/energy/floors/8/selected/813-3.png';
import img_813_4_selected from '@/assets/energy/floors/8/selected/813-4.png';
import img_815_1_selected from '@/assets/energy/floors/8/selected/815-1.png';
import img_815_2_selected from '@/assets/energy/floors/8/selected/815-2.png';
import img_815_3_selected from '@/assets/energy/floors/8/selected/815-3.png';
import img_815_4_selected from '@/assets/energy/floors/8/selected/815-4.png';
import img_830_selected from '@/assets/energy/floors/8/selected/830.png';
import img_831_selected from '@/assets/energy/floors/8/selected/831.png';
import img_832_selected from '@/assets/energy/floors/8/selected/832.png';
import img_833_selected from '@/assets/energy/floors/8/selected/833.png';
import img_834_selected from '@/assets/energy/floors/8/selected/834.png';
import img_835_selected from '@/assets/energy/floors/8/selected/835.png';
import img_836_selected from '@/assets/energy/floors/8/selected/836.png';
import img_837_selected from '@/assets/energy/floors/8/selected/837.png';
import img_838_selected from '@/assets/energy/floors/8/selected/838.png';
import img_839_selected from '@/assets/energy/floors/8/selected/839.png';
import img_840_selected from '@/assets/energy/floors/8/selected/840.png';
import img_841_selected from '@/assets/energy/floors/8/selected/841.png';
import img_842_selected from '@/assets/energy/floors/8/selected/842.png';
import img_843_selected from '@/assets/energy/floors/8/selected/843.png';
import img_844_selected from '@/assets/energy/floors/8/selected/844.png';
import img_845_selected from '@/assets/energy/floors/8/selected/845.png';
import img_846_selected from '@/assets/energy/floors/8/selected/846.png';
import img_847_selected from '@/assets/energy/floors/8/selected/847.png';
import img_848_selected from '@/assets/energy/floors/8/selected/848.png';
import img_849_selected from '@/assets/energy/floors/8/selected/849.png';

const width = (w: number) => `${100 * (w / 980)}%`;
const left = (l: number) => `${100 * (l - 300) / 980}%`;
const top = (t: number) => `${100 * (t - 288) / 398}%`;
const fontSize = (w: number, f: number) => f > 12 ? '12px' : '9px'


const initialRoomList = [
  {
    title: '801-1',
    titleLeft: `${100 * (327 - 303) / 136}%`,
    titleWidth: 'atuo',
    roomNo: '801-1',
    width: width(136),
    left: left(303),
    top: top(291),
    fontSize: fontSize(136, 16),
    image: img_801_1_common,
    selectedImage: img_801_1_selected,
  },
  {
    title: '801-2',
    roomNo: '801-2',
    width: width(48),
    left: left(391),
    top: top(331),
    fontSize: fontSize(48, 12),
    image: img_801_2_common,
    // selectedImage: img_801_2_selected,
  },
  {
    title: '801-3',
    roomNo: '801-3',
    width: width(48),
    left: left(391),
    top: top(291),
    fontSize: fontSize(48, 12),
    image: img_801_3_common,
    // selectedImage: img_801_3_selected,
  },
  {
    title: '802-1',
    titleLeft: `${100 * (501 - 441) / 114}%`,
    titleWidth: 'atuo',
    roomNo: '802-1',
    width: width(114),
    left: left(441),
    top: top(291),
    fontSize: fontSize(114, 16),
    image: img_802_1_common,
    selectedImage: img_802_1_selected,
  },
  {
    title: '802-2',
    roomNo: '802-2',
    width: width(48),
    left: left(441),
    top: top(331),
    fontSize: fontSize(48, 12),
    image: img_802_2_common,
    // selectedImage: img_802_2_selected,
  },
  {
    title: '802-3',
    roomNo: '802-3',
    width: width(48),
    left: left(441),
    top: top(291),
    fontSize: fontSize(48, 12),
    image: img_802_3_common,
    // selectedImage: img_802_3_selected,
  },
  {
    title: '新风机房',
    roomNo: '832',
    width: width(48),
    left: left(557),
    top: top(291),
    fontSize: fontSize(48, 12),
    writingMode: 'vertical-lr',
    image: img_832_common,
    selectedImage: img_832_selected,
  },
  {
    title: '803-1',
    titleLeft: `${100 * (695 - 607) / 182}%`,
    titleWidth: 'atuo',
    roomNo: '803-1',
    width: width(182),
    left: left(607),
    top: top(291),
    fontSize: fontSize(182, 16),
    image: img_803_1_common,
    selectedImage: img_803_1_selected,
  },
  {
    title: '803-2',
    roomNo: '803-2',
    width: width(30),
    left: left(627),
    top: top(349),
    fontSize: fontSize(30, 12),
    image: img_803_2_common,
    // selectedImage: img_803_2_selected,
  },
  {
    title: '803-3',
    roomNo: '803-3',
    width: width(50),
    left: left(607),
    top: top(291),
    fontSize: fontSize(50, 12),
    image: img_803_3_common,
    // selectedImage: img_803_3_selected,
  },
  {
    title: '803-4',
    roomNo: '803-4',
    width: width(42),
    left: left(659),
    top: top(363),
    fontSize: fontSize(42, 12),
    image: img_803_4_common,
    // selectedImage: img_803_4_selected,
  },
  {
    title: '833',
    roomNo: '833',
    width: width(18),
    left: left(607),
    top: top(349),
    fontSize: fontSize(18, 12),
    writingMode: 'vertical-lr',
    image: img_833_common,
    selectedImage: img_833_selected,
  },
  {
    title: '805-1',
    titleLeft: `${100 * (831 - 791) / 172}%`,
    titleWidth: 'atuo',
    roomNo: '805-1',
    width: width(172),
    left: left(791),
    top: top(291),
    fontSize: fontSize(172, 16),
    image: img_805_1_common,
    selectedImage: img_805_1_selected,
  },
  {
    title: '805-2',
    roomNo: '805-2',
    width: width(30),
    left: left(913),
    top: top(349),
    fontSize: fontSize(30, 12),
    image: img_805_2_common,
    // selectedImage: img_805_2_selected,
  },
  {
    title: '805-3',
    roomNo: '805-3',
    width: width(50),
    left: left(913),
    top: top(291),
    fontSize: fontSize(50, 12),
    image: img_805_3_common,
    // selectedImage: img_805_3_selected,
  },
  {
    title: '805-4',
    roomNo: '805-4',
    width: width(42),
    left: left(869),
    top: top(363),
    fontSize: fontSize(42, 12),
    image: img_805_4_common,
    // selectedImage: img_805_4_selected,
  },
  {
    title: '806-1',
    titleLeft: `${100 * (1055 - 965) / 172}%`,
    titleWidth: 'atuo',
    roomNo: '806-1',
    width: width(172),
    left: left(965),
    top: top(291),
    fontSize: fontSize(172, 16),
    image: img_806_1_common,
    selectedImage: img_806_1_selected,
  },
  {
    title: '806-2',
    roomNo: '806-2',
    width: width(30),
    left: left(985),
    top: top(349),
    fontSize: fontSize(30, 12),
    image: img_806_2_common,
    // selectedImage: img_806_2_selected,
  },
  {
    title: '806-3',
    roomNo: '806-3',
    width: width(50),
    left: left(965),
    top: top(291),
    fontSize: fontSize(50, 12),
    image: img_806_3_common,
    // selectedImage: img_806_3_selected,
  },
  {
    title: '806-4',
    roomNo: '806-4',
    width: width(42),
    left: left(1017),
    top: top(363),
    fontSize: fontSize(42, 12),
    image: img_806_4_common,
    // selectedImage: img_806_4_selected,
  },
  {
    title: '洽谈室',
    roomNo: '837',
    width: width(38),
    left: left(945),
    top: top(349),
    fontSize: fontSize(38, 12),
    image: img_837_common,
    selectedImage: img_837_selected,
  },
  {
    title: '807-1',
    titleLeft: `${100 * (1211 - 1139) / 138}%`,
    titleWidth: 'atuo',
    roomNo: '807-1',
    width: width(138),
    left: left(1139),
    top: top(291),
    fontSize: fontSize(138, 16),
    image: img_807_1_common,
    selectedImage: img_807_1_selected,
  },
  {
    title: '807-2',
    roomNo: '807-2',
    width: width(48),
    left: left(1139),
    top: top(331),
    fontSize: fontSize(48, 12),
    image: img_807_2_common,
    // selectedImage: img_807_2_selected,
  },
  {
    title: '807-3',
    roomNo: '807-3',
    width: width(48),
    left: left(1139),
    top: top(291),
    fontSize: fontSize(48, 12),
    image: img_807_3_common,
    // selectedImage: img_807_3_selected,
  },
  {
    title: '新风机房',
    roomNo: '839',
    width: width(98),
    left: left(1179),
    top: top(391),
    fontSize: fontSize(98, 12),
    image: img_839_common,
    selectedImage: img_839_selected,
  },
  {
    title: '808-1',
    titleTop: `${100 * (459 - 429) / 98}%`,
    roomNo: '808-1',
    width: width(98),
    left: left(1179),
    top: top(429),
    fontSize: fontSize(98, 16),
    image: img_808_1_common,
    selectedImage: img_808_1_selected,
  },
  {
    title: '808-2',
    roomNo: '808-2',
    width: width(34),
    left: left(1199),
    top: top(511),
    fontSize: fontSize(34, 12),
    image: img_808_2_common,
    // selectedImage: img_808_2_selected,
  },
  {
    title: '808-3',
    roomNo: '808-3',
    width: width(42),
    left: left(1235),
    top: top(511),
    fontSize: fontSize(42, 12),
    image: img_808_3_common,
    // selectedImage: img_808_3_selected,
  },
  {
    title: '809-1',
    titleLeft: `${100 * (1211 - 1139) / 138}%`,
    titleWidth: 'atuo',
    roomNo: '809-1',
    width: width(138),
    left: left(1139),
    top: top(585),
    fontSize: fontSize(138, 16),
    image: img_809_1_common,
    selectedImage: img_809_1_selected,
  },
  {
    title: '809-2',
    roomNo: '809-2',
    width: width(48),
    left: left(1139),
    top: top(613),
    fontSize: fontSize(48, 12),
    image: img_809_2_common,
    // selectedImage: img_809_2_selected,
  },
  {
    title: '809-3',
    roomNo: '809-3',
    width: width(48),
    left: left(1139),
    top: top(645),
    fontSize: fontSize(48, 12),
    image: img_809_3_common,
    // selectedImage: img_809_3_selected,
  },
  {
    title: '810-1',
    titleLeft: `${100 * (1035 - 1023) / 114}%`,
    titleWidth: 'atuo',
    roomNo: '810-1',
    width: width(114),
    left: left(1023),
    top: top(585),
    fontSize: fontSize(114, 16),
    image: img_810_1_common,
    selectedImage: img_810_1_selected,
  },
  {
    title: '810-2',
    roomNo: '810-2',
    width: width(48),
    left: left(1089),
    top: top(613),
    fontSize: fontSize(48, 12),
    image: img_810_2_common,
    // selectedImage: img_810_2_selected,
  },
  {
    title: '810-3',
    roomNo: '810-3',
    width: width(48),
    left: left(1089),
    top: top(645),
    fontSize: fontSize(48, 12),
    image: img_810_3_common,
    // selectedImage: img_810_3_selected,
  },
  {
    title: '茶水休闲区',
    roomNo: '800-3',
    width: width(114),
    left: left(907),
    top: top(585),
    fontSize: fontSize(114, 16),
    image: img_800_3_common,
    selectedImage: img_800_3_selected,
  },
  {
    title: '811-1',
    titleLeft: `${100 * (851 - 791) / 114}%`,
    titleWidth: 'atuo',
    roomNo: '811-1',
    width: width(114),
    left: left(791),
    top: top(585),
    fontSize: fontSize(114, 16),
    image: img_811_1_common,
    selectedImage: img_811_1_selected,
  },
  {
    title: '811-2',
    roomNo: '811-2',
    width: width(48),
    left: left(791),
    top: top(613),
    fontSize: fontSize(48, 12),
    image: img_811_2_common,
    // selectedImage: img_811_2_selected,
  },
  {
    title: '811-3',
    roomNo: '811-3',
    width: width(48),
    left: left(791),
    top: top(645),
    fontSize: fontSize(48, 12),
    image: img_811_3_common,
    // selectedImage: img_811_3_selected,
  },
  {
    title: '812-1',
    titleLeft: `${100 * (707 - 617) / 172}%`,
    titleWidth: 'atuo',
    roomNo: '812-1',
    width: width(172),
    left: left(617),
    top: top(585),
    fontSize: fontSize(172, 16),
    image: img_812_1_common,
    selectedImage: img_812_1_selected,
  },
  {
    title: '812-2',
    roomNo: '812-2',
    width: width(30),
    left: left(637),
    top: top(585),
    fontSize: fontSize(30, 12),
    image: img_812_2_common,
    // selectedImage: img_812_2_selected,
  },
  {
    title: '812-3',
    roomNo: '812-3',
    width: width(50),
    left: left(617),
    top: top(627),
    fontSize: fontSize(50, 12),
    image: img_812_3_common,
    // selectedImage: img_812_3_selected,
  },
  {
    title: '812-4',
    roomNo: '812-4',
    width: width(42),
    left: left(669),
    top: top(585),
    fontSize: fontSize(42, 12),
    image: img_812_4_common,
    // selectedImage: img_812_4_selected,
  },
  {
    title: '813-1',
    titleLeft: `${100 * (483 - 443) / 172}%`,
    titleWidth: 'atuo',
    roomNo: '813-1',
    width: width(172),
    left: left(443),
    top: top(585),
    fontSize: fontSize(172, 16),
    image: img_813_1_common,
    selectedImage: img_813_1_selected,
  },
  {
    title: '813-2',
    roomNo: '813-2',
    width: width(30),
    left: left(565),
    top: top(585),
    fontSize: fontSize(30, 12),
    image: img_813_2_common,
    // selectedImage: img_813_2_selected,
  },
  {
    title: '813-3',
    roomNo: '813-3',
    width: width(50),
    left: left(565),
    top: top(627),
    fontSize: fontSize(50, 12),
    image: img_813_3_common,
    // selectedImage: img_813_3_selected,
  },
  {
    title: '813-4',
    roomNo: '813-4',
    width: width(42),
    left: left(521),
    top: top(585),
    fontSize: fontSize(42, 12),
    image: img_813_4_common,
    // selectedImage: img_813_4_selected,
  },
  {
    title: '洽谈室',
    roomNo: '846',
    width: width(38),
    left: left(597),
    top: top(585),
    fontSize: fontSize(38, 12),
    image: img_846_common,
    selectedImage: img_846_selected,
  },
  {
    title: '815-1',
    titleLeft: `${100 * (327 - 303) / 138}%`,
    titleWidth: 'atuo',
    roomNo: '815-1',
    width: width(138),
    left: left(303),
    top: top(585),
    fontSize: fontSize(138, 16),
    image: img_815_1_common,
    // selectedImage: img_815_1_selected,
  },
  {
    title: '815-2',
    roomNo: '815-2',
    width: width(48),
    left: left(393),
    top: top(613),
    fontSize: fontSize(48, 12),
    image: img_815_2_common,
    // selectedImage: img_815_2_selected,
  },
  {
    title: '815-3',
    roomNo: '815-3',
    width: width(48),
    left: left(393),
    top: top(645),
    fontSize: fontSize(48, 12),
    image: img_815_3_common,
    // selectedImage: img_815_3_selected,
  },
  {
    title: '815-4',
    roomNo: '815-4',
    width: width(68),
    left: left(303),
    top: top(551),
    fontSize: fontSize(68, 12),
    image: img_815_4_common,
    // selectedImage: img_815_4_selected,
  },
  {
    title: '849',
    roomNo: '849',
    width: width(16),
    left: left(304),
    top: top(426),
    fontSize: fontSize(16, 12),
    writingMode: 'vertical-lr',
    image: img_849_common,
    selectedImage: img_849_selected,
  },
  {
    title: '830',
    roomNo: '830',
    width: width(50),
    left: left(395),
    top: top(425),
    fontSize: fontSize(50, 12),
    image: img_830_common,
    // selectedImage: img_830_selected,
  },
  {
    title: '848',
    roomNo: '848',
    width: width(50),
    left: left(395),
    top: top(481),
    fontSize: fontSize(50, 12),
    image: img_848_common,
    // selectedImage: img_848_selected,
  },
  {
    title: '茶水休闲区',
    roomNo: '800-4',
    width: width(80),
    left: left(481),
    top: top(425),
    fontSize: fontSize(80, 16),
    image: img_800_4_common,
    // selectedImage: img_800_4_selected,
  },
  {
    title: '储藏室',
    roomNo: '831',
    width: width(50),
    left: left(563),
    top: top(425),
    fontSize: fontSize(50, 12),
    image: img_831_common,
    selectedImage: img_831_selected,
  },
  {
    title: '储藏室',
    roomNo: '847',
    width: width(50),
    left: left(563),
    top: top(495),
    fontSize: fontSize(50, 12),
    image: img_847_common,
    selectedImage: img_847_selected,
  },
  {
    title: '洽谈室',
    roomNo: '834',
    width: width(52),
    left: left(615),
    top: top(425),
    fontSize: fontSize(52, 12),
    image: img_834_common,
    // selectedImage: img_834_selected,
  },
  {
    title: '洽谈室',
    roomNo: '845',
    width: width(52),
    left: left(615),
    top: top(495),
    fontSize: fontSize(52, 12),
    image: img_845_common,
    // selectedImage: img_845_selected,
  },
  {
    title: '835',
    roomNo: '835',
    width: width(80),
    left: left(669),
    top: top(425),
    fontSize: fontSize(80, 16),
    image: img_835_common,
    // selectedImage: img_835_selected,
  },
  {
    title: '844',
    roomNo: '844',
    width: width(80),
    left: left(669),
    top: top(495),
    fontSize: fontSize(80, 16),
    image: img_844_common,
    // selectedImage: img_844_selected,
  },
  {
    title: '836',
    roomNo: '836',
    width: width(66),
    left: left(785),
    top: top(425),
    fontSize: fontSize(66, 12),
    image: img_836_common,
    // selectedImage: img_836_selected,
  },
  {
    title: '842',
    roomNo: '842',
    width: width(66),
    left: left(785),
    top: top(501),
    fontSize: fontSize(66, 12),
    image: img_842_common,
    // selectedImage: img_842_selected,
  },
  {
    title: '843',
    roomNo: '843',
    width: width(38),
    left: left(813),
    top: top(475),
    fontSize: fontSize(38, 12),
    image: img_843_common,
    selectedImage: img_843_selected,
  },
  {
    title: '841',
    roomNo: '841',
    width: width(44),
    left: left(901),
    top: top(518),
    fontSize: fontSize(44, 12),
    image: img_841_common,
    selectedImage: img_841_selected,
  },
  {
    title: '840',
    roomNo: '840',
    width: width(36),
    left: left(1026),
    top: top(503),
    fontSize: fontSize(36, 12),
    image: img_840_common,
    selectedImage: img_840_selected,
  },
  {
    title: '838',
    roomNo: '838',
    width: width(28),
    left: left(1030),
    top: top(425),
    fontSize: fontSize(28, 12),
    writingMode: 'vertical-lr',
    image: img_838_common,
    selectedImage: img_838_selected,
  },
  {
    title: '800-1',
    roomNo: '800-1',
    width: width(902),
    left: left(375),
    top: top(553),
    fontSize: fontSize(902, 12),
    color: '#808080',
    image: img_800_1_common,
    selectedImage: img_800_1_selected,
  },
  {
    title: '800-2',
    roomNo: '800-2',
    width: width(872),
    left: left(303),
    top: top(393),
    fontSize: fontSize(872, 12),
    color: '#808080',
    image: img_800_2_common,
    selectedImage: img_800_2_selected,
  },
  {
    title: '800-5',
    roomNo: '800-5',
    width: width(28),
    left: left(1147),
    top: top(425),
    fontSize: fontSize(28, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_800_5_common,
    selectedImage: img_800_5_selected,
  },
  {
    title: '800-6',
    roomNo: '800-6',
    width: width(32),
    left: left(950),
    top: top(425),
    fontSize: fontSize(32, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_800_6_common,
    selectedImage: img_800_6_selected,
  },
  {
    title: '800-7',
    roomNo: '800-7',
    width: width(28),
    left: left(753),
    top: top(425),
    fontSize: fontSize(28, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_800_7_common,
    selectedImage: img_800_7_selected,
  },
  {
    title: '800-8',
    roomNo: '800-8',
    width: width(28),
    left: left(449),
    top: top(425),
    fontSize: fontSize(28, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_800_8_common,
    selectedImage: img_800_8_selected,
  },
]

export default {
  bg,
  initialRoomList
}