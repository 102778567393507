import qs from 'qs';
import jsonToFormData from 'json-form-data';
import request from '@/utils/request';
import { API_BASE } from '@/common/constants';
import { convertDict2List } from '@/utils/transform';

export function login(username: string, password: string) {
  return request.post(`/api/notlogined/login/loginByPwd?loginPwd=${password}&mobileNo=${username}`).then((res: any) => res.data);
}

export function getSessionKey() {
  return request.get(`/api/notlogined/charCaptcha/getSessionKey`).then((res: any) => res.data);
}

export function getCaptchaImage(sessionKey: number | string) {
  return `${API_BASE}/api/notlogined/charCaptcha/displayCaptcha?sessionKey=${sessionKey}`;
}

export function verifyCode(code: string, sessionKey: string) {
  return request.post(`/api/notlogined/charCaptcha/verifyCode?${qs.stringify({
    code,
    sessionKey
  })}`)
}

export function checkSessionKeyIsPassed(sessionKey: number | string) {
  return request.post(`/api/notlogined/charCaptcha/checkSessionKeyPassed?sessionKey=${sessionKey}`)
}

// 取用户状态
export function getUserStatusList() {
  return request.get('/api/conf/user/userStatus').then((res: any) => convertDict2List(res.data))
}

// 获取扫码登录用的code
export function getLoginCode() {
 return request.get('/api/notlogined/qrcodeLogin/getNewCode')
}

// 轮询扫码登录结果
export function getQrLoginResult(code: string) {
  return request.get(`/api/notlogined/qrcodeLogin/login?code=${code}`)
}

export function getUserInfo() {
  return request.get('/api/logined/user/getPersonBaseInfo')
}

export function getUserInfoById(userId: number) {
  return request.get(`/api/permission/userInfo/detail?userId=${userId}`)
}

// 取政治面貌列表
export function getPoliticsStatusList() {
  return request.get('/api/conf/user/politicsStatus').then((res: any) => convertDict2List(res.data))
}

// 取民族列表
export function getNationList() {
  return request.get('/api/conf/user/nation')
}

export function getUserInfoList(params: any) {
  return request.post('/api/permission/userInfo/list', jsonToFormData(params, { initialFormData: new FormData() }))
}

export function getUserInfoCount(params: any) {
  return request.post('/api/permission/userInfo/count', jsonToFormData(params, { initialFormData: new FormData() }))
}

export function getRoleList() {
  return request.get('/api/permission/roleInfo/allRecords')
}

export function getUserRoleList(userId: number) {
  return request.get(`/api/permission/userRole/userRoleList?userId=${userId}`)
}

// 给某个用户授权角色
export function grantRoleToUser(
  userId: number,
  roleId: number,
  expireTime: string
) {
  return request.get(`/api/permission/userRole/grant?expireTime=${encodeURIComponent(expireTime)}&roleId=${roleId}&userId=${userId}`)
}

// 取消某个用户某个角色
export function revokeRoleOfUser(
  userId: number,
  roleId: number
) {
  return request.get(`/api/permission/userRole/revoke?roleId=${roleId}&userId=${userId}`)
}

export function getOperateLogList() {
  return request.post('/api/permission/operateLog/list')
}

export function getOperateLogCount() {
  return request.post('/api/permission/operateLog/count')
}