import request from '@/utils/request';
import jsonToFormData from 'json-form-data';
import { convertDict2List, removeEmptyParams } from '@/utils/transform';

export function getWorkspaceAcAmmeterDayStat(day: string) {
  return request.get(`/api/logined/officeElectricCharge/workspaceAcAmmeterDayStat?day=${day}`)
}

export function getWorkspaceAcAmmeterMonthStat(month: string) {
  return request.get(`/api/logined/officeElectricCharge/workspaceAcAmmeterMonthStat?month=${month}`)
}

export function getBillList(params: {
  currentPage: number;
  pageSize?: number;
  roomNo?: string;
  corpName?: string;
  corpId?: number;
  billStatus?: number;
  billId?: number;
  businessType?: number;
  beginCreateTime?: string;
  endCreateTime?: string;
  billDateSignMin?: string;
  billDateSignMax?: string;
}) {
  const formData = jsonToFormData(removeEmptyParams(params), { initialFormData: new FormData() })
  return request.post('/api/permission/billRecord/list', formData)
}

export function getBillListCount(params: {
  roomNo?: string;
  corpId?: number;
  corpName?: string;
  billStatus?: number;
  billId?: number;
  businessType?: number;
  beginCreateTime?: string;
  endCreateTime?: string;
}) {
  const formData = jsonToFormData(removeEmptyParams(params), { initialFormData: new FormData() })
  return request.post('/api/permission/billRecord/count', formData)
}

export function getBillDetail(billId: number) {
  return request.get(`/api/permission/billRecord/detail?billId=${billId}`)
}

export function getBusinessTypeList() {
  return request.get('/api/conf/billrecord/businessType').then((res: any) => convertDict2List(res.data))
}

export function getBillStatusList() {
  return request.get('/api/conf/billrecord/billStatus').then((res: any) => convertDict2List(res.data))
}

export function getClearRecordList(params: any) {
  const formData = jsonToFormData(removeEmptyParams(params), { initialFormData: new FormData() })
  return request.post('/api/permission/corpAccountClearRecord/list', formData)
}

export function getClearRecordCount(params: any) {
  const formData = jsonToFormData(removeEmptyParams(params), { initialFormData: new FormData() })
  return request.post('/api/permission/corpAccountClearRecord/count', formData)
}

export function getMonthlyBillStat(month: string) {
  return request.get(`/api/permission/billStat/monthStat?month=${month}`)
}

export function getDailyBillStatOfMonth(month: string) {
  return request.get(`/api/permission/billStat/monthDayStat?month=${month}`)
}