import React from 'react';
import styled from 'styled-components';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import MainData from './MainData';
import SubData from './SubData';

const Container = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 12px;
  min-width: 220px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  height: 28px;
  font-size: 13px;
  font-weight: bold;
`

const DescContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  column-gap: 12px;
`

const Descritpion = styled.div`
  font-size: 14px;
  color: #333333;
  margin-top: 12px;
  font-weight: 500;
`

interface Props {
  title: string;
  data: Array<any>;
  subData?: Array<any>;
  desc?: string;
  increaseRate?: number;
}

export default function Cube(props: Props) {
  return (
    <Container>
      <Title>{props.title}</Title>
      <MainData data={props.data} />
      {props.subData ? <SubData data={props.subData} /> : null}
      <DescContainer>
        {props.desc ? <Descritpion>{props.desc}</Descritpion> : null}
        {
          props.increaseRate != undefined ? (
            <>
              {props.increaseRate > 0 ? <CaretUpOutlined /> : null}
              {props.increaseRate < 0 ? <CaretDownOutlined /> : null}
            </>
          ): null
        }
      </DescContainer>
    </Container>
  )
}