import { DeviceListParams, DeviceInfo, DeviceStatus, DeviceType } from '@/types/device';
import request from '@/utils/request';
import qs from 'qs';
import jsonToFormData from 'json-form-data';
import { convertDict2List } from '@/utils/transform';
import { API_BASE } from '@/common/constants';

export function getDeviceList(params: any = {
  currentPage: 1,
  pageSize: 10
}) {
  const formData = jsonToFormData(params, {
    initialFormData: new FormData()
  });
  return request.post(`/api/permission/deviceRecord/list`, formData);
}

export function getDeviceCount(params: {
  belongFloor?: string;
  belongRoom?: string;
  deviceId?: number;
  deviceNo?: string;
  deviceStatus?: DeviceStatus | undefined;
  networkStatus?: DeviceType;
  assetType?: number;
}) {
  const formData = jsonToFormData(params, {
    initialFormData: new FormData()
  });
  return request.post('/api/permission/deviceRecord/count', formData)
}

export function getDeviceInfo(id: number | string | undefined) {
  return request.get(`/api/permission/deviceRecord/detail?deviceId=${id}`);
}

export function updateDevice(data: DeviceInfo) {
  return request.post(`/api/permission/deviceRecord/upd?${qs.stringify(data)}`);
}

export function addDevice(data: DeviceInfo) {
  return request.post(`/api/permission/deviceRecord/add?${qs.stringify(data)}`);
}

export function deleteDevice(id: number) {
  return request.post(`/api/permission/deviceRecord/del?deviceId=${id}`);
}

export function deleteDevices(ids: number[]) {
  return request.post('/api/permission/deviceRecord/delRecords', ids);
}

export function getDeviceStatusList() {
  return request.get('/api/conf/device/deviceStatus').then((res: any) => convertDict2List(res.data))
}

export function getDeviceTypeList() {
  return request.get('/api/conf/device/deviceType').then((res: any) => convertDict2List(res.data))
}

export function getNetworkStatusList() {
  return request.get('/api/conf/device/networkStatus').then((res: any) => convertDict2List(res.data))
}

// 取资产类型
export function getAssetTypeList() {
  return request.get('/api/conf/device/assetType').then((res: any) => convertDict2List(res.data))
}

// 取设备二维码链接
export function getDeviceQRCodeUrl(deviceNo: string) {
  return request.get(`/api/permission/deviceRecord/qrcodeContent?deviceNo=${deviceNo}`)
}

// 关闭某个房间的所有设备
export function turnOffDeviceByRoomNo(roomNo: string) {
  return request.get(`/api/logined/operatedevicebatch/turnOffByRoomNo?roomNo=${roomNo}`)
}

// 打开某个房间的所有设备
export function turnOnDeviceByRoomNo(roomNo: string) {
  return request.get(`/api/logined/operatedevicebatch/turnOnByRoomNo?roomNo=${roomNo}`)
}

// 打开楼层所有设备
export function turnOnDeviceByFloorNo(floorNo: string) {
  return request.get(`/api/logined/operatedevicebatch/turnOnByFloorNo?floorNo=${floorNo}`)
}

export function turnOffDeviceByFloorNo(floorNo: string) {
  return request.get(`/api/logined/operatedevicebatch/turnOffByFloorNo?floorNo=${floorNo}`)
}