import styled from "styled-components";
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  flex: 1;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  
  align-items: flex-end;
  column-gap: 4px;
`

const Label = styled.div`
  font-size: 13px;
  transform: translateY(-3px);
`

const Value = styled.div`
  font-size: 20px;
  font-weight: 500;
`

interface Props {
  data: Array<any>;
}

export default function MainData({
  data
}: Props) {
  
  return (
    <Container>
      {
        data.map((item, index) => (
          <Row key={`main_data_c_${index}`}>
            {item.label ? <Label>{item.label}:</Label> : null}
            <Value>{item.value}</Value>
          </Row>
        ))
      }
    </Container>
  );
}

