import { useState, useEffect } from 'react';
import { Modal, Table, Popover } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux'
import AsyncQRCode from '@/components/AsyncQRCode';
import { getDeviceList, updateDevice, getDeviceQRCodeUrl } from '@/services/device';
import { getSelectedTextFromList } from '@/utils/transform';

const { Column } = Table;

interface Props {
  visible: boolean;
  roomNo: string;
  floorNo: string;
  assetType: 0 | 1;
  onClose: () => void;
}

const BindDevice: React.FC<Props> = ({
  visible,
  roomNo,
  floorNo,
  assetType,
  onClose
}) => {
  const [data, setData] = useState<any []>([]);
  const [selectedDeviceIdList, setSelectedDeviceIdList] = useState<string []>([]);
  const deviceTypeList = useSelector((state: any) => state.common.deviceTypeList);
  const deviceStatusList = useSelector((state: any) => state.common.deviceStatusList);
  const [loading, setLoading] = useState(false)

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedDeviceIdList(selectedRowKeys.map((item: string) => item.replace('room_device_all_', '')))
  }

  const onSave = () => {
    setLoading(true)
    Promise.all(selectedDeviceIdList.map(deviceId => updateDevice({
      deviceId,
      belongRoom: roomNo,
      belongFloor: floorNo,
      assetType: assetType
    }))).then(() => {
      setLoading(false)
      setTimeout(onClose, 1000)
    }).catch((err: any) => {
      console.error(err);
      setLoading(false)
    })
  }

  useEffect(() => {
    getDeviceList().then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setData(data)
      }
    })
  }, [])

  return (
    <Modal 
      title="添加设备"
      okText="保存"
      cancelText="取消"
      visible={visible}
      onCancel={onClose}
      onOk={onSave}
      width={900}
      confirmLoading={loading}
    >
      <Table
        dataSource={data}
        rowSelection={{
          type: 'checkbox',
          onChange: onSelectChange
        }}
        rowKey={(record: any) => `room_device_all_${record.deviceId}`}
      >
        <Column title="名称" dataIndex="deviceName" />
        <Column
          title="种类"
          dataIndex="deviceType"
          render={(_: number) => getSelectedTextFromList(deviceTypeList, _)}
        />
        <Column title="编号" dataIndex="deviceNo" />
        <Column title="型号" dataIndex="deviceModel" />
        <Column
          title="使用状态"
          dataIndex="deviceStatus"
          render={text => getSelectedTextFromList(deviceStatusList, text)}
        />
        <Column
          title="二维码"
          dataIndex="deviceNo"
          render={(_: string) => (
            <Popover content={<AsyncQRCode value={getDeviceQRCodeUrl(_)} />}>
              <QrcodeOutlined style={{ fontSize: 20 }} />
            </Popover>
          )}
        />
      </Table>
    </Modal>
  )
}

export default BindDevice;