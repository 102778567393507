import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Space, Table, Popconfirm, Popover } from 'antd';
import { PlusOutlined, QrcodeOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import AsyncQRCode from '@/components/AsyncQRCode';
import Title from '@/components/Form/Title';
import FormItem from '@/components/Form/Item';
import BindDevice from '../BindDevice';
import { getWorkspaceDetail, getWorkspaceList } from '@/services/workspace'; 
import { getDeviceList, updateDevice, getDeviceQRCodeUrl, getDeviceCount } from '@/services/device';
import { getSelectedTextFromList } from '@/utils/transform';
import { useNavigate, useParams } from 'react-router';
import EditDevice from '../EditDevice';
import SalePurpose from '../SalePurpose';

const { Column } = Table;

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 900px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const Content = styled.div`
  background: #F4F5F9;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #1F2329;
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface Props {

}

const WorkspaceDetail: React.FC<Props> = () => {
  const { id = '' } = useParams<any>();
  const navigate = useNavigate();
  const [detail, setDetail] = useState<any>({});
  const [selectedDeviceId, setSelectedDeviceId] = useState<number>();
  const [deviceList1, setDeviceList1] = useState<any []>([]);
  const [deviceList2, setDeviceList2] = useState<any []>([]);
  const [bindDeviceVisible1, setBindDeviceVisible1] = useState(false);
  const [bindDeviceVisible2, setBindDeviceVisible2] = useState(false);
  const [editDeviceVisible, setEditDeviceVisible] = useState(false);
  const [isShowDeviceList, setIsShowDeviceList] = useState(false);
  const [rentCost, setRentCost] = useState<any>('');

  const [deviceList1Count, setDeviceList1Count] = useState(0);
  const [deviceList1PageIndex, setDeviceList1PageIndex] = useState(1);
  const [deviceList1PageSize, setDeviceList1PageSize] = useState(10);

  const [deviceList2Count, setDeviceList2Count] = useState(0);
  const [deviceList2PageIndex, setDeviceList2PageIndex] = useState(1);
  const [deviceList2PageSize, setDeviceList2PageSize] = useState(10);

  const workspaceTypeList = useSelector((state: any) => state.common.workspaceTypeList);
  const workspaceSizeTypeList = useSelector((state: any) => state.common.workspaceSizeTypeList);
  const deviceTypeList = useSelector((state: any) => state.common.deviceTypeList);
  const deviceStatusList = useSelector((state: any) => state.common.deviceStatusList);

  const getDeviceList1 = (pageIndex: number, pageSize: number) => {
    getDeviceList({
      belongRoom: detail.roomNo,
      assetType: 0,
      currentPage: pageIndex,
      pageSize: pageSize
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDeviceList1(data)
      }
    });
    getDeviceCount({
      belongRoom: detail.roomNo,
      assetType: 0
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDeviceList1Count(data);
      }
    })
    setDeviceList1PageIndex(pageIndex);
    setDeviceList1PageSize(pageSize);
  }

  const getDeviceList2 = (pageIndex: number, pageSize: number) => {
    getDeviceList({
      belongRoom: detail.roomNo,
      assetType: 1,
      currentPage: pageIndex,
      pageSize: pageSize
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDeviceList2(data)
      }
    });
    getDeviceCount({
      belongRoom: detail.roomNo,
      assetType: 1
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDeviceList1Count(data);
      }
    })
    setDeviceList2PageIndex(pageIndex);
    setDeviceList2PageSize(pageSize);
  }

  const getBoundDeviceList = () => {
    getDeviceList1(deviceList1PageIndex, deviceList1PageSize);
    getDeviceList2(deviceList2PageIndex, deviceList2PageSize);
  }

  const onDeviceList1Pagination = (page: number, pageSize?: number | undefined) => {
    getDeviceList1(page, pageSize || deviceList1PageSize);
  }

  const onDeviceList2Pagination = (page: number, pageSize?: number | undefined) => {
    getDeviceList2(page, pageSize || deviceList1PageSize);
  }



  useEffect(() => {
    getWorkspaceDetail(id).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDetail(data)
        setIsShowDeviceList(data.roomNo !== data.parentRoomNo);
        return [data.roomNo !== data.parentRoomNo, data.parentRoomNo];
      }
      return [false, null]
    })
    .then(([success, parentRoomNo]) => success ? getWorkspaceList({ roomNo: parentRoomNo }) : null)
    .then((res: any) => {
      if (!res) {
        return
      }

      const { resultCode, data } = res.data;
      if (resultCode === 0 && data.length > 0) {
        setRentCost(data[0].rentCost);
      }
    })
  }, [id])

  useEffect(() => {
    getBoundDeviceList();
  }, [detail])
  return (
    <>
      <CloseButtonContainer>
        <Button type="text" onClick={() => navigate(-1) }><CloseOutlined /></Button>
      </CloseButtonContainer>
      <Container>
        <Panel>
          <Title>基本信息</Title>
          <Row gutter={[16, 24]}>
            <Col span={12}>
              <FormItem label="功能类型">
                <Content>{getSelectedTextFromList(workspaceTypeList, detail.workspaceType)}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="空间编号">
                <Content>{detail.roomNo}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="门牌号">
                <Content>{detail.corpRoomAlias}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="房间面积">
                <Content>{detail.workspaceArea}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="所属园区">
                <Content>{detail.belongPark}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="所属楼层">
                <Content>{detail.floorNo}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="容纳人数">
                <Content>{getSelectedTextFromList(workspaceSizeTypeList, detail.workspaceSizeType)}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="租金">
                <Content>{rentCost || detail.rentCost}</Content>
              </FormItem>
            </Col>
          </Row>
        </Panel>
        <Panel>
          <TitleContainer>
            <Title>入驻信息</Title>
            {
              detail.rentStatus === 0 ? (
                <Link to="/company?cs=negtiating"><Button>新增入驻企业</Button></Link>
              ) : null
            }
          </TitleContainer>
          <Row gutter={[16, 24]}>
            <Col span={12}>
              <FormItem label="入驻状态">
                <Content>{detail.rentStatus === 1 ? '已租' : '待租'}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="入驻公司">
                <Content>{detail.rentCorpName}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="合约时间">
                <Content>{detail.rentExpireDate}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="合约费用">
                <Content>{detail.rentCost}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="销售">
                <Content>{detail.salesman}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="销售手机">
                <Content></Content>
              </FormItem>
            </Col>
          </Row>
          <SalePurpose workspaceId={id} roomNo={detail.roomNo} />
        </Panel>
        {
          isShowDeviceList ? (
            <>
              <Panel>
                <Title>包含基础设施</Title>
                <Space style={{ marginBottom: 29 }}>
                  <Button icon={<PlusOutlined />} type="primary" onClick={() => setBindDeviceVisible1(true)}>添加</Button>
                  {/* <Button>批量导入/导出</Button> */}
                </Space>
                <Table
                  dataSource={deviceList1}
                  pagination={{
                    total: deviceList1Count,
                    pageSize: deviceList1PageSize,
                    current: deviceList1PageIndex,
                    showSizeChanger: true,
                    onChange: onDeviceList1Pagination
                  }}
                >
                  <Column title="名称" dataIndex="deviceName" />
                  <Column
                    title="种类"
                    dataIndex="deviceType"
                    render={(_: number) => getSelectedTextFromList(deviceTypeList, _)}
                  />
                  <Column title="编号" dataIndex="deviceNo" />
                  <Column title="型号" dataIndex="deviceModel" />
                  <Column title="关联房间" dataIndex="belongRoom" />
                  <Column
                    title="使用状态"
                    dataIndex="deviceStatus"
                    render={text => getSelectedTextFromList(deviceStatusList, text)}
                  />
                  <Column
                    title="二维码"
                    dataIndex="deviceNo"
                    render={(_: string) => (
                      <Popover content={<AsyncQRCode value={getDeviceQRCodeUrl(_)} />}>
                        <QrcodeOutlined style={{ fontSize: 20 }} />
                      </Popover>
                    )}
                  />
                  <Column
                    title="操作"
                    dataIndex="deviceId"
                    render={(deviceId: number, record: any) => {
                      return (
                        <Space>
                          <Button type="link" onClick={() => {
                            setEditDeviceVisible(true);
                            setSelectedDeviceId(deviceId);
                          }}>编辑</Button>
                          <Popconfirm
                            title={`确认要解除房间${detail.roomNo}与设备${record.deviceNo}的关联吗？`}
                            placement="topRight"
                            onConfirm={() => {
                              updateDevice({
                                deviceId,
                                belongRoom: '-',
                                belongFloor: '-',
                              }).then(getBoundDeviceList)
                            }}
                          >
                            <Button type="link" danger>删除</Button>
                          </Popconfirm>
                        </Space>
                      )
                    }}
                  />
                </Table>
              </Panel>
              <Panel>
                <Title>包含固定资产</Title>
                <Space style={{ marginBottom: 29 }}>
                  <Button icon={<PlusOutlined />} type="primary" onClick={() => setBindDeviceVisible2(true)}>添加</Button>
                  {/* <Button>批量导入/导出</Button> */}
                </Space>
                <Table
                  dataSource={deviceList2}
                  pagination={{
                    total: deviceList2Count,
                    pageSize: deviceList2PageSize,
                    current: deviceList2PageIndex,
                    showSizeChanger: true,
                    onChange: onDeviceList2Pagination
                  }}
                >
                  <Column title="名称" dataIndex="deviceName" />
                  <Column
                    title="种类"
                    dataIndex="deviceType"
                    render={(_: number) => getSelectedTextFromList(deviceTypeList, _)}
                  />
                  <Column title="编号" dataIndex="deviceNo" />
                  <Column title="型号" dataIndex="deviceModel" />
                  <Column title="关联房间" dataIndex="belongRoom" />
                  <Column
                    title="使用状态"
                    dataIndex="deviceStatus"
                    render={text => getSelectedTextFromList(deviceStatusList, text)}
                  />
                  <Column
                    title="二维码"
                    dataIndex="deviceNo"
                    render={(_: string) => (
                      <Popover content={<AsyncQRCode value={getDeviceQRCodeUrl(_)} />}>
                        <QrcodeOutlined style={{ fontSize: 20 }} />
                      </Popover>
                    )}
                  />
                  <Column
                    title="操作"
                    dataIndex="deviceId"
                    render={(deviceId: number, record: any) => {
                      return (
                        <Space>
                          <Button type="link" onClick={() => {
                            setEditDeviceVisible(true);
                            setSelectedDeviceId(deviceId);
                          }}>编辑</Button>
                          <Popconfirm
                            title={`确认要解除房间${detail.roomNo}与设备${record.deviceNo}的关联吗？`}
                            placement="topRight"
                            onConfirm={() => {
                              updateDevice({
                                deviceId,
                                belongRoom: '-',
                                belongFloor: '-',
                              }).then(getBoundDeviceList)
                            }}
                          >
                            <Button type="link" danger>删除</Button>
                          </Popconfirm>
                        </Space>
                      )
                    }}
                  />
                </Table>
              </Panel>
            </>
          ) : null
        }
        
        <BindDevice
          visible={bindDeviceVisible1}
          roomNo={detail.roomNo}
          floorNo={detail.floorNo}
          assetType={0}
          onClose={() => {
            setBindDeviceVisible1(false)
            getBoundDeviceList()
          }}
        />
        <BindDevice
          visible={bindDeviceVisible2}
          roomNo={detail.roomNo}
          floorNo={detail.floorNo}
          assetType={1}
          onClose={() => {
            setBindDeviceVisible2(false)
            getBoundDeviceList()
          }}
        />
        <EditDevice
         visible={editDeviceVisible}
         deviceId={selectedDeviceId}
         onClose={() => setEditDeviceVisible(false)}
         onOk={() => {
           setEditDeviceVisible(false);
           getBoundDeviceList();
         }}
        />
      </Container>
      
    </>
  )
}

export default WorkspaceDetail;