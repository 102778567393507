import { Form, Button, Row, Col, Select, Input as AntInput, InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import Title from '@/components/Form/Title';
import Input from '@/components/Form/Input';
import styled from 'styled-components';
import { ValueAndText } from '@/types/common';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { addRepairTask } from '@/services/task';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`;

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

interface Props {

}

export default function TaskEdit(props: Props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<any>();
  const deviceTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.deviceTypeList);
  const floorRoomList: Array<any> = useSelector((state: any) => state.common.floorRoomList);
  const [loading, setLoading] = useState(false)
  const [floorNo, setFloorNo] = useState('');
  
  const save = (values: any) => {
    addRepairTask({
      ...values,
    }).then(() => {
      navigate('/task');
    });
  }

  return (
    <>
      <CloseButtonContainer>
        <Button type="text" onClick={() => navigate(-1) }><CloseOutlined /></Button>
      </CloseButtonContainer>
      <Container>
        <Form form={form} onFinish={save}>
          <Panel>
            <Title>任务信息</Title>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem
                  label="申报类型"
                  name="applyType"
                >
                  <Select>
                    <Option value={0}>报修</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="设备类型"
                  name="deviceType"
                >
                  <Select>
                    {
                      deviceTypeList.map((item, index) => (
                        <Option key={`device_add_devicetype_opt_${index}`} value={item.value}>{item.text}</Option>
                      ))
                    }
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="房间号"
                  name="roomNo"
                >
                  <Select onChange={(_, opt: any) => setFloorNo(opt.floorno)}>
                    {
                      floorRoomList.map((floor, floorIndex) => (
                        <OptGroup key={`task_edit_floor_${floorIndex}`} label={floor.text}>
                          {
                            floor.children.map((room: any, roomIndex: number) => (
                              <Option key={`task_edit_room_${roomIndex}`} value={room.value} floorno={room.floorNo}>{room.text}</Option>
                            ))
                          }
                        </OptGroup>
                      ))
                    }
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="问题区域"
                  name="problemArea"
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="问题属性"
                  name="problemAttr"
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="费用"
                  name="repairAmount"
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="报修标题" name="repairTitle">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="报修数量"
                  name="repairCount"
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label="报修描述" name="repairDesc">
                  <Input />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="解决路径"
                  name="solution"
                >
                  <AntInput.TextArea rows={4} />
                </FormItem>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Panel>
          <Panel style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}>
            <Button onClick={() => {
              // navigate(-1);
              navigate('/task')
            }}>取消</Button>
            <Button
              type="primary"
              style={{ marginLeft: 12 }}
              htmlType="submit"
              loading={loading}
            >确定</Button>
          </Panel>
        </Form>
      </Container>
    </>
  )
}