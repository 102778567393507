import { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Popover, Space, Table } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Select from '@/components/Select';
import { getWorkspaceAcAmmeterDayStat, getBillList, getBillListCount } from '@/services/bill';
import { getFloorRoomList } from '@/services/workspace';
import RoomDailyDetail from './RoomDailyDetail';
import { billItemTypeMap } from '@/common/constants';
import { Link } from 'react-router-dom';

const { Column } =  Table;

const Container = styled.div``

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
`

interface Props {}

const DailyBill2: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [queryDate, setQueryDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [floorNo, setFloorNo] = useState('');
  const [roomNo, setRoomNo] = useState('');
  const [queryStartDate, setQueryStartDate] = useState<dayjs.Dayjs | null>(null);
  const [queryEndDate, setQueryEndDate] = useState<dayjs.Dayjs | null>(null);
  const [floorRoomMap, setFloorRoomMap] = useState<any>({});
  const [floorList, setFloorList] = useState<any[]>([]);
  const [roomList, setRoomList] = useState<any[]>([]);
  const [corpName, setCorpName] = useState('');

  const expandedRowRender = (record: any, index: number) => (
    <Table dataSource={record.itemList} pagination={false}>
      <Column title="账单标题" dataIndex="itemTitle" />
      <Column title="房间编号" dataIndex="roomNo" />
      <Column title="明细类型" dataIndex="itemType" render={(data) => billItemTypeMap[data] ?? ''} />
      <Column title="状态" dataIndex="itemStatus" render={(data) => data == 1 ? '有效' : '无效'} />
      <Column title="减免金额" dataIndex="freeAmount" />
      <Column title="应付金额" dataIndex="payableAmount" />
      <Column title="总金额" dataIndex="totalAmount" />
      <Column title="备注" dataIndex="cmt" />
    </Table>
  )

  const getData = (pageIndex = 1, pageSize = 30) => {
    setLoading(true);
    const params = {
      // roomNo,
      // floorNo,
      corpName,
      billDateSign: queryDate?.format('YYYYMMDD'),
      // beginCreateTime: queryStartDate?.format('YYYY-MM-DD 00:00:00'),
      // endCreateTime: queryEndDate?.format('YYYY-MM-DD 00:00:00')
    }
    getBillList({
      currentPage: pageIndex,
      pageSize,
      ...params
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data.map((d: any) => ({...d, key: d.billId})));
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
    getBillListCount(params).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataCount(data);
      }
    })
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  }

  const onPagination = (page: number, pageSize_?: number | undefined) => {
    getData(page, pageSize_)
  }

  const getExportUrl = () => {
    let billDateSign = queryDate?.format('YYYYMMDD');
    return `${window.location.origin}/api/permission/billRecord/export?billDateSign=${billDateSign}`
  }

  const getBillDetailExportUrl = () => {
    let billDateSign = queryDate?.format('YYYYMMDD');
    return `${window.location.origin}/api/permission/billitem/export?billDateSign=${billDateSign}`
  }


  useEffect(() => {
    if (queryDate) {
      getWorkspaceAcAmmeterDayStat(queryDate.format('YYYY-MM-DD')).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          const newDataSource: Array<any> = [];
          data.forEach((d: any) => {
            const { ammeterDayDataList, subList, ...roomData } = d;
            if (ammeterDayDataList.length > 0) {
              ammeterDayDataList.forEach((dayData: any) => {
                newDataSource.push({
                  roomData,
                  ...dayData
                })
              });
              return;
            }

            subList.forEach((subData: any) => {
              const { subAmmeterDayDataList: ammeterDayDataList, ...subRoomData } = subData;
              subRoomData.ammeterDayDataList.forEach((dayData: any) => {
                newDataSource.push({
                  roomData: subRoomData,
                  ...dayData
                })
              });
            })
            
          });

          setDataSource(newDataSource);
        }
      })
    }
  }, [queryDate])

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      const list = Object.keys(res.data).sort((prev: string, next: string) => {
        return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
      }) || []
      setFloorList(list.map(item => ({
        value: item,
        text: item
      })));
      setFloorRoomMap(res.data);
    })
  }, [])

  useEffect(() => {
    if (floorNo) {
      setRoomList(floorRoomMap[floorNo].filter((item: string) => !item.includes('-')).map((item: string) => ({
        value: item,
        text: item
      })))
    }
  }, [floorNo])


  useEffect(() => {
    getData();
  }, [queryDate, floorNo, roomNo, corpName])


  return (
    <Container>
      <SearchBar>
        <Space>
          <DatePicker value={queryDate} onChange={val => setQueryDate(val)} />
          {/* <Select label="所属楼层" value={floorNo} options={floorList} onChange={val => setFloorNo(val)} /> */}
          {/* <Select label="所属房间" value={roomNo} options={roomList} onChange={val => setRoomNo(val)} /> */}
          <Input placeholder="公司名称" onChange={e => setCorpName(e.target.value)} />
        </Space>
        <Space>
          <Link target="blank" to={getExportUrl()}>
            <Button>导出账单</Button>
          </Link>
          <Link target="blank" to={getBillDetailExportUrl()}>
            <Button>导出账单明细</Button>
          </Link>
        </Space>
      </SearchBar>
      
      <Table
        dataSource={dataSource}
        expandable={{ expandedRowRender }}
        loading={loading}
        pagination={{
          total: dataCount,
          pageSize: pageSize,
          current: pageIndex,
          onChange: onPagination,
          showSizeChanger: true
        }}
        scroll={{ x: 1500 }}
      >
        <Column title="账单标题" dataIndex="billTitle" />
        <Column title="公司名称" dataIndex="corpName" />
        <Column title="房间号" dataIndex="roomNo" width={100} />
        <Column title="账单名称" dataIndex="billTitle" />
        <Column title="减免金额" dataIndex="freeAmount" width={100} />
        <Column title="应付金额" dataIndex="payableAmount" width={100} />
        <Column title="实付金额" dataIndex="paidAmount" width={100} />
        <Column title="总金额" dataIndex="totalAmount" width={100} />
        <Column title="收款方" dataIndex="payeeName" width={120} />
        <Column title="账单时间" dataIndex="billDateSign" width={100} />
        {/* <Column
          title="操作" 
          render={(_: string, record: any) => (
            <Button type="link" onClick={() => {
              setSelectedRecord(record)
              setIsShowDetail(true)
            }}>详情</Button>
          )}
        /> */}
      </Table>
    </Container>
  )
}

export default DailyBill2;