import bg from '@/assets/energy/floors/9/9.png';
import img_900_1_common from '@/assets/energy/floors/9/common/900-1.png';
import img_900_2_common from '@/assets/energy/floors/9/common/900-2.png';
import img_900_3_common from '@/assets/energy/floors/9/common/900-3.png';
import img_900_4_common from '@/assets/energy/floors/9/common/900-4.png';
import img_900_5_common from '@/assets/energy/floors/9/common/900-5.png';
import img_900_6_common from '@/assets/energy/floors/9/common/900-6.png';
import img_900_7_common from '@/assets/energy/floors/9/common/900-7.png';
import img_901_1_common from '@/assets/energy/floors/9/common/901-1.png';
import img_901_2_common from '@/assets/energy/floors/9/common/901-2.png';
import img_901_3_common from '@/assets/energy/floors/9/common/901-3.png';
import img_901_4_common from '@/assets/energy/floors/9/common/901-4.png';
import img_902_1_common from '@/assets/energy/floors/9/common/902-1.png';
import img_902_2_common from '@/assets/energy/floors/9/common/902-2.png';
import img_902_3_common from '@/assets/energy/floors/9/common/902-3.png';
import img_902_4_common from '@/assets/energy/floors/9/common/902-4.png';
import img_903_1_common from '@/assets/energy/floors/9/common/903-1.png';
import img_903_2_common from '@/assets/energy/floors/9/common/903-2.png';
import img_903_3_common from '@/assets/energy/floors/9/common/903-3.png';
import img_903_4_common from '@/assets/energy/floors/9/common/903-4.png';
import img_905_1_common from '@/assets/energy/floors/9/common/905-1.png';
import img_905_2_common from '@/assets/energy/floors/9/common/905-2.png';
import img_905_3_common from '@/assets/energy/floors/9/common/905-3.png';
import img_905_4_common from '@/assets/energy/floors/9/common/905-4.png';
import img_906_1_common from '@/assets/energy/floors/9/common/906-1.png';
import img_906_2_common from '@/assets/energy/floors/9/common/906-2.png';
import img_906_3_common from '@/assets/energy/floors/9/common/906-3.png';
import img_907_1_common from '@/assets/energy/floors/9/common/907-1.png';
import img_907_2_common from '@/assets/energy/floors/9/common/907-2.png';
import img_907_3_common from '@/assets/energy/floors/9/common/907-3.png';
import img_908_1_common from '@/assets/energy/floors/9/common/908-1.png';
import img_908_2_common from '@/assets/energy/floors/9/common/908-2.png';
import img_908_3_common from '@/assets/energy/floors/9/common/908-3.png';
import img_909_1_common from '@/assets/energy/floors/9/common/909-1.png';
import img_909_2_common from '@/assets/energy/floors/9/common/909-2.png';
import img_909_3_common from '@/assets/energy/floors/9/common/909-3.png';
import img_909_4_common from '@/assets/energy/floors/9/common/909-4.png';
import img_910_1_common from '@/assets/energy/floors/9/common/910-1.png';
import img_910_2_common from '@/assets/energy/floors/9/common/910-2.png';
import img_910_3_common from '@/assets/energy/floors/9/common/910-3.png';
import img_910_4_common from '@/assets/energy/floors/9/common/910-4.png';
import img_930_common from '@/assets/energy/floors/9/common/930.png';
import img_931_common from '@/assets/energy/floors/9/common/931.png';
import img_932_common from '@/assets/energy/floors/9/common/932.png';
import img_933_common from '@/assets/energy/floors/9/common/933.png';
import img_934_common from '@/assets/energy/floors/9/common/934.png';
import img_935_common from '@/assets/energy/floors/9/common/935.png';
import img_936_common from '@/assets/energy/floors/9/common/936.png';
import img_937_common from '@/assets/energy/floors/9/common/937.png';
import img_938_common from '@/assets/energy/floors/9/common/938.png';
import img_939_common from '@/assets/energy/floors/9/common/939.png';
import img_940_common from '@/assets/energy/floors/9/common/940.png';
import img_941_common from '@/assets/energy/floors/9/common/941.png';
import img_942_common from '@/assets/energy/floors/9/common/942.png';
import img_943_common from '@/assets/energy/floors/9/common/943.png';
import img_944_common from '@/assets/energy/floors/9/common/944.png';

import img_900_1_selected from '@/assets/energy/floors/9/selected/900-1.png';
import img_900_2_selected from '@/assets/energy/floors/9/selected/900-2.png';
import img_900_3_selected from '@/assets/energy/floors/9/selected/900-3.png';
import img_900_4_selected from '@/assets/energy/floors/9/selected/900-4.png';
import img_900_5_selected from '@/assets/energy/floors/9/selected/900-5.png';
import img_900_6_selected from '@/assets/energy/floors/9/selected/900-6.png';
import img_900_7_selected from '@/assets/energy/floors/9/selected/900-7.png';
import img_901_1_selected from '@/assets/energy/floors/9/selected/901-1.png';
import img_901_2_selected from '@/assets/energy/floors/9/selected/901-2.png';
import img_901_3_selected from '@/assets/energy/floors/9/selected/901-3.png';
import img_901_4_selected from '@/assets/energy/floors/9/selected/901-4.png';
import img_902_1_selected from '@/assets/energy/floors/9/selected/902-1.png';
import img_902_2_selected from '@/assets/energy/floors/9/selected/902-2.png';
import img_902_3_selected from '@/assets/energy/floors/9/selected/902-3.png';
import img_902_4_selected from '@/assets/energy/floors/9/selected/902-4.png';
import img_903_1_selected from '@/assets/energy/floors/9/selected/903-1.png';
import img_903_2_selected from '@/assets/energy/floors/9/selected/903-2.png';
import img_903_3_selected from '@/assets/energy/floors/9/selected/903-3.png';
import img_903_4_selected from '@/assets/energy/floors/9/selected/903-4.png';
import img_905_1_selected from '@/assets/energy/floors/9/selected/905-1.png';
import img_905_2_selected from '@/assets/energy/floors/9/selected/905-2.png';
import img_905_3_selected from '@/assets/energy/floors/9/selected/905-3.png';
import img_905_4_selected from '@/assets/energy/floors/9/selected/905-4.png';
import img_906_1_selected from '@/assets/energy/floors/9/selected/906-1.png';
import img_906_2_selected from '@/assets/energy/floors/9/selected/906-2.png';
import img_906_3_selected from '@/assets/energy/floors/9/selected/906-3.png';
import img_907_1_selected from '@/assets/energy/floors/9/selected/907-1.png';
import img_907_2_selected from '@/assets/energy/floors/9/selected/907-2.png';
import img_907_3_selected from '@/assets/energy/floors/9/selected/907-3.png';
import img_908_1_selected from '@/assets/energy/floors/9/selected/908-1.png';
import img_908_2_selected from '@/assets/energy/floors/9/selected/908-2.png';
import img_908_3_selected from '@/assets/energy/floors/9/selected/908-3.png';
import img_909_1_selected from '@/assets/energy/floors/9/selected/909-1.png';
import img_909_2_selected from '@/assets/energy/floors/9/selected/909-2.png';
import img_909_3_selected from '@/assets/energy/floors/9/selected/909-3.png';
import img_909_4_selected from '@/assets/energy/floors/9/selected/909-4.png';
import img_910_1_selected from '@/assets/energy/floors/9/selected/910-1.png';
import img_910_2_selected from '@/assets/energy/floors/9/selected/910-2.png';
import img_910_3_selected from '@/assets/energy/floors/9/selected/910-3.png';
import img_910_4_selected from '@/assets/energy/floors/9/selected/910-4.png';
import img_930_selected from '@/assets/energy/floors/9/selected/930.png';
import img_931_selected from '@/assets/energy/floors/9/selected/931.png';
import img_932_selected from '@/assets/energy/floors/9/selected/932.png';
import img_933_selected from '@/assets/energy/floors/9/selected/933.png';
import img_934_selected from '@/assets/energy/floors/9/selected/934.png';
import img_935_selected from '@/assets/energy/floors/9/selected/935.png';
import img_936_selected from '@/assets/energy/floors/9/selected/936.png';
import img_937_selected from '@/assets/energy/floors/9/selected/937.png';
import img_938_selected from '@/assets/energy/floors/9/selected/938.png';
import img_939_selected from '@/assets/energy/floors/9/selected/939.png';
import img_940_selected from '@/assets/energy/floors/9/selected/940.png';
import img_941_selected from '@/assets/energy/floors/9/selected/941.png';
import img_942_selected from '@/assets/energy/floors/9/selected/942.png';
import img_943_selected from '@/assets/energy/floors/9/selected/943.png';
import img_944_selected from '@/assets/energy/floors/9/selected/944.png';

const width = (w: number) => `${100 * (w / 980)}%`;
const left = (l: number) => `${100 * (l - 300) / 980}%`;
const top = (t: number) => `${100 * (t - 269) / 400}%`;
const fontSize = (w: number, f: number) => f > 12 ? '12px' : '9px'


// {
//   title: '5xx-',
//   roomNo: '5xx-',
//   width: width(w),
//   left: left(),
//   top: top(),
//   fontSize: fontSize(w, 12),
//   color: '#808080',
//   writingMode: 'vertical-lr',
//   image: img_5xx__common,
//   selectedImage: img_5xx__selected,
// },

const initialRoomList = [
  {
    title: '新风机房',
    roomNo: '930',
    width: width(42),
    left: left(533),
    top: top(272),
    fontSize: fontSize(42, 12),
    writingMode: 'vertical-lr',
    image: img_930_common,
    selectedImage: img_930_selected,
  },
  {
    title: '901-1',
    titleLeft: `${100 * (607 - 577) / 152}%`,
    titleWidth: 'atuo',
    roomNo: '901-1',
    width: width(152),
    left: left(577),
    top: top(272),
    fontSize: fontSize(152,16),
    image: img_901_1_common,
    selectedImage: img_901_1_selected,
  },
  {
    title: '901-2',
    roomNo: '901-2',
    width: width(30),
    left: left(679),
    top: top(332),
    fontSize: fontSize(30, 12),
    writingMode: 'vertical-lr',
    image: img_901_2_common,
    // selectedImage: img_901_2_selected,
  },
  {
    title: '901-3',
    roomNo: '901-3',
    width: width(50),
    left: left(679),
    top: top(272),
    fontSize: fontSize(50, 12),
    image: img_901_3_common,
    // selectedImage: img_901_3_selected,
  },
  {
    title: '901-4',
    roomNo: '901-4',
    width: width(42),
    left: left(635),
    top: top(346),
    fontSize: fontSize(42, 12),
    image: img_901_4_common,
    // selectedImage: img_901_4_selected,
  },
  {
    title: '902-1',
    titleLeft: `${100 * (823 - 731) / 174}%`,
    titleWidth: 'atuo',
    roomNo: '902-1',
    width: width(174),
    left: left(731),
    top: top(272),
    fontSize: fontSize(174, 16),
    image: img_902_1_common,
    selectedImage: img_902_1_selected,
  },
  {
    title: '902-2',
    roomNo: '902-2',
    width: width(30),
    left: left(751),
    top: top(332),
    fontSize: fontSize(30, 12),
    writingMode: 'vertical-lr',
    image: img_902_2_common,
    // selectedImage: img_902_2_selected,
  },
  {
    title: '902-3',
    roomNo: '902-3',
    width: width(50),
    left: left(731),
    top: top(272),
    fontSize: fontSize(50, 12),
    image: img_902_3_common,
    // selectedImage: img_902_3_selected,
  },
  {
    title: '902-4',
    roomNo: '902-4',
    width: width(42),
    left: left(783),
    top: top(346),
    fontSize: fontSize(42, 12),
    image: img_902_4_common,
    // selectedImage: img_902_4_selected,
  },
  {
    title: '洽谈室',
    roomNo: '933',
    width: width(38),
    left: left(711),
    top: top(332),
    fontSize: fontSize(38, 12),
    image: img_933_common,
    selectedImage: img_933_selected,
  },
  {
    title: '903-1',
    titleLeft: `${100 * (949 - 907) / 174}%`,
    titleWidth: 'atuo',
    roomNo: '903-1',
    width: width(174),
    left: left(907),
    top: top(272),
    fontSize: fontSize(174, 16),
    image: img_903_1_common,
    selectedImage: img_903_1_selected,
  },
  {
    title: '903-2',
    roomNo: '903-2',
    width: width(30),
    left: left(1031),
    top: top(332),
    fontSize: fontSize(30, 12),
    writingMode: 'vertical-lr',
    image: img_903_2_common,
    // selectedImage: img_903_2_selected,
  },
  {
    title: '903-3',
    roomNo: '903-3',
    width: width(50),
    left: left(1031),
    top: top(272),
    fontSize: fontSize(50, 12),
    image: img_903_3_common,
    // selectedImage: img_903_3_selected,
  },
  {
    title: '903-4',
    roomNo: '903-4',
    width: width(42),
    left: left(987),
    top: top(346),
    fontSize: fontSize(42, 12),
    image: img_903_4_common,
    // selectedImage: img_903_4_selected,
  },
  {
    title: '905-1',
    roomNo: '905-1',
    width: width(194),
    left: left(1083),
    top: top(272),
    fontSize: fontSize(194, 16),
    image: img_905_1_common,
    selectedImage: img_905_1_selected,
  },
  {
    title: '905-2',
    roomNo: '905-2',
    width: width(30),
    left: left(1103),
    top: top(332),
    fontSize: fontSize(30, 12),
    image: img_905_2_common,
    // selectedImage: img_905_2_selected,
  },
  {
    title: '905-3',
    roomNo: '905-3',
    width: width(50),
    left: left(1083),
    top: top(272),
    fontSize: fontSize(50, 12),
    image: img_905_3_common,
    // selectedImage: img_905_3_selected,
  },
  {
    title: '905-4',
    roomNo: '905-4',
    width: width(62),
    left: left(1215),
    top: top(346),
    fontSize: fontSize(62, 12),
    image: img_905_4_common,
    // selectedImage: img_905_4_selected,
  },
  {
    title: '洽谈室',
    roomNo: '936',
    width: width(38),
    left: left(1063),
    top: top(332),
    fontSize: fontSize(38, 12),
    image: img_936_common,
    selectedImage: img_936_selected,
  },
  {
    title: '新风机房',
    roomNo: '937',
    width: width(100),
    left: left(1177),
    top: top(374),
    fontSize: fontSize(100, 12),
    image: img_937_common,
    selectedImage: img_937_selected,
  },
  {
    title: '906-1',
    titleTop: `${100 * (442 - 412) / 100}%`,
    roomNo: '906-1',
    width: width(100),
    left: left(1177),
    top: top(412),
    fontSize: fontSize(100, 16),
    image: img_906_1_common,
    selectedImage: img_906_1_selected,
  },
  {
    title: '906-2',
    roomNo: '906-2',
    width: width(34),
    left: left(1199),
    top: top(492),
    fontSize: fontSize(34, 12),
    image: img_906_2_common,
    // selectedImage: img_906_2_selected,
  },
  {
    title: '906-3',
    roomNo: '906-3',
    width: width(42),
    left: left(1235),
    top: top(492),
    fontSize: fontSize(42, 12),
    image: img_906_3_common,
    // selectedImage: img_906_3_selected,
  },
  {
    title: '907-1',
    titleLeft: `${100 * (1213 - 1141) / 136}%`,
    titleWidth: 'atuo',
    roomNo: '907-1',
    width: width(136),
    left: left(1141),
    top: top(566),
    fontSize: fontSize(136, 16),
    image: img_907_1_common,
    selectedImage: img_907_1_selected,
  },
  {
    title: '907-2',
    roomNo: '907-2',
    width: width(48),
    left: left(1141),
    top: top(596),
    fontSize: fontSize(48, 12),
    image: img_907_2_common,
    // selectedImage: img_907_2_selected,
  },
  {
    title: '907-3',
    roomNo: '907-3',
    width: width(48),
    left: left(1141),
    top: top(628),
    fontSize: fontSize(48, 12),
    image: img_907_3_common,
    // selectedImage: img_907_3_selected,
  },
  {
    title: '908-1',
    titleLeft: `${100 * (1037 - 1023) / 116}%`,
    titleWidth: 'atuo',
    roomNo: '908-1',
    width: width(116),
    left: left(1023),
    top: top(566),
    fontSize: fontSize(116, 16),
    image: img_908_1_common,
    selectedImage: img_908_1_selected,
  },
  {
    title: '908-2',
    roomNo: '908-2',
    width: width(48),
    left: left(1091),
    top: top(596),
    fontSize: fontSize(48, 12),
    image: img_908_2_common,
    // selectedImage: img_908_2_selected,
  },
  {
    title: '908-3',
    roomNo: '908-3',
    width: width(48),
    left: left(1091),
    top: top(628),
    fontSize: fontSize(48, 12),
    image: img_908_3_common,
    // selectedImage: img_908_3_selected,
  },
  {
    title: '茶水休闲区',
    roomNo: '900-3',
    width: width(114),
    left: left(907),
    top: top(566),
    fontSize: fontSize(114, 12),
    image: img_900_3_common,
    selectedImage: img_900_3_selected,
  },
  {
    title: '909-1',
    titleLeft: `${100 * (823 - 731) / 174}%`,
    titleWidth: 'atuo',
    roomNo: '909-1',
    width: width(174),
    left: left(731),
    top: top(566),
    fontSize: fontSize(174, 12),
    image: img_909_1_common,
    selectedImage: img_909_1_selected,
  },
  {
    title: '909-2',
    roomNo: '909-2',
    width: width(30),
    left: left(751),
    top: top(566),
    fontSize: fontSize(30, 12),
    writingMode: 'vertical-lr',
    image: img_909_2_common,
    // selectedImage: img_909_2_selected,
  },
  {
    title: '909-3',
    roomNo: '909-3',
    width: width(50),
    left: left(731),
    top: top(608),
    fontSize: fontSize(50, 12),
    image: img_909_3_common,
    // selectedImage: img_909_3_selected,
  },
  {
    title: '909-4',
    roomNo: '909-4',
    width: width(42),
    left: left(783),
    top: top(566),
    fontSize: fontSize(42, 12),
    image: img_909_4_common,
    // selectedImage: img_909_4_selected,
  },
  {
    title: '910-1',
    roomNo: '910-1',
    width: width(196),
    left: left(533),
    top: top(566),
    fontSize: fontSize(196, 16),
    image: img_910_1_common,
    selectedImage: img_910_1_selected,
  },
  {
    title: '910-2',
    roomNo: '910-2',
    width: width(30),
    left: left(679),
    top: top(566),
    fontSize: fontSize(30, 12),
    image: img_910_2_common,
    // selectedImage: img_910_2_selected,
  },
  {
    title: '910-3',
    roomNo: '910-3',
    width: width(50),
    left: left(679),
    top: top(608),
    fontSize: fontSize(50, 12),
    image: img_910_3_common,
    // selectedImage: img_910_3_selected,
  },
  {
    title: '910-4',
    roomNo: '910-4',
    width: width(62),
    left: left(533),
    top: top(566),
    fontSize: fontSize(62, 12),
    image: img_910_4_common,
    // selectedImage: img_910_4_selected,
  },
  {
    title: '洽谈室',
    roomNo: '942',
    width: width(38),
    left: left(711),
    top: top(566),
    fontSize: fontSize(38, 12),
    image: img_942_common,
    selectedImage: img_942_selected,
  },
  {
    title: '900-4',
    roomNo: '900-4',
    width: width(100),
    left: left(533),
    top: top(408),
    fontSize: fontSize(100, 12),
    image: img_900_4_common,
    selectedImage: img_900_4_selected,
  },
  {
    title: '储藏室',
    roomNo: '931',
    width: width(32),
    left: left(635),
    top: top(408),
    fontSize: fontSize(32, 12),
    writingMode: 'vertical-lr',
    image: img_931_common,
    selectedImage: img_931_selected,
  },
  {
    title: '储藏室',
    roomNo: '944',
    width: width(32),
    left: left(635),
    top: top(478),
    fontSize: fontSize(32, 12),
    writingMode: 'vertical-lr',
    image: img_944_common,
    selectedImage: img_944_selected,
  },
  {
    title: '932',
    roomNo: '932',
    width: width(82),
    left: left(669),
    top: top(408),
    fontSize: fontSize(82, 12),
    image: img_932_common,
    selectedImage: img_932_selected,
  },
  {
    title: '943',
    roomNo: '943',
    width: width(82),
    left: left(669),
    top: top(478),
    fontSize: fontSize(82, 12),
    image: img_943_common,
    selectedImage: img_943_selected,
  },
  {
    title: '934',
    roomNo: '934',
    width: width(64),
    left: left(787),
    top: top(408),
    fontSize: fontSize(64, 12),
    image: img_934_common,
    selectedImage: img_934_selected,
  },
  {
    title: '940',
    roomNo: '940',
    width: width(64),
    left: left(787),
    top: top(484),
    fontSize: fontSize(64, 12),
    image: img_940_common,
    selectedImage: img_940_selected,
  },
  {
    title: '941',
    roomNo: '941',
    width: width(32),
    left: left(819),
    top: top(456),
    fontSize: fontSize(32, 12),
    image: img_941_common,
    selectedImage: img_941_selected,
  },
  {
    title: '939',
    roomNo: '939',
    width: width(40),
    left: left(901),
    top: top(500),
    fontSize: fontSize(40, 12),
    image: img_939_common,
    selectedImage: img_939_selected,
  },
  {
    title: '935',
    roomNo: '935',
    width: width(22),
    left: left(1034),
    top: top(408),
    fontSize: fontSize(22, 12),
    image: img_935_common,
    selectedImage: img_935_selected,
  },
  {
    title: '938',
    roomNo: '938',
    width: width(30),
    left: left(1030),
    top: top(484),
    fontSize: fontSize(30, 12),
    image: img_938_common,
    selectedImage: img_938_selected,
  },
  {
    title: '900-1',
    roomNo: '900-1',
    width: width(744),
    left: left(533),
    top: top(534),
    fontSize: fontSize(744, 12),
    color: '#808080',
    image: img_900_1_common,
    selectedImage: img_900_1_selected,
  },
  {
    title: '900-2',
    roomNo: '900-2',
    width: width(640),
    left: left(533),
    top: top(376),
    fontSize: fontSize(640, 12),
    color: '#808080',
    image: img_900_2_common,
    selectedImage: img_900_2_selected,
  },
  {
    title: '900-5',
    roomNo: '900-5',
    width: width(28),
    left: left(1145),
    top: top(408),
    fontSize: fontSize(28, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_900_5_common,
    selectedImage: img_900_5_selected,
  },
  {
    title: '900-6',
    roomNo: '900-6',
    width: width(40),
    left: left(946),
    top: top(408),
    fontSize: fontSize(40, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_900_6_common,
    selectedImage: img_900_6_selected,
  },
  {
    title: '900-7',
    roomNo: '900-7',
    width: width(28),
    left: left(755),
    top: top(408),
    fontSize: fontSize(28, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_900_7_common,
    selectedImage: img_900_7_selected,
  },
]

export default {
  bg,
  initialRoomList
}