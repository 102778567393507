import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserInfo } from "../../types/common";

const initialState: UserInfo = {
  id: '',
  name: '',
  corpName: '',
  corpId: '',
  isLogin: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.id = action.payload.id ?? ''
      state.name = action.payload.name ?? ''
      state.isLogin = action.payload.isLogin
      state.corpName = action.payload.corpName ?? ''
      state.corpId = action.payload.corpId ?? ''
    }
  }
})

export const { setUserInfo } = userSlice.actions

export default userSlice.reducer