import { useState, useEffect } from 'react';
import { Input, Checkbox, Spin, Space, Button, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Title from '@/components/Form/Title';
import { getRoleList, getUserRoleList, grantRoleToUser, revokeRoleOfUser, getUserInfoById } from '@/services/user';
import dayjs from 'dayjs';

const Container = styled.div``

const Label = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1F2329;
`

const RolesContainer = styled.div`
  margin-top: 22px;
  border-radius: 4px;
  border: 1px solid #DEE0E3;
  padding: 14px 24px 0px 24px;
`

const RoleListTitle = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #646A73;
`

const Role = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #DEE0E3;
  padding: 9px 0;
  &:last-child {
    border: none;
  }
  > div {
    padding-left: 12px;
    color: #1F2329;
    font-size: 14px;
  }
`

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px 0;
  margin-top: 24px;
`

const PermissionEdit = () => {
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState<any[]>([]);
  const [userRoleList, setUserRoleList] = useState<any[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(0);
  const [userName, setUserName] = useState('');
  const { userId } = useParams() as any;
  const navigate = useNavigate();

  const toggle = (roleId: number, checked: boolean) => {
    for(let i = 0; i < roleList.length; i++) {
      if (roleId === roleList[i].roleId) {
        roleList[i].checked = checked;
        break;
      }
    }

    setRoleList([...roleList])
  }

  const save = () => {
    const selectedRoleList = roleList.filter((item: any) => item.checked);
    const newRoleList: any[] = [];
    const currentRoleList = [...userRoleList];
    const expireTime = dayjs('2024-1-1').format('YYYY-MM-DD 00:00:00')

    console.log('expireTime: ', expireTime)

    selectedRoleList.forEach((item: any) => {
      const index = currentRoleList.findIndex((r: any) => r.roleId === item.roleId);
      if (index >= 0) {
        currentRoleList.splice(index, 1);
        return;
      }

      newRoleList.push(item);
    })

    Promise.all([
      ...newRoleList.map((item: any) => grantRoleToUser(userId, item.roleId, expireTime)),
      ...currentRoleList.map((item: any) => revokeRoleOfUser(userId, item.roleId))
    ]).then((resList: any[]) => {
      message.success('保存成功')
    })
  }

  const cancel = () => {
    navigate(-1)
  }

  useEffect(() => {
    setLoading(true)
    let tempUserRoleList: any[] = [];
    getUserRoleList(userId).then((res: any) => {
      const { resultCode, data } = res.data;
      console.log('userRoleList 1: ', data)
      setUserRoleList(data);
      tempUserRoleList = data;
      return getRoleList();
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      console.log('userRoleList: ', userRoleList)
      if (resultCode === 0) {
        setRoleList(data.map((item: any) => ({
          ...item,
          checked: tempUserRoleList.find((ur: any) => ur.roleId === item.roleId) ? true : false
        })));
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })

    getUserInfoById(userId).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setUserName(data.realName);
      }
    })
  }, [])

  return (
    <Container>
      <Spin spinning={loading}>
        <Title>权限管理</Title>
        <Label>人员：{userName}</Label>
        <RolesContainer>
          <RoleListTitle>角色</RoleListTitle>
          {
            roleList.map((item: any) => (
              <Role key={`role_${item.roleId}`}>
                <Checkbox
                  value={item.roleId}
                  checked={item.checked}
                  onChange={(e: any) => {
                    toggle(item.roleId, e.target.checked);
                  }}
                />
                <div>{item.roleDisplayName}</div>
              </Role>
            ))
          }
        </RolesContainer>
        <ButtonBar>
          <Space>
            <Button onClick={cancel}>返回</Button>
            <Button type="primary" onClick={save}>确定</Button>
          </Space>
        </ButtonBar>
      </Spin>
    </Container>
  )
}

export default PermissionEdit;