import { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Input as AntInput, message, InputNumber } from 'antd';
import { LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Input from '@/components/Form/Input';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { uploadFile } from '@/services/common';
import { saveActivity, getActivityDetail } from '@/services/activity';
import { getNoticeDetail, saveNotice } from '@/services/notice';

const FormItem = Form.Item;

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`

const ImageContainer = styled.div`
  width: 120px;
  img {
    width: 100%;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  column-gap: 12px;
`

interface Props {
  type: 'add' | 'edit';
}

type ParamType = {
  id: string;
}

const NoticeEdit: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams<ParamType>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cmsPageId, setCmsPageId] = useState('');

  const save = (values: any) => {
    setLoading(true);

    console.log('values: ', values)

    saveNotice({
      ...values,
      ...(id ? { noticeId: id } : {}),
    }).then((res: any) => {
      const { resultCode, resultMsg } = res.data;
      if (resultCode === 0) {
        message.success('保存成功');
        setTimeout(() => {
          navigate('/notice');
        }, 1000);
      } else {
        message.error(resultMsg);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
  }

  useEffect(() => {
    if (props.type === 'edit' && id) {
      getNoticeDetail(id).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          console.log('notice detail: ', data)
          form.setFieldsValue({
            ...data,
          });
          setCmsPageId(data.cmsPageId);
        }
      })
    }
  }, [])

  return (
    <>
      <CloseButtonContainer>
        <Link target="_blank" to={`${window.location.origin}/cms-edit/#/home?pageId=${cmsPageId}`}>编辑网页</Link>
        <Button type="text" onClick={() => navigate(-1) }><CloseOutlined /></Button>
      </CloseButtonContainer>
      <Form form={form} onFinish={save} layout="vertical">
        <Container>
          <Panel>
            <Row gutter={[22, 0]}>
              <Col span={24}>
                <FormItem label="公告标题" name="noticeTitle">
                  <Input />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label="排序优先级" name="sortPriority">
                  <InputNumber />
                </FormItem>
              </Col>
            </Row>
          </Panel>
          <Footer>
            <Button onClick={() => navigate('/notice')}>取消</Button>
            <Button type="primary" htmlType="submit" loading={loading}>确定</Button>
          </Footer>
        </Container>
      </Form>
    </>
  )
}

export default NoticeEdit