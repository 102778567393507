import { useState, useEffect } from "react";
import styled from 'styled-components';
import { Row, Col, Button, Image, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from "react-router-dom";
import Title from '@/components/Form/Title';
import FormItem from "@/components/Form/Item";
import Text from "@/components/Form/Text";
import { getSelectedTextFromList, getSexText } from '@/utils/transform';
import { getEmployeeDetail } from '@/services/company';
import { ValueAndText, Whether } from "@/types/common";

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Panel = styled.div`
  width: 666px;
`

const FormContainer = styled.div`
  padding-left: 16px;
  width: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

type ParamTypes = {
  id: string;
}

const Detail = () => {
  const { id = '' } = useParams<ParamTypes>();
  const navigate = useNavigate();
  const [detail, setDetail] = useState<any>();
  const educationList: Array<ValueAndText> = useSelector((state: any) => state.common.educationList);
  const employeeTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.employeeTypeList);
  const politicsStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.politicsStatusList);
  const corpRoleList: Array<ValueAndText> = useSelector((state: any) => state.common.corpRoleList);

  useEffect(() => {
    getEmployeeDetail(id).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDetail(data);
      }
    })
  }, [])

  return (
    <>
      <ButtonBar>
        <Button type="text" onClick={() => { navigate(-1) }}><CloseOutlined /></Button>
      </ButtonBar>
      <Container>
        <Panel>
        <TitleContainer>
            <Title>基本信息</Title>
            <Link to={`/company/editemployee/${id}`}><Button>编辑</Button></Link>
          </TitleContainer>
          <FormContainer>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem
                  label="身份证姓名"
                  name="realName"
                >
                  <Text>{detail?.realName}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="性别"
                  name="sex"
                >
                  <Text>{getSexText(detail?.sex)}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="民族"
                  name=""
                >
                  <Text>{detail?.nation}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="出生日期"
                  name="birth"
                >
                  <Text>{detail?.birth}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="联系电话"
                  name="mobileNo"
                >
                  <Text>{detail?.mobileNo}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="成员角色"
                  name="corpRole"
                >
                  <Text>{getSelectedTextFromList(corpRoleList, detail?.corpRole)}</Text>
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="证件号码"
                  name="idCardNo"
                >
                  <Text>{detail?.idCardNo}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="头像">
                  <Image width={80} src={detail?.headImgUrl} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="身份证正面">
                  <Image width={80} src={detail?.idCardFrontImgUrl} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="身份证反面">
                  <Image width={80} src={detail?.idCardReverseImgUrl} />
                </FormItem>
              </Col>
            </Row>
          </FormContainer>
        </Panel>
        <Panel>
          <Title>学历信息</Title>
          <FormContainer>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem
                  label="学历"
                  name="education"
                >
                  <Text>{getSelectedTextFromList(educationList, detail?.education)}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="毕业学校"
                  name="graduateSchool"
                >
                  <Text>{detail?.graduateSchool}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="毕业时间"
                  name="graduateDate"
                >
                  <Text>{detail?.graduateDate}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="所学专业"
                  name="schoolMajor"
                >
                  <Text>{detail?.schoolMajor}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="学位证照片">
                  <Image width={80} src={detail?.degreeCertificateImgUrl} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="学位证编号">
                  <Text>{detail?.degreeCertificateNo}</Text>
                </FormItem>
              </Col>
            </Row>
          </FormContainer>
        </Panel>
        <Panel>
          <Title>社会关系</Title>
          <FormContainer>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem
                  label="政治面貌"
                  name="politicsStatus"
                >
                  <Text>{getSelectedTextFromList(politicsStatusList, detail?.politicsStatus)}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="是否退伍军人">
                  <Text>{detail?.exSoldier === Whether.TRUE ? '是' : '否'}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="是否任职社会组织">
                  <Text>{detail?.employedInSocialOrg === Whether.TRUE ? '是' : '否'}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="任职的社会组织名称">
                  <Text>{detail?.employerSocialOrgName}</Text>
                </FormItem>
              </Col>
            </Row>
          </FormContainer>
        </Panel>
        {/* <Panel>
          <Title>工作信息</Title>
          <FormContainer>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem
                  label="公司名称"
                  name=""
                >
                  <Text>{detail?.corpName}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="在职状态"
                  name="employeeType"
                >
                  <Text>{getSelectedTextFromList(employeeTypeList, detail?.employeeType)}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="入职时间"
                  name="entryDate"
                >
                  <Text>{detail?.entryDate}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="工位"
                  name="seatNumber"
                >
                  <Text>{detail?.seatNumber}</Text>
                </FormItem>
              </Col>
            </Row>
          </FormContainer>
        </Panel> */}
      </Container>
    </>
  )
}

export default Detail;