import request from '@/utils/request';
import jsonToFormData from 'json-form-data';
import { convertDict2List } from '@/utils/transform';

export function getWorkspaceList(params: any) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post(`/api/permission/workspaceRecord/list`, formData)
}

export function getWorkspaceCount(params: any) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post('/api/permission/workspaceRecord/count', formData)
}

export function addWorkspace() {
  
}

export function deleteWorkspace() {

}

export function getWorkspaceDetail(id: number | string) {
  return request.get(`/api/permission/workspaceRecord/detail?workspaceId=${id}`)
}

export function updateWorkspace(params: any) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post(`/api/permission/workspaceRecord/upd`, formData)
}

// 取租赁状态
export function getRentStatusList() {
  return request.get('/api/conf/workspace/rentStatus').then((res: any) => convertDict2List(res.data))
}

// 取房间大小
export function getWorkspaceSizeTypeList() {
  return request.get('/api/conf/workspace/workspaceSizeType').then((res: any) => convertDict2List(res.data))
}

// 取空间类型
export function getWorkspaceTypeList() {
  return request.get('/api/conf/workspace/workspaceType').then((res: any) => convertDict2List(res.data))
}

// 取资源类型
export function getResourceTypeList() {
  return request.get('/api/conf/workspace/resourceType').then((res: any) => convertDict2List(res.data))
}

export function getFloorRoomList() {
  return request.get('/api/conf/workspace/floorRoomList')
}

// 取可租赁房间列表
export function getCanRentRoomList() {
  return request.get('/api/conf/workspace/canRentRoomList')
}

export function getSalePurposeList(params: any) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post('/api/permission/workspaceSalePurpose/list', formData)
}

export function getSalePuposeCount(params: any) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post('/api/permission/workspaceSalePurpose/count', formData)
}

export function addSalePurpose(params: any) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post('/api/permission/workspaceSalePurpose/add', formData)
}

export function deleteSalePurpose(params: any) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post('/api/permission/workspaceSalePurpose/del', formData)
}

export function updateSalePurpose(params: any) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post('/api/permission/workspaceSalePurpose/upd', formData)
}