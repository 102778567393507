import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import StatusBar from './StatusBar';
import { Button, Row, Col, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Title from "@/components/Form/Title";
import FormItem from "@/components/Form/Item";
import Text from "@/components/Form/Text";
import { getAdvertiseDetail } from '@/services/advertise';
import { ValueAndText } from '@/types/common';
import { useSelector } from 'react-redux';
import { getSelectedTextFromList } from '@/utils/transform';

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`;

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 12px;
`


const FormContainer = styled.div`
  padding-left: 16px;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;

  > img {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  background-color: #F4F5F9;
  padding: 5px 10px;
  font-size: 14px;
  color: #1f2329;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 4px;
`;

type ParamType = {
  id: string;
}

const AdvertiseDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<ParamType>();
  const [detail, setDetail] = useState<any>({});
  const adTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.adTypeList);
  const adDisplayLocationList: Array<ValueAndText> = useSelector((state: any) => state.common.adDisplayLocationList);
  const adStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.adStatusList);


  useEffect(() => {
    getAdvertiseDetail(id).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDetail(data);
      }
    })
  }, [])

  
  return (
    <>
      <ButtonBar>
        <Button type="text" onClick={() => { navigate(-1) }}><CloseOutlined /></Button>
      </ButtonBar>
      <Container>
        <StatusBar startDate={detail?.displayBeginTime} endDate={detail?.displayEndTime} />
        <Panel>
          <TitleContainer>
            <Title>投放详情</Title>
            <ActionGroup>
              <Link to={`/activity/edit/${id}`}><Button>编辑投放详情</Button></Link>
              <Link to={`${window.location.origin}/cms-edit/#/home?pageId=${detail?.cmsPageId}`}><Button>编辑网页</Button></Link>
            </ActionGroup>
          </TitleContainer>
          <Row gutter={[14, 12]} wrap={false}>
            <Col>
              <ImageContainer>
                <Image src={detail?.imgUrl} />
              </ImageContainer>
            </Col>
            <Col flex={1}>
              <TextContainer>{detail?.title}</TextContainer>
              <TextContainer>{detail?.description}</TextContainer>
            </Col>
          </Row>
          <FormContainer>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem label="投放起止时间">
                  <Text>
                    {`${dayjs(detail?.displayBeginTime).format('YYYY-MM-DD')} 至 ${dayjs(detail?.displayEndTime).format('YYYY-MM-DD')}`}
                  </Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="投放编号">
                  <Text>{detail?.adId}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="投放类型">
                  <Text>{getSelectedTextFromList(adTypeList, detail?.adType)}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="投放区域">
                  <Text>{getSelectedTextFromList(adDisplayLocationList, detail?.displayLocationId)}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="投放创建时间">
                  <Text>{detail?.createTime}</Text>
                </FormItem>
              </Col>
            </Row>
          </FormContainer>
        </Panel>
      </Container>
    </>
  )
}

export default AdvertiseDetail;