import { useState } from "react";
import { message, Space, Spin } from 'antd';
import styled from 'styled-components';
import Panel from '@/screens/EnergyManagement/Panel';
import img_light_on from '@/assets/light_on@2x.png';
import img_light_off from '@/assets/light_off@2x.png';
import { Button, SwitchContainer } from './StyledComponents';
import { turnOnLight, turnOffLight, getDeviceDetail } from '@/services/energy';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 42px;
`

const Logo = styled.img`
  width: 66px;
`

interface Props {
  deviceId: number;
  deviceNo: string;
  isOpen: boolean;
  onToggle: () => void;
}

const LightController: React.FC<Props> = ({
  deviceId,
  deviceNo,
  isOpen,
  onToggle,
}) => {
  const [loading, setLoading] = useState(false);

  const toggle = (v: boolean) => {
    const fn = v ? turnOnLight : turnOffLight;
    setLoading(true);
    fn(deviceNo).then((res: any) => {
      const { resultCode, resultMsg } = res.data;
      if (resultCode === 0) {
        setLoading(false);
        onToggle();
        return;
      }

      throw new Error(resultMsg)
    }).catch((err: any) => {
      message.error(err.message);
      setLoading(false);
    })
  }

  return (
    <Panel title="照明灯">
      <Spin spinning={loading}>
        <LogoContainer>
          <Logo src={isOpen ? img_light_on : img_light_off} />
        </LogoContainer>
        <SwitchContainer>
          <Space size={18}>
              <Button
                actived={isOpen}
                onClick={() => toggle(true)}
              >开启</Button>
              <Button
                actived={!isOpen}
                onClick={() => toggle(false)}
              >关闭</Button>
            </Space>
        </SwitchContainer>
      </Spin>
    </Panel>
  )
}

export default LightController;