import bg from '@/assets/floor_plan/4/4lou.png'
import img_401 from '@/assets/floor_plan/4/common/401.png';
import img_402 from '@/assets/floor_plan/4/common/402.png';
import img_403 from '@/assets/floor_plan/4/common/403.png';
import img_401_selected from '@/assets/floor_plan/4/selected/401.png';
import img_402_selected from '@/assets/floor_plan/4/selected/402.png';
import img_403_selected from '@/assets/floor_plan/4/selected/403.png';
import img_401_expire from '@/assets/floor_plan/4/expire/401.png';
import img_402_expire from '@/assets/floor_plan/4/expire/402.png';
import img_403_expire from '@/assets/floor_plan/4/expire/403.png';
import img_401_mismatch from '@/assets/floor_plan/4/mismatch/401.png';
import img_402_mismatch from '@/assets/floor_plan/4/mismatch/402.png';
import img_403_mismatch from '@/assets/floor_plan/4/mismatch/403.png';

const width = (w: number) => `${100 * (w / 980)}%`;
const left = (l: number) => `${100 * (l - 297) / 980}%`;
const top = (t: number) => `${100 * (t - 310) / 328}%`;

const initialRoomListData = [
  {
    title: '运营办公室403',
    roomNo: '403',
    width: width(160),
    left: left(932),
    top: top(313),
    image: img_403,
    selectedImage: img_403_selected,
    expireImage: img_403_expire,
    mismatchImage: img_403_mismatch
  },
  {
    title: '401',
    roomNo: '401',
    width: width(80),
    left: left(1094),
    top: top(327),
    image: img_402,
    selectedImage: img_402_selected,
    expireImage: img_402_expire,
    mismatchImage: img_402_mismatch
  },
  {
    title: '402',
    roomNo: '402',
    width: width(98),
    left: left(1176),
    top: top(327),
    image: img_401,
    selectedImage: img_401_selected,
    expireImage: img_401_expire,
    mismatchImage: img_401_mismatch
  },
]

export default {
  bg,
  initialRoomList: initialRoomListData
}