import { Tabs } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';

const { TabPane } = Tabs;

const CompanyManagement = () => {
  const navigate = useNavigate();

  const handeTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      navigate('/company/list')
    } else {
      navigate('/company/employee')
    }
  }
  return (
    <div>
      <Tabs defaultActiveKey="1" onChange={handeTabChange}>
        <TabPane tab="公司" key="1"></TabPane>
        <TabPane tab="成员" key="2"></TabPane>
      </Tabs>
      <Outlet />
    </div>
  )
}

export default CompanyManagement;