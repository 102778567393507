import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DisplayMode } from "../../types/common";

type WorkspaceState = {
  sales: {
    pageIndex: number;
    pageSize: number;
  },
  common: {
    pageIndex: number;
    pageSize: number;
    rentStatus: number | undefined;
    floorNo: string;
    workspaceType: number | undefined;
    workspaceSizeType: number | undefined;
    displayMode: DisplayMode;
  }
}

const initialState: WorkspaceState = {
  sales: {
    pageIndex: 1,
    pageSize: 20,
  },
  common: {
    pageIndex: 1,
    pageSize: 20,
    rentStatus: undefined,
    floorNo: '04F',
    workspaceType: undefined,
    workspaceSizeType: undefined,
    displayMode: 'list'
  }
}

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspacePagination(
      state, 
      action: PayloadAction<{
        type: 'common' | 'sales',
        pageIndex: number,
        pageSize: number
      }>
    ) {
      state[action.payload.type].pageIndex = action.payload.pageIndex
      state[action.payload.type].pageSize = action.payload.pageSize
    },
    setRentStatus(state, action: PayloadAction<number | undefined>) {
      state.common.rentStatus = action.payload
    },
    setFloorNo(state, action: PayloadAction<string>) {
      state.common.floorNo = action.payload
    },
    setWorkspaceType(state, action: PayloadAction<number | undefined>) {
      state.common.workspaceType = action.payload
    },
    setWorkspaceSizeType(state, action: PayloadAction<number | undefined>) {
      state.common.workspaceSizeType = action.payload
    },
    setWorkspaceDisplayMode(state, action: PayloadAction<DisplayMode>) {
      state.common.displayMode = action.payload
    }
  }
})

export const {
  setWorkspacePagination,
  setRentStatus,
  setFloorNo,
  setWorkspaceType,
  setWorkspaceSizeType,
  setWorkspaceDisplayMode,
} = workspaceSlice.actions

export default workspaceSlice.reducer