import { Col, Table } from 'antd';
import styled from 'styled-components';

const { Column } = Table;

const Container = styled.div`
  padding: 12px 24px 0 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

const Label = styled.div`
  color: #646A73;
  font-size: 14px;
  width: 160px;
  text-align: left;
`

const Content = styled.div`
  flex: 1;
  color: #1F2329;
  font-size: 14px;
  font-weight: 400;
`

interface Props {
  data: any;
}

const RoomDailyDetail: React.FC<Props> = ({ data }) => {
  return (
    <Container>
      <Row>
        <Label>日期：</Label>
        <Content>{data.generateDate.format('YYYY-MM-DD')}</Content>
      </Row>
      <Row> 
        <Label>公司名称:</Label>
        <Content>{data.corpName}</Content>
      </Row>
      <Row> 
        <Label>房间编号:</Label>
        <Content>{data.roomNo}</Content>
      </Row>
      <Row>
        <Label>减免金额</Label>
        <Content>{data.freeAmount}</Content>
      </Row>
      <Row>
        <Label>应付金额</Label>
        <Content>{data.payableAmount?.toFixed(4)}</Content>
      </Row>
      <Row>
        <Label>实付金额</Label>
        <Content>{data.paidAmount?.toFixed(4)}</Content>
      </Row>
      <Row>
        <Label>总金额</Label>
        <Content>{data.totalAmount?.toFixed(4)}</Content>
      </Row>
    </Container>
  )
}

export default RoomDailyDetail;