import { useState, useEffect } from 'react';
import { Row, Col, Select, Button, Form, DatePicker, Upload, Input as AntInput, Table, Popconfirm, Modal, Space, message, Image } from 'antd';
import { LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Title from '@/components/Form/Title';
// import FormItem from '@/components/Form/Item';
import Input from '@/components/Form/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { ValueAndText } from '@/types/common';
import {
  addCompany,
  addPatent,
  updatePatent,
  deletePatent,
  getCompanyDetail,
  updateCompany,
  getPatentList,
  addContact,
  updateContact,
  deleteContact,
  getContactList,
  addWorkspaceRentContract,
  updateWorkspaceRentContract,
  addWorkspaceRentContractImg,
  updateWorkspaceRentContractImg,
  getWorkspaceRentContractImgList,
} from '@/services/company';
import { uploadFile, getFileUrl } from '@/services/common';
import { getWorkspaceList, getCanRentRoomList, updateWorkspace } from '@/services/workspace';
import CorpStatusAndRelatedFields from './CorpStatusAndRelatedFields';
import PatentEditModal from './PatentEditModal';
import ContactEditModal from './ContactEditModal';
import { getSelectedTextFromList } from '@/utils/transform';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = AntInput;
const { Column } = Table;

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`

const FormContainer = styled.div`
  padding-left: 16px;
  width: 100%;
`



const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 0 12px 0;
  width: 100%;
`

const ImageContainer = styled.div`
  width: 120px;
  img {
    width: 100%;
  }
`

interface Props {
  type: 'add' | 'edit';
}

type ParamType = {
  id: string;
}

const CompanyEdit: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { id = '' } = useParams<ParamType>();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const staffSizeTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.staffSizeTypeList);
  const industryList: Array<ValueAndText> = useSelector((state: any) => state.common.industryList);
  const patentTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.patentTypeList);
  const [imageUrl, setImageUrl] = useState('');
  const [legalPersonIdPositiveImgUrl, setLegalPersonIdPositiveImgUrl] = useState('');
  const [legalPersonIdNegativeImgUrl, setLegalPersonIdNegativeImgUrl] = useState('');
  const [roomList, setRoomList] = useState<any[]>([]);
  const [floorRoomList, setFloorRoomList] = useState<any>([]);
  const [roomModalVisible, setRoomModalVisible] = useState(false);
  const [patentList, setPatentList] = useState<any[]>([]);
  const [selectedPatentIndex, setSelectedPatentIndex] = useState(0);
  const [selectedPatentData, setSelectedPatentData] = useState<any>({});
  const [patentModalVisible, setPatentModalVisible] = useState(false);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [selectedContactIndex, setSelectedContactIndex] = useState(0);
  const [selectedContactData, setSelectedContactData] = useState<any>({});
  const [contactList, setContactList] = useState<any[]>([]);
  const [contractStatus, setContractStatus] = useState(0);
  const [contractId, setContractId] = useState(0);
  const [contractImgList, setContractImgList] = useState<any []>([]);
  const [isAllRequired, setIsAllRequired] = useState(false);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const doUpload = (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;

    return new Promise<string>((resolve, reject) => {
      uploadFile({
        file,
        onProgress
      }).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          resolve(getFileUrl(data))
          onSuccess(file);
        }
        reject(res.data)
      }).catch((err: any) => {
        onError({ event: err });
        reject(err)
      });
    })
  }

  const saveRoom = (values: any, corpId: number | string) => {
    return getWorkspaceList({ roomNo: values.roomNo }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0 && data.length > 0) {
        const { workspaceId } = data[0];
        return updateWorkspace({
          workspaceId,
          rentCorpId: corpId,
          ...values
        })
      }
    })
  }

  const cacheRoomList = (values: any) => {
    const newRoomList = [...roomList];
    const { rentCost, rentExpireDate, roomNo } = values;
    const _room = newRoomList.find((item: any) => item.roomNo === values.roomNo);
    if (_room) {
      _room.rentCost = rentCost;
      _room.rentExpireDate = rentExpireDate?.format('YYYY-MM-DD');
      _room.isRemoved = false;
    }
    else {
      newRoomList.push({
        roomNo,
        rentCost,
        rentExpireDate: rentExpireDate?.format('YYYY-MM-DD'),
        isRemoved: false,
      })
    }

    setRoomList(newRoomList)
    setRoomModalVisible(false)
    formModal.resetFields()
  }

  const removeRoom = (roomNo: string) => {
    const newRoomList = [...roomList];
    const _room = newRoomList.find((item: any) => item.roomNo === roomNo);
    if (_room) {
      _room.rentCorpId = 0;
      _room.rentCost = 0;
      _room.rentExpireDate = '';
      _room.isRemoved = true;
    }
    setRoomList(newRoomList)
  }

  const modifyRoom = (values: any) => {
    formModal.setFieldsValue(values)
    setRoomModalVisible(true)
  }

  const savePatent = (values: any, corpId: number | string) => {
    if (values.id && values.isRemoved) {
      return deletePatent(values.id)
    }
    if (values.id) {
      return updatePatent(values)
    }
    if (!values.isRemoved) {
      return addPatent({
        ...values,
        corpId: corpId
      })
    }
  }

  // TODO: cachePatent这个名字起的不对，要改
  const cachePatent = (values: any) => {
    const newPatentList = [...patentList];
    if (selectedPatentIndex) {
      const _patent = newPatentList.find((item: any) => item.index === selectedPatentIndex);
      _patent.patentType = values.patentType;
      _patent.patentName = values.patentName;
      _patent.patentImageUrl = values.patentImageUrl;
      _patent.patentDescription = values.patentDescription;
    }
    else {
      newPatentList.push({
        ...values,
        index: newPatentList.length + 1,
        isRemoved: false
      })
    }
    setPatentList(newPatentList);
    setPatentModalVisible(false)
  }

  const removePatent = (index: number) => {
    const newPatentList = [...patentList]
    const _patent = newPatentList.find((item: any) => item.index === index);
    if (_patent) {
      _patent.isRemoved = true;
    }
    setPatentList(newPatentList)
  }

  const cacheContact = (values: any) => {
    const newContactList = [...contactList];
    if (selectedContactData.index) {
      const _contact = newContactList.find((item: any) => item.index === selectedContactData.index);
      _contact.contactName = values.contactName;
      _contact.contactMobile = values.contactMobile;
      _contact.contactEmail = values.contactEmail;
    }
    else {
      newContactList.push({
        ...values,
        index: newContactList.length + 1,
        isRemoved: false
      })
    }
    setContactList(newContactList);
    setContactModalVisible(false);
  }

  const removeContact = (index: number) => {
    const newContactList = [...contactList];
    const _contact = newContactList.find((item: any) => item.index === index);
    if (_contact) {
      _contact.isRemoved = true;
      setContactList(newContactList);
    }
  }

  const saveContact = (values: any, corpId: number | string) => {
    if (values.contactId && values.isRemoved) {
      return deleteContact(values.contactId)
    }
    if (values.contactId) {
      return updateContact(values)
    }
    if (!values.isRemoved) {
      return addContact({
        ...values,
        corpId: corpId
      })
    }
  }

  const separateContractFieldsValue = (values: any) => {
    let _values: any = {};
    
    const {
      contractNo,
      communicatePerson, contractAmount, currentFeeStage=0,
      extendProtocolDesc, extendProtocolTitle,
      perPayableAmount, perPayableSchedule,
      rejectReason, roomList,
    } = values;

    _values = Object.assign({}, _values, {
      contractNo,
      communicatePerson, contractAmount, currentFeeStage,
      extendProtocolDesc, extendProtocolTitle,
      rejectReason, roomList,
      status: contractStatus,
    });

    Object.keys(_values).forEach(key => {
      delete values[key];
      if (_values[key] === undefined) {
        delete _values[key]
      }
    })

    if (values.checkinDate) {
      _values.checkinDate = values.checkinDate.format('YYYY-MM-DD');
      delete values.checkinDate;
    }
    if (values.expiriteDate) {
      _values.expiriteDate = values.expiriteDate.format('YYYY-MM-DD');
      delete values.expiriteDate;
    }
    if (values.lastCommunicateTime) {
      _values.lastCommunicateTime = values.lastCommunicateTime.format('YYYY-MM-DD hh:mm:ss');
      delete values.lastCommunicateTime;
    }
    if (_values.roomList) {
      _values.roomList = roomList.join(',')
    }

    return _values;
  }

  const saveContractImg = (data: any, contractId: number) => {
    console.log('contract img data: ', data)
    if (data.id) {
      return updateWorkspaceRentContractImg({
        ...data,
        status: data.isRemoved ? 0 : 1
      })
    }
    if (!data.isRemoved) {
      return addWorkspaceRentContractImg({
        ...data,
        status: 1,
        contractId
      })
    }
    return null;
  }

  const save = (values: any) => {
    values.businessLicenseUrl = imageUrl;
    values.legalPersonIdNegativeImg = legalPersonIdNegativeImgUrl;
    values.legalPersonIdPositiveImg = legalPersonIdPositiveImgUrl;

    const valuesOfWorkspaceRentContract = separateContractFieldsValue(values);

    if (values.establishedDate) {
      values.establishedDate = values.establishedDate.format('YYYY-MM-DD')
    }
   
    setLoading(true)
    if (props.type === 'add') {
      addCompany(values).then((res: any) => {
        setLoading(false);
        // navigate('/company', { tab: 'company' })

        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          Promise.all([
            // ...roomList.map(item => saveRoom(item, data)),
            ...patentList.map(item => savePatent(item, data)),
            ...contactList.map(item => saveContact(item, data)),
            addWorkspaceRentContract({
              ...valuesOfWorkspaceRentContract,
              corpId: data,
            }),
          ]).then((resList) => {
            message.success('保存成功')
            setTimeout(() => {
              navigate('/company', {state: { tab: 'company' }})
            }, 1000)
            setLoading(false)
          }).catch(() => {
            setLoading(false)
          })
          return
        }

        setLoading(false)
      });
    }
    else {
      updateCompany({
        corpId: id,
        ...values
      }).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          if (!contractId) {
            return addWorkspaceRentContract({
              ...valuesOfWorkspaceRentContract,
              corpId: id
            })
          }

          return updateWorkspaceRentContract({
            ...valuesOfWorkspaceRentContract,
            contractId
          })
        }
        throw new Error('保存失败')
      }).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          return Promise.all([
            // ...roomList.map(item => saveRoom(item, id)),
            ...patentList.map(item => savePatent(item, id)),
            ...contactList.map(item => saveContact(item, id)),
            ...contractImgList.map(item => saveContractImg(item, contractId || data)),
          ])
        }

        throw new Error('保存合同信息失败');
      }).then(() => {
        message.success('保存成功')
        setTimeout(() => {
          navigate('/company', { state: { tab: 'company' }})
        }, 1000)
        setLoading(false);
      })
    }
  }

  useEffect(() => {
    if (props.type === 'edit') {
      getCompanyDetail(id).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          const { lastRentContractRecord, ...others } = data
          const corpDetail = {
            ...others,
            ...lastRentContractRecord
          };
          // corpDetail.checkinDate = dayjs(data.checkinDate);
          corpDetail.staffSizeType = data.staffSizeType;
          corpDetail.expansionPlan = data.expansionPlan;
          corpDetail.contractStatus = data.contractStatus;
          corpDetail.establishedDate = dayjs(data.establishedDate);

          if (corpDetail.checkinDate) {
            corpDetail.checkinDate = dayjs(corpDetail.checkinDate);
          }

          if (data.lastRentContractRecord?.checkinDate) {
            corpDetail.checkinDate = dayjs(data.lastRentContractRecord.checkinDate);
          }
          if (data.lastRentContractRecord?.lastCommunicateTime) {
            corpDetail.lastCommunicateTime = dayjs(data.lastRentContractRecord.lastCommunicateTime);
          }
          if (data.lastRentContractRecord?.expiriteDate) {
            corpDetail.expiriteDate = dayjs(data.lastRentContractRecord.expiriteDate);
          }
          if (data.lastRentContractRecord?.perPayableSchedule) {
            corpDetail.perPayableSchedule = dayjs(data.lastRentContractRecord.perPayableSchedule);
          }
          if (data.lastRentContractRecord?.roomList) {
            corpDetail.roomList = data.lastRentContractRecord?.roomList?.split(',') || [];
          }

          form.setFieldsValue(corpDetail);
          setImageUrl(data.businessLicenseUrl);
          setLegalPersonIdPositiveImgUrl(data.legalPersonIdPositiveImg);
          setLegalPersonIdNegativeImgUrl(data.legalPersonIdNegativeImg);
          setContractStatus(data.lastRentContractRecord?.status || 0);
          setContractId(data.lastRentContractRecord?.contractId);

          getWorkspaceRentContractImgList(data.lastRentContractRecord?.contractId).then((res: any) => {
            const { resultCode, data } = res.data;
            if (resultCode === 0) {
              setContractImgList(data.map((item: any, index: number) => {
                return {
                  ...item,
                  index: index + 1,
                  isRemoved: false
                }
              }))
            }
          })

          if (data.workspaceRecordList) {
            setRoomList(data.workspaceRecordList.map((item: any) => {
              return {
                ...item,
                isRemoved: false
              }
            }))
          }
        }
      })

      getPatentList(id).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          setPatentList(data.map((item: any, index: number) => {
            return {
              ...item,
              index: index + 1,
              isRemoved: false,
            }
          }) || []);
        }
      })

      getContactList(id).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          setContactList(data.map((item: any, index: number) => {
            return {
              ...item,
              index: index + 1,
              isRemoved: false
            }
          }))
        }
      })
    }

    getCanRentRoomList().then((res: any) => {
      setFloorRoomList(res.data)
    })
  }, []);

  useEffect(() => {
    setIsAllRequired(contractStatus >= 2)
  }, [contractStatus])

  return (
    <>
      <CloseButtonContainer>
        <Button type="text" onClick={() => navigate(-1) }><CloseOutlined /></Button>
      </CloseButtonContainer>
      <Form form={form} onFinish={save} layout="vertical">
        <Container>
          <Panel>
            <Title>基本信息</Title>
            <FormContainer>
              <Row gutter={[22, 0]}>
                <Col span={12}>
                  <FormItem
                    label="企业名称"
                    name="corpName"
                    rules={[{ required: true, message: '请输入企业名称' }]}
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                  label="营业执照编号"
                  name="businessLicenseNo"
                  rules={[{ required: isAllRequired, message: '请输入营业执照编号' }]}
                >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                  label="注册资本"
                  name="capitalSocial"
                  rules={[{ required: isAllRequired, message: '请输入注册资本' }]}
                >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                  label="成立日期"
                  name="establishedDate"
                  rules={[{ required: isAllRequired, message: '请输入成立日期' }]}
                >
                    <DatePicker />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                  label="规模"
                  name="staffSizeType"
                  rules={[{ required: true, message: '请选择规模' }]}
                >
                    <Select>
                      {
                        staffSizeTypeList?.map(({ text, value }) => (
                          <Option key={`staff_size_type_${value}`} value={value}>{text}</Option>
                        ))
                      }
                    </Select>
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                  label="行业"
                  name="belongIndustry"
                  rules={[{ required: isAllRequired, message: '请选择行业' }]}
                >
                    <Select>
                      {
                        industryList.map(({ value, text }) => (
                          <Option key={`cmp_edit_industry_${value}`} value={value}>{text}</Option>
                        ))
                      }
                    </Select>
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                  label="主营业务"
                  name="businessType"
                  rules={[{ required: isAllRequired, message: '请输入主营业务' }]}
                >
                    <TextArea />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[22, 0]}>
                <Col span={12}>
                  <FormItem label="营业执照" required={isAllRequired}>
                    <Upload
                      name="businessLicenseUrl"
                      listType="picture-card"
                      showUploadList={false}
                      customRequest={(options) => {
                        doUpload(options).then(url => setImageUrl(url))
                      }}
                    >
                      {imageUrl ? <img src={imageUrl} alt="" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                  </FormItem>
                </Col>
                {
                  props.type === 'edit' && (
                    <Col span={12}>
                      <FormItem label="企业二维码">
                        <QRCode value={`https://zlh.yoymoney.com/tools/corpId=${id}`} />
                      </FormItem>
                    </Col>
                  )
                }
              </Row>
              <Row gutter={[22, 0]}>
                <Col span={12}>
                  <FormItem
                  label="社保账号"
                  name="socialSecurityAccount"
                  rules={[{ required: isAllRequired, message: '请输入社保账号' }]}
                >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                  label="社保在缴人数"
                  name="socialSecurityPeopleNumber"
                  rules={[{ required: isAllRequired, message: '请输入社保在缴人数' }]}
                >
                    <Input type="number" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                  label="法人姓名"
                  name="legalPersonName"
                  rules={[{ required: isAllRequired, message: '请输入法人姓名' }]}
                >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                  label="法人证件号"
                  name="legalPersonIdNumber"
                  rules={[{ required: isAllRequired, message: '请输入法人证件号' }]}
                >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="法人证件照正反面"
                    required={isAllRequired}
                  >
                    <Space>
                      <Upload
                        name="legalPersonIdPositiveImg"
                        listType="picture-card"
                        showUploadList={false}
                        customRequest={(options) => {
                          doUpload(options).then(url => setLegalPersonIdPositiveImgUrl(url))
                        }}
                      >
                        {legalPersonIdPositiveImgUrl ? <img src={legalPersonIdPositiveImgUrl} alt="" style={{ width: '100%' }} /> : uploadButton}
                      </Upload>
                      <Upload
                        name="legalPersonIdNegativeImg"
                        listType="picture-card"
                        showUploadList={false}
                        customRequest={(options) => {
                          doUpload(options).then(url => setLegalPersonIdNegativeImgUrl(url))
                        }}
                      >
                        {legalPersonIdNegativeImgUrl ? <img src={legalPersonIdNegativeImgUrl} alt="" style={{ width: '100%' }} /> : uploadButton}
                      </Upload>
                    </Space>
                  </FormItem>
                </Col>
              </Row>
            </FormContainer>
          </Panel>
          <Panel>
            <Title>知识产权</Title>
            <ButtonBar>
              <Button type="primary" onClick={() => {
                setPatentModalVisible(true);
                setSelectedPatentIndex(0);
              }}>
                <PlusOutlined />
                添加专利
              </Button>
            </ButtonBar>
            <Table
              dataSource={patentList.filter((item: any) => item.isRemoved === false)}
              pagination={false}
              rowKey={(record: any) => `corp_c_patent_${record.index}`}
            >
              <Column title="类别" dataIndex="patentType" render={(_: number) => getSelectedTextFromList(patentTypeList, _)} />
              <Column title="名称" dataIndex="patentName" />
              <Column title="详情" dataIndex="patentDescription" />
              <Column
                title="专利图片"
                dataIndex="patentImageUrl"
                render={(_: string) => <ImageContainer><Image src={_} /></ImageContainer>}
              />
              <Column
                title="操作"
                dataIndex="index"
                render={(_: number, record: any) => (
                  <Space>
                    <Button type="link" onClick={() => {
                      setPatentModalVisible(true);
                      setSelectedPatentIndex(_);
                      setSelectedPatentData(patentList.find((item: any) => item.index === _))
                    }}>编辑</Button>
                    <Popconfirm
                      title={`确定要删除专利：${record.patentDescription}吗？`}
                      onConfirm={() => {
                        removePatent(_);
                      }}
                    >
                      <Button type="link" danger>删除</Button>
                    </Popconfirm>
                  </Space>
                )}
              />
            </Table>
          </Panel>
          {/* <Panel>
            <Title>入驻房间</Title>
            <ButtonBar>
              <Button type="primary" onClick={() => setRoomModalVisible(true)}>
                <PlusOutlined />
                添加房间
              </Button>
            </ButtonBar>
            <Table
              dataSource={roomList.filter((item: any) => item.isRemoved === false)}
              pagination={false}
              rowKey={(record: any) => `emp_edit_c_room_${record.roomNo}`}
            >
              <Column title="房间号" dataIndex="roomNo" />
              <Column title="租金" dataIndex="rentCost" />
              <Column title="租用到期日期" dataIndex="rentExpireDate" />
              <Column
                title="操作"
                dataIndex="roomNo"
                render={(roomNo: string, record: any) => (
                  <Space>
                    <Button type="link" onClick={() => {
                      formModal.setFieldsValue({
                        roomNo,
                        rentCost: record.rentCost,
                        rentExpireDate: dayjs(record.rentExpireDate)
                      });
                      setRoomModalVisible(true)
                    }}>编辑</Button>
                    <Popconfirm
                      title={`确定删除房间吗？`}
                      onConfirm={() => {
                        console.log(`roomNo: ${roomNo}`)


                        removeRoom(roomNo)
                      }}
                    >
                      <Button type="link">删除</Button>
                    </Popconfirm>
                  </Space>
                )}
              />
            </Table>
          </Panel> */}
          <Panel>
            <Title>联系人</Title>
            <ButtonBar>
              <Button type="primary" onClick={() => {
                setContactModalVisible(true);
                setSelectedContactIndex(0);
                setSelectedContactData({});
              }}>
                <PlusOutlined />
                添加联系人
              </Button>
            </ButtonBar>
            <Table
              dataSource={contactList.filter((item: any) => item.isRemoved === false)}
              pagination={false}
              rowKey={(record: any) => `corp_c_contact_${record.index}`}
            >
              <Column title="姓名" dataIndex="contactName" />
              <Column title="手机号" dataIndex="contactMobile" />
              <Column title="邮箱" dataIndex="contactEmail" />
              <Column
                title="操作"
                dataIndex="index"
                render={(_: number, record: any) => (
                  <Space>
                    <Button type="link" onClick={() => {
                      setContactModalVisible(true);
                      setSelectedContactIndex(_);
                      setSelectedContactData(contactList.find((item: any) => item.index === _))
                    }}>编辑</Button>
                    <Popconfirm
                      title={`确定要删除联系人：${record.contactName}吗？`}
                      onConfirm={() => {
                        removeContact(_);
                      }}
                    >
                      <Button type="link" danger>删除</Button>
                    </Popconfirm>
                  </Space>
                )}
              />
            </Table>
          </Panel>
          <Panel>
            <Title>企业状态</Title>
            <CorpStatusAndRelatedFields
              form={form}
              contractStatus={contractStatus}
              contractImgList={contractImgList}
              onStatusChange={(status: number) => setContractStatus(status)}
              onContractImgListChange={setContractImgList}
              cacheRoom={cacheRoomList}
              cachedRoomList={roomList}
              removeRoom={removeRoom}
              modifyRoom={modifyRoom}
            />
          </Panel>
          <Panel style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}>
            <Button onClick={() => {
              // navigate(-1);
              navigate('/company', { state: { tab: 'company' }})
            }}>取消</Button>
            <Button
              type="primary"
              style={{ marginLeft: 12 }}
              htmlType="submit"
              loading={loading}
            >确定</Button>
          </Panel>
        </Container>
      </Form>
      <Modal
        title="绑定房间"
        closable
        onCancel={() => setRoomModalVisible(false)}
        visible={roomModalVisible}
        footer={null}
      >
        <Form form={formModal} onFinish={cacheRoomList}>
          <Form.Item label="房间号" name="roomNo" required>
            <Select>
              {
                Object.keys(floorRoomList).sort((prev: string, next: string) => {
                  return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
                }).map((floorName: string, floorIndex: number) => (
                  <OptGroup key={`device_edit_floow_${floorIndex}`} label={floorName}>
                    {
                      floorRoomList[floorName].map((roomName: string, roomIndex: number) => (
                        <Option key={`device_edit_${floorIndex}room_${roomIndex}`} value={roomName}>{roomName}</Option>
                      ))
                    }
                  </OptGroup>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label="租金" name="rentCost" required>
            <Input />
          </Form.Item>
          <Form.Item label="租用到期日" name="rentExpireDate" required>
            <DatePicker />
          </Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">保存</Button>
          </Space>
        </Form>
      </Modal>
      <PatentEditModal
        visible={patentModalVisible}
        onCancel={() => setPatentModalVisible(false)}
        onOk={cachePatent}
        data={selectedPatentData}
      />
      <ContactEditModal
        visible={contactModalVisible}
        onCancel={() => setContactModalVisible(false)}
        onOk={cacheContact}
        data={selectedContactData}
      />
    </>
  )
}

export default CompanyEdit;