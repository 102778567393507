import { useEffect } from 'react';
import {
  RouterProvider,
  Routes,
  Route,
  createBrowserRouter,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './App.css';
// import Login from './screens/Login';
// import QRLogin from './screens/Login/QRLogin';
// import Register from './screens/Register';
// import MainFrame from './screens/MainFrame';
import {
  getContractStatus,
  getCountryList,
  getExpansionPlan,
  getPhoneCountryCode,
  getStaffSizeType,
  getCoprRoleList,
  getEducationList,
  getEmployeeTypeList,
  getJobStatusList,
  getIndustryList,
  getPatentTypeList,
  getCurrentFeeStageList
} from './services/company';
import { getUserStatusList } from './services/user';
import {
  getNetworkStatusList,
  getDeviceStatusList,
  getDeviceTypeList,
  getAssetTypeList
} from './services/device';
import {
  getRentStatusList,
  getWorkspaceSizeTypeList,
  getWorkspaceTypeList,
  getResourceTypeList,
  getFloorRoomList,
} from './services/workspace';
import { getPoliticsStatusList } from './services/user';
import { getBusinessTypeList, getBillStatusList } from './services/bill';
import {
  getAdTypeList,
  getAdDisplayLocationList,
  getAdStatusList,
} from './services/advertise';
import { setCommonList } from './store/features/common';
import router from './routes';

{/* <Route path="/login" element={<Login />} />
        <Route path="/qrlogin" element={<QRLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<MainFrame />} /> */}




function App() {
  const dispatch = useDispatch();
  // TODO: 这部分初始化加载数据的代码，迁移到自定义hooks中。
  useEffect(() => {
    getStaffSizeType().then((data: any) => dispatch(setCommonList({name:'staffSizeTypeList', data})))
    getContractStatus().then((data: any) => dispatch(setCommonList({name:'contractStatusList', data})))
    getExpansionPlan().then((data: any) => dispatch(setCommonList({name:'expansionPlanList', data})))
    getCountryList().then((data: any) => dispatch(setCommonList({name:'countryList', data})))
    getPhoneCountryCode().then((data: any) => dispatch(setCommonList({name:'phoneCountryCodeList', data})))
    getCoprRoleList().then((data: any) => dispatch(setCommonList({name:'corpRoleList', data})))
    getEducationList().then((data: any) => dispatch(setCommonList({name:'educationList', data})))
    getEmployeeTypeList().then((data: any) => dispatch(setCommonList({name:'employeeTypeList', data})))
    getJobStatusList().then((data: any) => dispatch(setCommonList({name:'jobStatusList', data})))
    getUserStatusList().then((data: any) => dispatch(setCommonList({name:'userStatusList', data})))
    getDeviceStatusList().then((data: any) => dispatch(setCommonList({name:'deviceStatusList', data})))
    getDeviceTypeList().then((data: any) => dispatch(setCommonList({name:'deviceTypeList', data})))
    getNetworkStatusList().then((data: any) => dispatch(setCommonList({name:'networkStatusList', data})))
    getRentStatusList().then((data: any) => dispatch(setCommonList({name:'rentStatusList', data})))
    getWorkspaceSizeTypeList().then((data: any) => dispatch(setCommonList({name:'workspaceSizeTypeList', data})))
    getWorkspaceTypeList().then((data: any) => dispatch(setCommonList({name:'workspaceTypeList', data})))
    getResourceTypeList().then((data: any) => dispatch(setCommonList({name:'resourceTypeList', data})))
    getPoliticsStatusList().then((data: any) => dispatch(setCommonList({name:'politicsStatusList', data})))
    getIndustryList().then((data: any) => dispatch(setCommonList({name:'industryList', data})))
    getPatentTypeList().then((data: any) => dispatch(setCommonList({name:'patentTypeList', data})))
    getCurrentFeeStageList().then((data: any) => dispatch(setCommonList({name:'currentFeeStageList', data})))
    getAssetTypeList().then((data: any) => dispatch(setCommonList({name:'assetTypeList', data})))
    getBusinessTypeList().then((data: any) => dispatch(setCommonList({name:'businessTypeList', data})))
    getBillStatusList().then((data: any) => dispatch(setCommonList({name:'billStatusList', data})))
    getAdTypeList().then((data: any) => dispatch(setCommonList({name:'adTypeList', data})))
    getAdDisplayLocationList().then((data: any) => dispatch(setCommonList({name:'adDisplayLocationList', data})))
    getAdStatusList().then((data: any) => dispatch(setCommonList({name:'adStatusList', data})))

    getFloorRoomList().then((res: any) => {
      const list = Object.keys(res.data).sort((prev: string, next: string) => {
        return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
      }) || []
      dispatch(
        setCommonList({
          name: 'floorList',
          data: list.map(item => ({
            value: item,
            text: item
          }))
        })
      )
      dispatch(
        setCommonList({
          name: 'floorRoomList',
          data: list.map(item => ({
            value: item,
            text: item,
            children: res.data[item]?.map((roomName: string) => ({ value: roomName, text: roomName, floorNo: item })) || []
          }))
        })
      )
    });
  }, []);

  

  // return (
  //   <Router basename="/web">
  //     <Routes>
  //       <Route path="/login" element={<Login />} />
  //       <Route path="/qrlogin" element={<QRLogin />} />
  //       <Route path="/register" element={<Register />} />
  //       <Route path="/" element={<MainFrame />} />
  //     </Routes>
  //   </Router>
  // );

  return (
    <RouterProvider router={router} />
  )
}

export default App;
