import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import { ContractStatus, JobStatus } from '@/types/common';
import { getSelectedTextFromList } from '@/utils/transform';

const Container = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: ${(props) => props.backgroundColor || '#FFF'};
  border-radius: 12px;
  width: 80px;
  height: 24px;
  padding: 0 6px 0 2px;
`

const IconContainer = styled.div<{ backgroundColor?: string }>`
  border-radius: 50%;
  background: ${(props) => props.backgroundColor};
  width: 14px;
  height: 14px;
  color: #FFF;
  font-size: 9px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #646A73;
`

const Circle = styled.div`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #FFC609;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #FFF;
  }
`

interface Props {
  status: number | string;
  content: string;
}


export const Chip: React.FC<Props> = ({
  status,
  content
}) => {
  let icon, backgroundColor, iconBgColor;

  switch(status) {
    case 'normal':
      icon = <CheckOutlined />;
      iconBgColor = '#82A7FC';
      backgroundColor = '#F0F4FF';
      break;
    case 'danger':
      icon = <ExclamationOutlined />;
      iconBgColor = '#D85249';
      backgroundColor= '#FFF0F0';
      break;
    case 'warning':
      icon = <Circle />;
      backgroundColor = '#FAF5E1';
      break;
  }

  return (
    <Container backgroundColor={backgroundColor}>
      <IconContainer backgroundColor={iconBgColor}>{icon}</IconContainer>
      <Content>{content}</Content>
    </Container>
  )
}

interface ChipForStatusProps {
  contractStatus?: ContractStatus;
  jobStatus?: JobStatus;
  meetingRoomStatus?: number;
  text?: string;
}

const ChipForStatus: React.FC<ChipForStatusProps> = ({
  contractStatus,
  jobStatus,
  meetingRoomStatus,
}) => {
  let status: string = '';
  let content: string | undefined = '';

  const jobStatusList = useSelector((state: any) => state.common.jobStatusList);
  
  if (contractStatus !== undefined) {
    switch(contractStatus) {
      case ContractStatus.Unpaid:
      case ContractStatus.Paid:
        content = '已签约';
        status = 'normal';
        break;
      case ContractStatus.Retreat:
        content = '已退驻';
        status = 'danger';
        break;
      default:
        content = '未签约';
        status = 'warning';
        break;
    }
  }

  if (jobStatus !== undefined) {
    content = getSelectedTextFromList(jobStatusList, jobStatus);
    switch(jobStatus) {
      case JobStatus.Resign:
        status = 'danger';
        break;
      case JobStatus.OnTheJob:
        status = 'normal';
        break;
      case JobStatus.Applying:
        status = 'warning';
        break;
      case JobStatus.Rejected:
        status = 'warning';
        content = '被拒绝';
        break;
    }    
  }

  if (meetingRoomStatus !== undefined) {
    switch(meetingRoomStatus) {
      case 0:
        status = 'normal';
        content = '已通过';
        break;
      case 10:
        status = 'warning';
        content = '待审批';
        break;
      case 11:
        status = 'danger';
        content = '已拒绝';
        break;
    }
  }

  return (
    <Chip status={status} content={content} />
  )
}

export default ChipForStatus;