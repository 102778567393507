import { useState, useEffect } from 'react';
import { Row, Col, Select, Button, Form, DatePicker, Upload, Input as AntInput, message, Image } from 'antd';
import { LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Input from '@/components/Form/Input';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { uploadFile } from '@/services/common';
import { saveActivity, getActivityDetail } from '@/services/activity';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = AntInput;

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`

const ImageContainer = styled.div`
  width: 120px;
  img {
    width: 100%;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  column-gap: 12px;
`

interface Props {
  type: 'add' | 'edit';
}

type ParamType = {
  id: string;
}

const ActivityEdit: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams<ParamType>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState('');
  const [cmsPageId, setCmsPageId] = useState('');

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const doUpload = (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;
    uploadFile({ file, onProgress })
      .then((res: any) => {
        const { resultCode, fileUrl } = res.data;
        if (resultCode === 0) {
          setImageUrls(fileUrl);
          onSuccess(file);
        }
      })
      .catch((err: any) => onError({ event: err }))
  }

  const save = (values: any) => {
    setLoading(true);

    saveActivity({
      ...values,
      activityBeginTime: values.activityBeginTime.format('YYYY-MM-DD 00:00:00'),
      activityEndTime: values.activityEndTime.format('YYYY-MM-DD 00:00:00'),
      applyBeginTime: values.applyBeginTime.format('YYYY-MM-DD 00:00:00'),
      applyEndTime: values.applyEndTime.format('YYYY-MM-DD 00:00:00'),
      imageUrls,
      ...(id ? { activityId: id } : {}),
    }).then((res: any) => {
      const { resultCode, resultMsg } = res.data;
      if (resultCode === 0) {
        message.success('保存成功');
        setTimeout(() => {
          navigate('/activity');
        }, 1000);
      } else {
        message.error(resultMsg);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
  }

  useEffect(() => {
    if (props.type === 'edit') {
      getActivityDetail(id).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          setImageUrls(data.imageUrls);
          form.setFieldsValue({
            ...data,
            activityBeginTime: dayjs(data.activityBeginTime),
            activityEndTime: dayjs(data.activityEndTime),
            applyBeginTime: dayjs(data.applyBeginTime),
            applyEndTime: dayjs(data.applyEndTime),
          });
          setCmsPageId(data.cmsPageId);
        }
      })
    }
  }, [])

  return (
    <>
      <CloseButtonContainer>
        <Link target="_blank" to={`${window.location.origin}/cms-edit/#/home?pageId=${cmsPageId}`}>编辑网页</Link>
        <Button type="text" onClick={() => navigate(-1) }><CloseOutlined /></Button>
      </CloseButtonContainer>
      <Form form={form} onFinish={save} layout="vertical">
        <Container>
          <Panel>
            <Row gutter={[22, 0]}>
              <Col span={12}>
                <FormItem label="活动标题" name="activityTitle">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="活动类型" name="activityType">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="活动范围" name="activityScope">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="活动地点" name="activityPlace">
                  <Input />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label="活动详情" name="activityDetail">
                  <TextArea />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="活动开始时间" name="activityBeginTime">
                  <DatePicker />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="活动结束时间" name="activityEndTime">
                  <DatePicker />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="报名开始时间" name="applyBeginTime">
                  <DatePicker />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="报名结束时间" name="applyEndTime">
                  <DatePicker />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="联系人" name="contactPerson">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="联系电话" name="contactPhone">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="活动图片">
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    customRequest={doUpload}
                  >
                    { imageUrls ? <Image src={imageUrls} /> : uploadButton}
                  </Upload>
                </FormItem>
              </Col>
            </Row>
          </Panel>
          <Footer>
            <Button onClick={() => navigate('/activity')}>取消</Button>
            <Button type="primary" htmlType="submit" loading={loading}>确定</Button>
          </Footer>
        </Container>
      </Form>
    </>
  )
}

export default ActivityEdit