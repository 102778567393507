import { useState, useEffect } from 'react';
import { Table, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedTextFromList } from '@/utils/transform';
import { getWorkspaceList, getWorkspaceCount } from '@/services/workspace';
import { IsParentRoom } from '@/types/common';
import { setWorkspacePagination } from '@/store/features/workspace';
import { Chip } from '@/components/Chip';

const { Column } = Table;

interface Props {
  rentStatus: string | number;
  workspaceType: string | number;
  workspaceSizeType: string | number;
  floorNo: string;
  isParentRoom: IsParentRoom;
}

const List: React.FC<Props> = ({
  rentStatus,
  workspaceType,
  workspaceSizeType,
  floorNo,
  isParentRoom
}) => {
  const rentStatusList = useSelector((state: any) => state.common.rentStatusList)
  const workspaceTypeList = useSelector((state: any) => state.common.workspaceTypeList)
  const workspaceSizeTypeList = useSelector((state: any) => state.common.workspaceSizeTypeList)
  const workspaceUsageList = useSelector((state: any) => state.common.workspaceUsageList)
  const contractStatusList = useSelector((state: any) => state.common.contractStatusList)
  const pageIndex = useSelector((state: any) => state.workspace.sales.pageIndex);
  const pageSize = useSelector((state: any) => state.workspace.sales.pageSize);
  const [dataCount, setDataCount] = useState(0);
  const [dataSource, setDataSource] = useState<any []>([]);

  const dispatch = useDispatch();

  const getData = (page = 1, pageSize = 20) => {
    getWorkspaceList({
      rentStatus,
      workspaceType: 0,
      workspaceSizeType,
      floorNo,
      isParentRoom,
      currentPage: page,
      pageSize: pageSize,
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data)
      }
    });
    getWorkspaceCount({
      rentStatus,
      workspaceType: 0,
      workspaceSizeType,
      isParentRoom,
      floorNo,
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataCount(data);
      }
    })
  }

  const onPagination = (page: number, pageSize_?: number | undefined) => {
    dispatch(setWorkspacePagination({type: 'sales', pageIndex: page, pageSize: pageSize_ || pageSize}))
    getData(page, pageSize_)
  }

  useEffect(() => {
    dispatch(setWorkspacePagination({type: 'sales', pageIndex: 1, pageSize: pageSize}))
    getData(1, pageSize);
  }, [rentStatus, workspaceType, workspaceSizeType, floorNo, isParentRoom])

  return (
    <>
      <Table
        rowKey={(record: any) => `workspace_list_item_${record.workspaceId}`}
        dataSource={dataSource}
        pagination={{
          total: dataCount,
          pageSize: pageSize,
          current: pageIndex,
          onChange: onPagination
        }}
        scroll={{ x: 1700 }}
      >
        <Column
          title="门牌号"
          dataIndex="corpRoomAlias"
          width={120}
          sorter={{
            compare: (a: any, b: any) => a.corpRoomAlias - b.corpRoomAlias,
            multiple: 1,
          }}
        />
        <Column title="楼层" dataIndex="floorNo" width={60} />
        <Column
          title="功能类型"
          dataIndex="workspaceType"
          width={120}
          render={(_: string) => getSelectedTextFromList(workspaceTypeList, _)}
          sorter={{
            compare: (a: any, b: any) => a.workspaceType - b.workspaceType,
            multiple: 2,
          }}
        />
        <Column
          title="用途"
          dataIndex="workspaceUsage"
          width={100}
          render={(_: string) => getSelectedTextFromList(workspaceUsageList, _)}
          sorter={{
            compare: (a: any, b: any) => a.workspaceUsage.length - b.workspaceUsage.length,
            multiple: 2,
          }}
        />
        <Column title="面积" dataIndex="workspaceArea" width={100} />
        <Column title="租金" dataIndex="rentCost" width={140} />
        <Column title="容纳人数" dataIndex="realGalleryful" width={100} />
        <Column
          title="入驻情况" 
          dataIndex="rentStatus"
          width={100}
          render={(_: string) => getSelectedTextFromList(rentStatusList, _)}
        />
        <Column title="入驻单位" dataIndex="rentCorpName"/>
        <Column title="联系人" dataIndex="salesman" width={100}/>
        <Column
          title="跟进状况"
          dataIndex="nextContractStatus"
          width={120}
          render={(_: number) => getSelectedTextFromList(contractStatusList, _)}
        />
        <Column
          title="合同剩余天数"
          dataIndex="rentDaysLeft"
          width={130}
          sorter={{
            compare: (a: any, b: any) => a.rentDaysLeft - b.rentDaysLeft,
            multiple: 3,
          }}
        />
        <Column
          title="合同状态"
          dataIndex="rentDaysLeft"
          width={120}
          render={(_: number) => {
            if (_ > 60) {
              return <Chip status="normal" content="未到期" />
            }
            if (_ > 0) {
              return <Chip status="warning" content="即将到期" />
            }
            return <Chip status="danger" content="已逾期" />
          }}
        />
        <Column
          title="操作"
          dataIndex="workspaceId"
          fixed="right"
          width={100}
          render={(id: number) => (
            <Space>
              <Link to={`/workspace/detail/${id}`}>详情</Link>
            </Space>
          )}
        />
      </Table>
    </>
  )
}

export default List