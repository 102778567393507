import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Tabs, Popconfirm, message, Space, DatePicker, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Table from '@/components/Table';
import { Link } from 'react-router-dom';
import { getActivityList, deleteActivity, getActivityCount, setTop } from '@/services/activity';
import _ from 'lodash';
import dayjs from 'dayjs';
import { title } from 'process';
import { render } from '@testing-library/react';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const Container = styled.div``;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding-right: 12px;
`

const InputActivityType = styled(Input)`
  width: 190px;
`

const InputSearch = styled(Input.Search)`
  width: 212px;
`

const ActivityInfoContianer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  column-gap: 12px;
`;

const ActivityCover = styled.div`
  width: 80px;
  > img { width: 100% }
`

const ActivityTitle = styled.div`
  color: #1f2329;
  font-size: 12px;
`;

const ActivityDetail = styled.div`
  color: #8f959e;
  font-size: 12px;
`;

const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 6px;
  justify-content: flex-end;
`

const TextButton = styled.div`
  color: #1890ff;
  cursor: pointer;
`


const ActivityList: React.FC = () => {
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [searchDateList, setSearchDateList] = useState<any>([]);
  const [activityType, setActivityType] = useState('');
  const [activityTitle, setActivityTitle] = useState('');
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: '活动',
      width: '35%',
      render: (record: any) => (
        <ActivityInfoContianer>
          <ActivityCover>
            <img src={record.imageUrls} alt="" />
          </ActivityCover>
          <div style={{ flex: 1}}>
            <ActivityTitle>{record.activityTitle}</ActivityTitle>
            <ActivityDetail>
              <div>描述：</div>
              {record.activityDetail}
            </ActivityDetail>
          </div>
        </ActivityInfoContianer>
      )
    },
    {
      title: '活动起止时间',
      width: '15%',
      render: (record: any) => (
        <div style={{ textAlign: 'left' }}>
          <div style={{ whiteSpace: 'nowrap' }}>{record.activityBeginTime}</div>
          <div style={{ whiteSpace: 'nowrap' }}>{record.activityEndTime}</div>
        </div>
      )
    },
    {
      title: '活动范围',
      dataIndex: 'activityScope',
      width: '15%',
    },
    {
      title: '活动类型',
      dataIndex: 'activityType',
      width: '10%'
    },
    {
      title: '任务操作',
      width: '25%',
      render: (record: any) => (
        <ActionGroup>
          <Link to={`/activity/edit/${record.activityId}`}><Button type="primary">编辑</Button></Link>
          <Link to={`/activity/${record.activityId}`}><Button>查看</Button></Link>
          <Popconfirm
            title={`确认要删除活动：${record.activityTitle}吗？`}
            onConfirm={() => onDelete(record.activityId) }
          >
            <Button>删除</Button>
          </Popconfirm>
        </ActionGroup>
      )
    },
    {
      title: '活动编号',
      dataIndex: 'activityId'
    },
    {
      title: '',
      render: (record: any) => (
        <TextButton
          onClick={async () => {
            await setTop(record.activityId);
            getData();
          }}
        >置顶</TextButton>
      )
    },
    {
      title: '',
      dataIndex: 'createTime',
    },
  ];

  const getData = _.debounce(() => {
    console.log(searchDateList)
    getActivityList({
      activityType,
      activityTitle,
      currentPage: current,
      ...(searchDateList?.length === 2 ? {
        beginDay: searchDateList[0].format('YYYY-MM-DD'),
        endDay: searchDateList[1].format('YYYY-MM-DD'),
      } : {})
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data);
      }
    })
    getActivityCount({
      activityType,
      activityTitle,
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setTotal(data);
      }
    })
  }, 500);


  const onPaginationChange = (page: number) => {
    setCurrent(page);
  }

  const onDelete = (id: string | number) => {
    deleteActivity(id).then((res: any) => {
      const { resultCode } = res.data;
      if (resultCode === 0) {
        getData();
        return;
      }
      message.error('删除失败');
    })
  }

  useEffect(() => {
    getData();
  }, [activityTitle, activityType, current, searchDateList]);

  return (
    <Container>
      <SearchContainer>
        <SearchBar>
          <Space>
            <RangePicker onChange={v => setSearchDateList(v)} />
            <InputActivityType addonBefore="活动类型" value={activityType} onChange={e => setActivityType(e.target.value)} />
          </Space>
          <InputSearch placeholder="搜索活动" value={activityTitle} onChange={e => setActivityTitle(e.target.value)} />
        </SearchBar>
        <Link to="/activity/add"><Button type="primary" icon={<PlusOutlined />}>新建活动</Button></Link>
      </SearchContainer>
      <Tabs>
        <TabPane tab="所有活动" key="task_tab_1">
          <Table
            columns={columns}
            dataSource={dataSource}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
        <TabPane tab="未开始" key="task_tab_2">
          <Table
            columns={columns}
            dataSource={dataSource.filter(({activityBeginTime}) => dayjs(activityBeginTime).isAfter(dayjs()))}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
        <TabPane tab="进行中" key="task_tab_3">
          <Table
            columns={columns}
            dataSource={
              dataSource.filter(
                ({activityBeginTime, activityEndTime}) => 
                  dayjs(activityBeginTime).isBefore(dayjs()) &&
                  dayjs(activityEndTime).isAfter(dayjs())
              )
            }
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
        <TabPane tab="已结束" key="task_tab_4">
          <Table
            columns={columns}
            dataSource={dataSource.filter(({activityEndTime}) => dayjs(activityEndTime).isBefore(dayjs()))}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
      </Tabs>

    </Container>
  )
}

export default ActivityList;