import { turnOffDeviceByFloorNo, turnOnDeviceByFloorNo } from "@/services/device";
import { Modal } from "antd";
import { floor } from "lodash";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`

const Button = styled.div`
  border-radius: 15px;
  padding: 0 8px;
  height: 30px;
  font-size: 14px;
  border: 1px solid #C2C5CA;
  text-align: center;
  color: #8F959E;
  line-height: 30px;
  cursor: pointer;
`

interface Props {
  floorNo: string;
}

export default function DeviceController({
  floorNo
}: Props) {
  const openAllDevice = () => {
    Modal.confirm({
      title: '开启所有设备',
      content: '是否确认开启所有设备？',
      onOk: () => {
        turnOnDeviceByFloorNo(floorNo).then(res => {
          if (res.data.resultCode !== 0) {
            Modal.error({
              title: '操作失败',
              content: res.data.resultMsg
            })
          }
        })
      }
    })
  }

  const closeAllDevice = () => {
    Modal.confirm({
      title: '开启所有设备',
      content: '是否确认开启所有设备？',
      onOk: () => {
        turnOffDeviceByFloorNo(floorNo).then(res => {
          if (res.data.resultCode !== 0) {
            Modal.error({
              title: '操作失败',
              content: res.data.resultMsg
            })
          }
        })
      }
    })
  }

  return (
    <Container>
      <Button onClick={openAllDevice}>开启楼层所有设备</Button>
      <Button onClick={closeAllDevice}>关闭楼层所有设备</Button>
    </Container>
  )
}