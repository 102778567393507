import { useEffect, useState } from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { message, Tooltip } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { getLoginCode, getQrLoginResult } from '@/services/user';
import img_cover from '@/assets/illustration@2x.png';
import img_logo from '@/assets/logo3@2x.png';
import img_desktop from '@/assets/desktop@2x.png';
import styles from './style.module.less';
import { useSelector } from 'react-redux';

const Container = styled.div`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
`

const LoginPanel = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 36vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Logo = styled.img`
  height: 30px;
  position: absolute;
  top: 11px;
  left: 22px;
`

const BtnDesktop = styled.div`
  width: 70px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  overflow: hidden;

  img {
    width: 100%;
  }
`

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
`

const SubTitle = styled.div`
  font-size: 14px;
  padding: 12px 0;
`

const QRContainer = styled.div`
  margin: 20px 0 60px 0;
`

const PromptContainer = styled.div``

const QRLogin = () => {
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  let hTimer: NodeJS.Timeout;
  let hTimerUpdateQRCode: NodeJS.Timeout;
  const user = useSelector((state: any) => state.user);

  const checkLoginResult = () => {
    if (user.isLogin) {
      clearTimeout(hTimer)
      return;
    }

    getQrLoginResult(code).then((res: any) => {
      const { resultCode } = res.data;
      if (resultCode === 0) {
        message.success('登录成功');
        setTimeout(() => {
          navigate('/company');
        }, 1500);
        return;
      }

      hTimer = setTimeout(checkLoginResult, 2000)
    })
  }

  useEffect(() => {
    getLoginCode().then((res: any) => {
      const { resultCode, resultMsg, data } = res.data;
      if (resultCode !== 0) {
        message.error(resultMsg);
        return;
      }

      setCode(data)
    })
  }, [])

  useEffect(() => {
    hTimerUpdateQRCode = setInterval(() => {
      getLoginCode().then((res: any) => {
        const { resultCode, resultMsg, data } = res.data;
        if (resultCode !== 0) {
          message.error(resultMsg);
          return;
        }
  
        setCode(data)
      })
    }, 2 * 60 * 1000)
    return () => {
      clearInterval(hTimerUpdateQRCode)
      clearTimeout(hTimer)
    }
  }, [])

  useEffect(() => {
    if (!code) {
      return;
    }
    if (hTimer) {
      clearTimeout(hTimer);
    }
    checkLoginResult();

    return () => {
      clearTimeout(hTimer)
    }
  }, [code])

  return (
    <Container>
      <div className={styles.leftPanel}>
        <Logo src={img_logo} alt="腾讯双创社区（南通）" />
        <LoginPanel>
          <BtnDesktop>
            <Tooltip placement="left" title="账号登录" color="#6483F9">
              <Link to="/login"><img src={img_desktop} alt="" /></Link>
            </Tooltip>
          </BtnDesktop>
          <Title>扫码登录</Title>
          <SubTitle>请使用微信移动端扫描二维码</SubTitle>
          <QRContainer>
            <QRCode value={`https://zlh.yoymoney.com/tools/code=${code}`} size={200}/>
          </QRContainer>
          {/* <PromptContainer>
            还没有账号？
            <Link to="/register">立即注册</Link>
          </PromptContainer> */}
        </LoginPanel>
      </div>
      <div className={styles.rightPanel}>
        <img src={img_cover} alt="" />
      </div>
    </Container>
  )
}

export default QRLogin;