import request from '@/utils/request';
import jsonToFormData from 'json-form-data';

export function getTaskList(data: any) {
  const formData = jsonToFormData({
    ...data,
    pageSize: 20
  }, { initialFormData: new FormData() })
  return request.post('/api/permission/repairTaskRecord/list', formData)
}

export function getTaskCount(data: any) {
  const formData = jsonToFormData(data, { initialFormData: new FormData() })
  return request.post('/api/permission/repairTaskRecord/count', formData)
}

export function getTaskDetail(id: number | string) {
  return request.get(`/api/permission/repairTaskRecord/detail?taskId=${id}`)
}

export function finishTask(id: number | string) {
  const formData = jsonToFormData({
    taskId: id,
    handleStatus: 2 //已完成
  }, { initialFormData: new FormData() })
  return request.post('/api/permission/repairTaskRecord/upd', formData)
}

export function updateRepariDesc(id: number | string, desc: string) {
  const formData = jsonToFormData({
    taskId: id,
    repairDesc: desc,
  }, { initialFormData: new FormData() })
  return request.post('/api/permission/repairTaskRecord/upd', formData)
}

export function addRepairTask(data: any) {
  const formData = jsonToFormData({
    repairTitle: '',
    repairDesc: '',
    ...data,
  }, { initialFormData: new FormData() })
  return request.post('/api/permission/repairTaskRecord/add', formData)
}

export function updateRepairTask(data: any) {
  const formData = jsonToFormData({
    repairTitle: '',
    repairDesc: '',
    ...data,
  }, { initialFormData: new FormData() })
  return request.post('/api/permission/repairTaskRecord/upd', formData)
}

export function deleteTask(id: string) {
  const formData = jsonToFormData({
    taskId: id,
  }, { initialFormData: new FormData() })
  return request.post('/api/logined/repairTaskRecord/cancel', formData)
} 