import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type KnowledgeState = {
  groupList: any[];
}

const initialState: KnowledgeState = {
  groupList: [],
}

const knowledgeSlice = createSlice({
  name: 'knowledge',
  initialState,
  reducers: {
    setKnowledgeGroupList(state, action: PayloadAction<any[]>) {
      state.groupList = action.payload
    }
  }
})

export const { setKnowledgeGroupList } = knowledgeSlice.actions

export default knowledgeSlice.reducer