import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Tabs, Space, DatePicker, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Table from '@/components/Table';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import { getAdvertiseCount, getAdvertiseList } from '@/services/advertise';
import { ValueAndText } from '@/types/common';
import { useSelector } from 'react-redux';
import { getSelectedTextFromList } from '@/utils/transform';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Container = styled.div``;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding-right: 12px;
`

const InputActivityType = styled(Input)`
  width: 190px;
`

const InputSearch = styled(Input.Search)`
  width: 212px;
`

const ActivityInfoContianer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  column-gap: 12px;
`;

const ActivityCover = styled.div`
  width: 80px;
  > img { width: 100% }
`

const ActivityTitle = styled.div`
  color: #1f2329;
  font-size: 12px;
`;

const ActivityDetail = styled.div`
  color: #8f959e;
  font-size: 12px;
`;

const AdvertiseList: React.FC = () => {
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [searchDateList, setSearchDateList] = useState<any>([]);
  const [adTitle, setAdTitle] = useState('');
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const adTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.adTypeList);
  const adDisplayLocationList: Array<ValueAndText> = useSelector((state: any) => state.common.adDisplayLocationList);
  const adStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.adStatusList);
  const [adType, setAdType] = useState();

  const columns = [
    {
      title: '投放内容',
      width: '40%',
      render: (record: any) => (
        <ActivityInfoContianer>
          <ActivityCover>
            <img src={record.imgUrl} alt="" />
          </ActivityCover>
          <div style={{ flex: 1}}>
            <ActivityTitle>{record.title}</ActivityTitle>
            <ActivityDetail>
              <div>描述：</div>
              {record.description}
            </ActivityDetail>
          </div>
        </ActivityInfoContianer>
      )
    },
    {
      title: '投放起止时间',
      width: '15%',
      render: (record: any) => (
        <div style={{ textAlign: 'left' }}>
          <div>{record.displayBeginTime}</div>
          <div>{record.displayEndTime}</div>
        </div>
      )
    },
    {
      title: '投放区域',
      width: '10%',
      render: (record: any) => getSelectedTextFromList(adDisplayLocationList, record.displayLocationId)
    },
    {
      title: '投放类型',
      width: '10%', 
      render: (record: any) => getSelectedTextFromList(adTypeList, record.adType)
    },
    {
      title: '投放状态',
      width: '10%',
      render: (record: any) => getSelectedTextFromList(adStatusList, record.status)
    },
    {
      title: '任务操作',
      width: '15%',
      render: (record: any) => (
        <Space direction="vertical">
          <Link to={`/ad/edit/${record.adId}`}><Button type="primary">编辑</Button></Link>
          <Link to={`/ad/${record.adId}`}><Button>查看</Button></Link>
        </Space>
      )
    },
    {
      title: '',
      dataIndex: 'createTime',
    },
    {
      title: '投放编号',
      dataIndex: 'adId'
    }
  ];

  const getData = _.debounce(() => {
    getAdvertiseList({
      currentPage: current,
      title: adTitle,
      adType,
      ...(searchDateList?.length === 2 ? {
        beginDay: searchDateList[0].format('YYYY-MM-DD'),
        endDay: searchDateList[1].format('YYYY-MM-DD'),
      } : {})
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data);
      }
    })
    getAdvertiseCount({
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setTotal(data);
      }
    })
  }, 500);

  const onPaginationChange = (page: number) => {
    setCurrent(page);
  }

  useEffect(() => {
    getData();
  }, [current, searchDateList, adTitle, adType]);

  return (
    <Container>
      <SearchContainer>
        <SearchBar>
          <Space>
            <RangePicker onChange={v => setSearchDateList(v)} />
            <Select value={adType} onChange={(v: any) => setAdType(v)} style={{ width: 140}} placeholder="投放类型">
              {
                adTypeList.map(({ value, text }) => (
                  <Option key={`ad_type_${value}`} value={value}>{text}</Option>
                ))
              }
            </Select>
          </Space>
          <InputSearch placeholder="搜索投放" value={adTitle} onChange={e => setAdTitle(e.target.value)} />
        </SearchBar>
        <Link to="/ad/add"><Button type="primary" icon={<PlusOutlined />}>新建投放</Button></Link>
      </SearchContainer>
      <Tabs>
        <TabPane tab="所有投放" key="task_tab_1">
          <Table
            columns={columns}
            dataSource={dataSource}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
        <TabPane tab="未开始" key="task_tab_2">
          <Table
            columns={columns}
            dataSource={dataSource.filter(({displayBeginTime}) => dayjs(displayBeginTime).isAfter(dayjs()))}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
        <TabPane tab="投放中" key="task_tab_3">
          <Table
            columns={columns}
            dataSource={
              dataSource.filter(
                ({displayBeginTime, displayEndTime}) => 
                  dayjs(displayBeginTime).isBefore(dayjs()) &&
                  dayjs(displayEndTime).isAfter(dayjs())
              )
            }
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
        <TabPane tab="已结束" key="task_tab_4">
          <Table
            columns={columns}
            dataSource={dataSource.filter(({displayEndTime}) => dayjs(displayEndTime).isBefore(dayjs()))}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
      </Tabs>

    </Container>
  );
}


export default AdvertiseList;