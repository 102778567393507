import { useEffect } from 'react';
import { Layout, Menu, Card, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { PayCircleOutlined } from '@ant-design/icons';
import {
  Routes,
  Route,
  Link,
  useLocation,
  Outlet
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CompanyManagement from '@/screens/CompanyManagement';
import DataManagement from '@/screens/DataManagement';
import Invitation from '@/screens/CompanyManagement/Employee/Invitation';
import Breadcrumb from '@/components/Breadcrumb'
import ApplicantList from '../CompanyManagement/Employee/ApplicantList';
import Workspace from '@/screens/WorkspaceManagement';
import WorkspaceDetail from '@/screens/WorkspaceManagement/Detail';
import WOrkspaceEdit from '@/screens/WorkspaceManagement/Edit';
import DeviceList from '@/screens/DeviceManagement';
import DeviceEdit from '@/screens/DeviceManagement/Edit';
import DeviceDetail from '@/screens/DeviceManagement/Detail';
import Calendar from '@/screens/WorkspaceManagement/Calendar.discard';
import MeetingRoomManagement from '@/screens/MeetingRoomManagement';
import EnergyManagement from '@/screens/EnergyManagement';
import DailyBill from '@/screens/EnergyManagement/Bill/Daily';
import MonthlyBill from '@/screens/EnergyManagement/Bill/Monthly/MonthlyBill';
import MonthlyBillCalendarMode from '@/screens/EnergyManagement/Bill/Monthly/Calendar';
import InvoiceList from '@/screens/EnergyManagement/Invoice'
import ClearRecord from '@/screens/EnergyManagement/ClearRecord';
import PersonnelFiling from '@/screens/OperationManagement/PersonnelFiling';
import OperateLog from '@/screens/OperationManagement/OperateLog';
import CardManagent from '@/screens/CompanyManagement/Card';
import styles from './style.module.less';
import img_logo from '@/assets/group@2x.png';
import img_down from '@/assets/caret-down@2x.png';
import { getUserInfo } from '@/services/user';
import { setUserInfo } from '@/store/features/user';
import Cookies from 'js-cookie';
import NavIcon from '@/components/NavIcon';
import Knowledge from '@/screens/Knowledge';
import TaskManagement from '@/screens/TaskManagement';
import TaskDetail from '../TaskManagement/Detail';
import ActivityList from '../ActivityManagement';
import ActivityEdit from '../ActivityManagement/Edit';
import ActivityDetail from '../ActivityManagement/Detail';
import AdvertiseList from '../AdvertiseManagement';
import AdvertiseEdit from '../AdvertiseManagement/Edit';
import AdvertiseDetail from '../AdvertiseManagement/Detail';
import Dashboard from '../Dashboard';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const MenuStyle: React.CSSProperties = { fontWeight: 'bold' };
const MenuItemStyle: React.CSSProperties = { fontWeight: 'normal' };

const logout = () => {
  Cookies.remove('wpSessionKey')
  setTimeout(() => {
    window.location.href = '/web/qrlogin'
  }, 500);
}

// const menu = (
//   <Menu>
//     <Menu.Item onClick={logout}>退出</Menu.Item>
//   </Menu>
// )

const menuItems: MenuProps['items'] = [
  {
    key: '1',
    label: '退出',
    onClick: logout
  }
]

function MainFrame() {
  const location = useLocation()
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.user);

  useEffect(() => {
    getUserInfo().then((res: any) => {
      const { resultCode, resultMsg, data } = res.data;
      if (resultCode === 0) {
        dispatch(setUserInfo({
          id: data.userId,
          name: data.realName || data.nickName,
          isLogin: true,
          corpName: data.corpName,
          corpId: data.corpId
        }));
      }
    })
  }, [])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className={styles.header}>
        <img className={styles.logo} src={img_logo} alt="腾讯双创社区（南通）" />
        <Dropdown menu={{ items: menuItems}} placement="bottom" arrow>
          <div className={styles.userInfoContainer}>
            <div className={styles.userInfoContainerInner}>
              <div className={styles.corpName}>{userInfo.name}</div>
              {
                userInfo.corpName && (
                  <div className={styles.positionName}>{userInfo.corpName}</div>
                )
              }
            </div>
            <img className={styles.iconDown} src={img_down} alt="" />
          </div>
        </Dropdown>
      </Header>
      <Layout>
        <Sider collapsible theme="light">
          <Menu theme="light" mode="inline" selectedKeys={[location.pathname]}>
            {/* <Menu.Item icon={<NavIcon type="home" />} style={MenuStyle}>首页</Menu.Item> */}
            <SubMenu key="sub2" icon={<NavIcon type="space" />} title="空间管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/salesspace">空间入驻管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/workspace">空间配置管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/meetingroom">会议室管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/device">资产管理</Link></Menu.Item>
            </SubMenu>
            <SubMenu key="sub3" icon={<NavIcon type="source" />} title="能源管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/energy">能源管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/energy/monthlybill">账单</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/energy/invoice">开票申请</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/energy/clearrecord">账务清结算</Link></Menu.Item>
            </SubMenu>
            <SubMenu key="sub4" icon={<NavIcon type="marketing" />} title="运营管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/personnelfiling/permission">人员备案</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/personnelfiling/log">事件跟踪</Link></Menu.Item>
            </SubMenu>
            <SubMenu key="sub5" icon={<NavIcon type="enterprise" />} title="公司及数据管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/company/list">公司管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/card">卡片管理</Link></Menu.Item>
              {/* <Menu.Item style={MenuItemStyle}><Link to="/data">数据管理</Link></Menu.Item> */}
            </SubMenu>
            <SubMenu key="sub6" icon={<PayCircleOutlined />} title="经营管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/dashboard">数据分析</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/task">任务管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/activity">活动管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/ad">投放管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/notice">公告管理</Link></Menu.Item>
            </SubMenu>
            <SubMenu key="sub7" icon={<NavIcon type="book" />} title="知识管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/knowledge/2">公共知识</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/knowledge/1">积累知识</Link></Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout className="main-container">
          <Breadcrumb />
          <Content>
            {/* <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/" element={<Card />}>
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/device/detail/:id" element={<DeviceDetail />} />
                <Route path="/device/add" element={<DeviceEdit type="add" />} />
                <Route path="/device/edit/:id" element={<DeviceEdit type="edit" />} />
                <Route path="/device" element={<DeviceList />} />
                <Route path="/workspace/edit/:id" element={<WOrkspaceEdit />} />
                <Route path="/workspace/detail/:id" element={<WorkspaceDetail />} />
                <Route path="/salesspace" element={<Workspace isParentRoom={1} />} />
                <Route path="/workspace" element={<Workspace isParentRoom={0} />} />
                <Route path="/company/applicants" element={<ApplicantList />} />
                <Route path="/company/invitation" element={<Invitation />} />
                <Route path="/company" element={<CompanyManagement />} />
                <Route path="/data" element={<DataManagement />} />
                <Route path="/meetingroom" element={<MeetingRoomManagement />} />
                <Route path="/energy/dailybill" element={<DailyBill />} />
                <Route path="/energy/monthlybill" element={<MonthlyBill />} />
                <Route path="/energy/monthlybillc" element={<MonthlyBillCalendarMode />} />
                <Route path="/energy/invoice" element={<InvoiceList />} />
                <Route path="/energy/clearrecord" element={<ClearRecord />} />
                <Route path="/energy" element={<EnergyManagement />} />
                <Route path="/personnelfiling/permission" element={<PersonnelFiling />} />
                <Route path="/personnelfiling/log" element={<OperateLog />} />
                <Route path="/card" element={<CardManagent />} />
                <Route path="/knowledge/:bigCategoryId" element={<Knowledge />} />
                <Route path="/task/:id" element={<TaskDetail />} />
                <Route path="/task" element={<TaskManagement />} />
                <Route path="/activity/add" element={<ActivityEdit type="add" />} />
                <Route path="/activity/edit/:id" element={<ActivityEdit type="edit" />} />
                <Route path="/activity/:id" element={<ActivityDetail />} />
                <Route path="/activity" element={<ActivityList />} />
                <Route path="/ad/add" element={<AdvertiseEdit type="add" />} />
                <Route path="/ad/edit/:id" element={<AdvertiseEdit type="edit" />} />
                <Route path="/ad/:id" element={<AdvertiseDetail />} />
                <Route path="/ad" element={<AdvertiseList />} />
              </Route>
            </Routes> */}
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default MainFrame;
