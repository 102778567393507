import styled from 'styled-components';

type Props = {
  left: number | string;
  top : number | string;
  height: number | string;
}

const AppointmentCell = styled.div<Props>`
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  height: ${(props) => props.height}px;
  width: 160px;
  background: #E2EBFF;
`
export default AppointmentCell;