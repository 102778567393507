import { useState, useEffect, ReactNode } from 'react';
import { Button, Col, Input, Popover, Row, Table } from 'antd';
import {
  PlusOutlined,
  SearchOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import Chip from '../../../components/Chip';
import { getCompanyList, getCompanyCount } from '@/services/company';
import { ContractStatus, ValueAndText } from '@/types/common';
import { getSelectedTextFromList } from '@/utils/transform';
import DownloadableQRCode from '@/components/DownloadableQRCode';
import { setSelectedCorpId } from '@/store/features/company';
import useQuery from '@/hooks/useQuery';
import {
  setPagination,
  setCorpName,
  setCorpContractStatus
} from '@/store/features/company';
import Select from '@/components/Select';

const { Column } = Table;

const Company = () => {
  const [dataSource, setDataSource] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const queryParams = useQuery();

  const staffSizeTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.staffSizeTypeList);
  const industryList: Array<ValueAndText> = useSelector((state: any) => state.common.industryList);
  const currentFeeStageList: Array<ValueAndText> = useSelector((state: any) => state.common.currentFeeStageList);
  const corpName = useSelector((state: any) => state.company.corpName);
  const pageIndex = useSelector((state: any) => state.company.pageIndex);
  const pageSize = useSelector((state: any) => state.company.pageSize);
  const contractStatusList = useSelector((state: any) => state.common.contractStatusList);
  const contractStatus = useSelector((state: any) => state.company.contractStatus);

  const getData = debounce((page = 1, pageSize = 100) => {
    setLoading(true);
    getCompanyList({
      currentPage: page,
      pageSize: pageSize,
      corpName,
      contractStatus: queryParams.get('cs') === 'negtiating' ? ContractStatus.Negtiating : contractStatus
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data);
        setDataCount(data.length);
      }
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
    // getCompanyCount({
    //   corpName,
    //   contractStatus: queryParams.get('cs') === 'negtiating' ? ContractStatus.Negtiating : contractStatus
    // }).then((res: any) => {
    //   const { resultCode, data } = res.data;
    //   if (resultCode === 0) {
    //     setDataCount(data);
    //   }
    // })
  }, 1000)

  const onPagination = (page: number, pageSize_?: number | undefined) => {
    dispatch(setPagination({pageIndex: page, pageSize: pageSize_ || pageSize}))
    // getData(page, pageSize_)
  }

  useEffect(() => {
    dispatch(setPagination({pageIndex: 1, pageSize}))
    getData(1, pageSize)
  }, [corpName, contractStatus]);

  useEffect(() => {
    dispatch(setSelectedCorpId(0));
  }, [])

  return (
    <>
      <Row style={{ marginBottom: 22 }}>
        <Col flex={2}>
          {/* 如何创建公司？
          <Button type="link">了解详情</Button> */}
        </Col>
        <Col flex={3}>
          <Row justify="end">
            <Input
              prefix={<SearchOutlined />}
              placeholder="搜索公司"
              style={{ width: 200, marginRight: 12 }}
              value={corpName}
              onChange={e => dispatch(setCorpName(e.target.value))}
            />
            <Select
              label="合同状态"
              value={contractStatus}
              options={contractStatusList}
              onChange={val => dispatch(setCorpContractStatus(val))}
            />
            <Link to="/company/add">
              <Button type="primary" icon={<PlusOutlined />}>新建公司</Button>
            </Link>
          </Row>
        </Col>
      </Row>
      <Table
        dataSource={dataSource} 
        rowKey={(record: any) => `corp_${record.corpId}`}
        loading={loading}
        pagination={{
          // total: dataCount,
          // pageSize: pageSize,
          // current: pageIndex,
          onChange: onPagination,
          showSizeChanger: true
        }}
      >
        <Column
          title="序号"
          key="index"
          render={(text, record, index: number) => (<span>{(pageIndex - 1) * pageSize + index + 1}</span>)}
        />
        <Column
          title="公司名称"
          dataIndex="corpName"
          key="corpName"
          width={250}
          render={(text, record: any) => (
            <Link to={`/company/detail/${record.corpId}`}>{text}</Link>
          )}
        />
        <Column
          title="联系人"
          dataIndex="contactRecordList"
          render={(_: any) => {
            if (_ && _.length > 0) {
              return _[0].contactName
            }
            return ''
          }}
        />
        <Column
          title="行业"
          dataIndex="belongIndustry"
          render={(_: number): ReactNode => getSelectedTextFromList(industryList, _)}
        />
        <Column 
          title="团队规模" 
          dataIndex="staffSizeType"
          render={(_): ReactNode => getSelectedTextFromList(staffSizeTypeList, _)}
        />
        <Column title="社保在缴人数" dataIndex="socialSecurityPeopleNumber" />
        <Column
          title="入驻房间"
          dataIndex="rentRooms"
          sortDirections={['descend', 'ascend']}
          sorter={(a: any, b: any) => a.rentRooms - b.rentRooms}
        />
        <Column title="房间容纳人数"  dataIndex="roomsGalleryful" />
        <Column title="合同状态"
          dataIndex="lastRentContractRecord"
          render={(_: any): ReactNode => {
            return (<Chip contractStatus={_?.status || ContractStatus.Negtiating} />)
          }}
        />
        <Column
          title="缴费状态"
          dataIndex="lastRentContractRecord"
          render={(_: any) => getSelectedTextFromList(currentFeeStageList, _?.currentFeeStage)}
        />
        <Column title="积分" dataIndex="point" />
        <Column title="二维码"
          align="center"
          dataIndex="corpId"
          render={(corpId) => (
            <Popover content={<DownloadableQRCode value={`https://zlh.yoymoney.com/tools/corpId=${corpId}`} />}>
              <QrcodeOutlined style={{ fontSize: 20 }} />
            </Popover>
          )}
        />
      </Table>
    </>
  )
}

export default Company;