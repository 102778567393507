import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Table, Popconfirm, message, Space, DatePicker, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { deleteNotice, getNoticeList } from '@/services/notice';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding-right: 12px;
`

const NoticeList: React.FC = () => {
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: '公告标题',
      dataIndex: 'noticeTitle',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
    },
    {
      title: '操作',
      render: (record: any) => (
        <Space direction="horizontal">
          <Link to={`/notice/edit/${record.noticeId}`}><Button type="link">编辑</Button></Link>
          <Popconfirm
            title={`确认要删除公告：${record.noticeTitle}吗？`}
            onConfirm={() => onDelete(record.noticeId) }
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </Space>
      )
    },
  ];

  const fetchData = () => {
    getNoticeList().then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data.list)
      }
    })
  }

  const onDelete = (noticeId: string) => {
    deleteNotice(noticeId).then((res: any) => {
      const { resultCode } = res.data;
      if (resultCode === 0) {
        fetchData();
        return;
      }
      message.error('删除失败');
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <SearchContainer>
        <SearchBar></SearchBar>
        <Link to="/notice/add"><Button type="primary" icon={<PlusOutlined />}>新建公告</Button></Link>
      </SearchContainer>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record.noticeId}
      />
    </div>
  );
}

export default NoticeList;