import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ValueAndText } from "../../types/common";

type CommonStateType = {
	staffSizeTypeList: Array<ValueAndText>;
	contractStatusList: Array<ValueAndText>;
	expansionPlanList: Array<ValueAndText>;
	countryList: Array<ValueAndText>;
	phoneCountryCodeList: Array<ValueAndText>;
	corpRoleList: Array<ValueAndText>;
	educationList: Array<ValueAndText>;
	employeeTypeList: Array<ValueAndText>;
	jobStatusList: Array<ValueAndText>;
	userStatusList: Array<ValueAndText>;
	deviceStatusList: Array<ValueAndText>;
	deviceTypeList: Array<ValueAndText>;
	networkStatusList: Array<ValueAndText>;
	rentStatusList: Array<ValueAndText>;
	workspaceTypeList: Array<ValueAndText>;
	workspaceSizeTypeList: Array<ValueAndText>;
	resourceTypeList: Array<ValueAndText>;
	politicsStatusList: Array<ValueAndText>;
	industryList: Array<ValueAndText>;
	patentTypeList: Array<ValueAndText>;
	currentFeeStageList: Array<ValueAndText>;
	assetTypeList: Array<ValueAndText>;
	businessTypeList: Array<ValueAndText>;
	billStatusList: Array<ValueAndText>;
	taskStatusList: Array<ValueAndText>;
	adTypeList: Array<ValueAndText>;
	adDisplayLocationList: Array<ValueAndText>;
	adStatusList: Array<ValueAndText>;
	floorList: Array<ValueAndText>;
	floorRoomList: Array<any>;
  workspaceUsageList: Array<ValueAndText>;
}

export type CommonListName = 'staffSizeTypeList' |
  'contractStatusList' |
  'expansionPlanList' |
  'countryList' |
  'phoneCountryCodeList' |
  'corpRoleList' |
  'educationList' |
  'employeeTypeList' |
  'jobStatusList' |
  'userStatusList' |
  'deviceStatusList' |
  'deviceTypeList' |
  'networkStatusList' |
  'rentStatusList' |
  'workspaceTypeList' |
  'workspaceSizeTypeList' |
  'resourceTypeList' |
  'politicsStatusList' |
  'industryList' |
  'patentTypeList' |
  'currentFeeStageList' |
  'assetTypeList' |
  'businessTypeList' |
  'billStatusList' |
  'taskStatusList' |
  'adTypeList' |
  'adDisplayLocationList' |
  'adStatusList' |
	'floorList' |
	'floorRoomList'

type Playload = {
	name: CommonListName
	data: Array<ValueAndText>
}

const initialState: CommonStateType = {
	staffSizeTypeList: [],
	contractStatusList: [],
	expansionPlanList: [],
	countryList: [],
	phoneCountryCodeList: [],
	corpRoleList: [],
	educationList: [],
	employeeTypeList: [],
	jobStatusList: [],
	userStatusList: [],
	deviceStatusList: [],
	deviceTypeList: [],
	networkStatusList: [],
	rentStatusList: [],
	workspaceTypeList: [],
	workspaceSizeTypeList: [],
	resourceTypeList: [],
	politicsStatusList: [],
	industryList: [],
	patentTypeList: [],
	currentFeeStageList: [],
	assetTypeList: [],
	businessTypeList: [],
	billStatusList: [],
	taskStatusList: [{value:0,text:'待处理'},{value:1,text:'处理中'},{value:2,text:'已完成'},{value:3,text:'已取消'}],
	adTypeList: [],
	adDisplayLocationList: [],
	adStatusList: [],
	floorList: [],
	floorRoomList: [],
  workspaceUsageList: [{value:'office',text:'办公室'},{value:'storeroom',text:'储藏室'}],
} 

export const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setCommonList(state, action: PayloadAction<Playload>) {
			state[action.payload.name] = action.payload.data
		}
	},
})

export const { setCommonList } = commonSlice.actions

export default commonSlice.reducer