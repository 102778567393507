import styled from 'styled-components';

const Container = styled.div``

const Column = styled.div``

const Label = styled.div``

const Value = styled.div``

interface Props {
  data: Array<any>;
}

export default function SubData({
  data,
}: Props) {
  return (
    <Container>
      {
        data.map((item) => (
          <Column key={`${item.key}`}>
            <Value>{item.value}</Value>
            <Label>{item.label}</Label>
          </Column>
        ))
      }
    </Container>
  )
}