import request from '@/utils/request';
import jsonToFormData from 'json-form-data';

export function getMeetingRoomList(params: any) {
  const formData = jsonToFormData(params, {
    initialFormData: new FormData()
  });
  return request.post('/api/logined/meetingRoomBooking/list', formData)
}

export function auditPass(bookingId: number | string) {
  return request.post(`/api/permission/meetingRoomBookingRecord/auditPass?bookingId=${bookingId}`)
}

export function auditReject(bookingId: number | string) {
  return request.post(`/api/permission/meetingRoomBookingRecord/auditReject?bookingId=${bookingId}`)
}

export function getOneMeetingRoomBooingList(roomNo: string, queryDate: string) {
  const formData = jsonToFormData({
    roomNo,
    beginUseDate: queryDate,
    endUseDate: queryDate,
    bookingStatus: 0
  }, {
    initialFormData: new FormData()
  });
  return request.post(`/api/logined/meetingRoomBooking/oneRoomBookingList`, formData)
}

export function addBookingRecord(params: any) {
  const formData = jsonToFormData(params, {
    initialFormData: new FormData()
  });
  return request.post('/api/permission/meetingRoomBookingRecord/add', params)
}

export function cancelBookingRecord(bookingId: string | number) {
  const formData = jsonToFormData({ bookingId }, {
    initialFormData: new FormData()
  });
  return request.post('/api/permission/meetingRoomBookingRecord/cancelBooking', formData)
}