import { useEffect, useState } from 'react';
import { Table, Button, Layout, Space, Popover, Input, Popconfirm, message } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  QrcodeOutlined,
  SearchOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from '@/components/Select';
import { getDeviceList, getDeviceCount, getDeviceQRCodeUrl, deleteDevice } from '@/services/device';
import { DeviceStatus } from '@/types/device';
import { getFloorRoomList } from '@/services/workspace';
import { getSelectedTextFromList } from '@/utils/transform';
import { debounce } from 'lodash';
import DownloadableQRCode from '@/components/DownloadableQRCode';

const { Sider } = Layout;
const { Column } = Table;

type ListItemProps = {
  selected?: boolean;
}

const Search = styled.div`
  padding: 12px 24px 12px 0px;
`

const NavBar = styled.div`
`
const ContentContainer = styled.div`
  background-color: #FFFFFF;
  border-left: 1px solid #DEE0E3;
  position: relative;
  flex: 1;
  padding: 0 12px 0 30px;
  margin-left: 26px;
`
const BtnCollapse = styled.div`
  position: absolute;
  left: -26px;
  top: 20px;
  background-color: #FFFFFF;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  border: 1px solid #DEE0E3;
  width: 26px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right-width: 0;
  &:after {
    content: "";
    position: absolute;
    height: 32px;
    width: 2px;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    top: -1px;
    right: -1px;
  }
`
const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
`
const NavItem = styled.div<ListItemProps>`
  cursor: pointer;
  background: ${(props) => props.selected ? '#F0F4FF' : '#F1F2F4'};
  border-radius: 1px;
  margin: 12px 24px 12px 0px;
  color: ${(props) => props.selected ? '#3371FF' : '#1F2329'};
  font-size: 14px;
  padding: 8px 9px;
`

const DeviceList = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPagesize] = useState(20)
  const [searchText, setSearchText] = useState(''); 
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatus>()
  const [floorRoomList, setFloorRoomList] = useState<any[]>([]);
  const [selectedFloor, setSelectedFloor] = useState('');
  const [loading, setLoading] = useState(false);
  const deviceStatusList = useSelector((state: any) => state.common.deviceStatusList);
  const deviceTypeList = useSelector((state: any) => state.common.deviceTypeList);
  const assetTypeList = useSelector((state: any) => state.common.assetTypeList);
  const networkStatusList = useSelector((state: any) => state.common.networkStatusList);

  const getData = (page = 1, pageSize = 20) => {
    setLoading(true);
    getDeviceList({
      deviceStatus,
      belongFloor: selectedFloor,
      currentPage: page,
      pageSize,
      deviceNo: searchText || undefined,
      // belongRoom: searchText || undefined,
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
    getDeviceCount({
      deviceStatus,
      belongFloor: selectedFloor,
      deviceNo: searchText || undefined,
      // belongRoom: searchText || undefined,
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataCount(data);
      }
    })
  }

  const doSearch = debounce((e) => {
    setSearchText(e.target.value)
  }, 500)

  const onPagination = (page: number, pageSize_?: number | undefined) => {
    setPageIndex(page);
    setPagesize(pageSize_ || pageSize);
    getData(page, pageSize_)
  }

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      const list = Object.keys(res.data).sort((prev: string, next: string) => {
        return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
      }) || []
      setFloorRoomList(list)
      setSelectedFloor(list.length > 0 ? list[0] : '')
    })
  }, [])

  useEffect(() => {
    getData();
  }, [deviceStatus, selectedFloor, searchText]);

  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth={0} theme="light">
        <Search>
          <Input
            type="search"
            prefix={<SearchOutlined />}
            onChange={doSearch}
          />
        </Search>
        <NavBar>
          <NavItem
            selected={'' === selectedFloor}
            onClick={() => setSelectedFloor('')}
          >全部楼层</NavItem>
          {
            floorRoomList.map((floorNo: string) => (
              <NavItem
                key={`device_floor_${floorNo}`}
                selected={floorNo === selectedFloor}
                onClick={() => setSelectedFloor(floorNo)}
              >{floorNo}</NavItem>
            ))
          }
        </NavBar>
      </Sider>
      <ContentContainer className="sub-container">
        <BtnCollapse
          onClick={() => setCollapsed(!collapsed)}
        >{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</BtnCollapse>
        
        <div>
          <ButtonBar>
            <Select label="使用状态" options={deviceStatusList} onChange={val => setDeviceStatus(val)} />
            <Space>
              <Link to="/device/add">
                <Button icon={<PlusOutlined />} type="primary">
                  添加设备
                </Button>
              </Link>
              {/* <Button>批量导入/导出</Button> */}
            </Space>
          </ButtonBar>
          <Table
            rowSelection={{
              type: 'checkbox'
            }}
            rowKey={(record: any) => `devicelist_item_${record.deviceId}`}
            dataSource={dataSource}
            pagination={{
              total: dataCount,
              pageSize: pageSize,
              current: pageIndex,
              onChange: onPagination,
              showSizeChanger: true
            }}
            loading={loading}
          >
            <Column
              title="名称"
              dataIndex="deviceName"
              render={(_: string, record: any) => {
                return (<Link to={`/device/detail/${record.deviceId}`}>{_}</Link>)
              }}
            />
            <Column
              title="种类"
              dataIndex="deviceType"
              render={(_: number) => getSelectedTextFromList(deviceTypeList, _)}
            />
            <Column
              title="资产类型"
              dataIndex="assetType"
              render={(_: number) => getSelectedTextFromList(assetTypeList, _)}
            />
            <Column
              title="编号"
              dataIndex="deviceNo"
            />
            <Column title="关联房间" dataIndex="belongRoom" />
            <Column 
              title="使用状态"
              dataIndex="deviceStatus"
              render={(_: number) => getSelectedTextFromList(deviceStatusList, _)}
            />
            <Column
              title="联网状态"
              dataIndex="networkStatus"
              render={(_: number) => getSelectedTextFromList(networkStatusList, _)}
            />
            <Column
              title="二维码"
              dataIndex="deviceNo"
              render={(_: string) => (
                <Popover content={<DownloadableQRCode value={getDeviceQRCodeUrl(_)} />}>
                  <QrcodeOutlined style={{ fontSize: 20 }} />
                </Popover>
              )}
            />
            <Column
              title="操作"
              dataIndex="deviceId"
              render={(_: number, record: any) => (
                <>
                  <Link to={`/device/edit/${_}`}>
                    <Button type="link">编辑</Button>
                  </Link>
                  <Popconfirm
                    title={`确定要删除设备${record.deviceNo}吗？`}
                    onConfirm={() => {
                      deleteDevice(_).then((res: any) => {
                        const { resultCode, resultMsg } = res.data;
                        if (resultCode === 0) {
                          getData(pageIndex, pageSize);
                          return;
                        }
                        message.error(resultMsg);
                      })
                    }}
                  >
                    <Button type="link" danger>删除</Button>
                  </Popconfirm>
                </>
              )}
            />
          </Table>
        </div>
      </ContentContainer>
    </Layout>
  )
}

export default DeviceList;