import { useEffect, useState } from 'react';
import { DatePicker, Popover, Space, Spin, Divider, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Select from '@/components/Select';
import { getBillList, getWorkspaceAcAmmeterMonthStat } from '@/services/bill';
import { getFloorRoomList } from '@/services/workspace';
import CalendarDetail from './CalendarDetail';

const Container = styled.div``

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 20px;
`

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const RoomName = styled.div`
  width: 224px;
  height: 35px;
  line-height: 35px;
  padding-left: 8px;
  border-bottom: 1px solid #E2EBFF;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Cell = styled.div`
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 36px;
  border-left: 1px solid #E2EBFF;
  border-bottom: 1px solid #E2EBFF;
  > div {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  >.used {
    background-color: #E2EBFF;
    cursor: pointer
  }
`

const TimesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const timeList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

const getDayDataByDate = (date: Number, dayDataList: any[] = []) => {
  for(let i = 0; i < dayDataList.length; i++) {
    if (dayjs(dayDataList[i].generateDate).date() === date) {
      return dayDataList[i];
    }
  }
  return null;
}

interface Props {}

const MonthlyBill: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [dayList, setDayList] = useState<Array<number>>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [queryDate, setQueryDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [floorNo, setFloorNo] = useState('');
  const [roomNo, setRoomNo] = useState('');
  const [floorRoomMap, setFloorRoomMap] = useState<any>({});
  const [floorList, setFloorList] = useState<any[]>([]);
  const [roomList, setRoomList] = useState<any[]>([]);
  const [corpName, setCorpName] = useState('');

  useEffect(() => {
    if (queryDate) {
      setLoading(true);
      const newDayList = []
      for(let i=1; i <= queryDate.endOf('month').date(); i++) {
        newDayList.push(i);
      }
      setDayList(newDayList);

      const startDate = dayjs(queryDate.format('YYYY-MM-01'));
      const endDate = startDate.endOf('month');

      getBillList({
        currentPage: 1,
        billDateSignMin: startDate.format('YYYYMMDD'),
        billDateSignMax: endDate.format('YYYYMMDD'),
        corpName,
      }).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          const newDataMap: any = {};
          for (var e of data) {
            var key = e.corpId.toString();
            if (newDataMap.hasOwnProperty(key)  == false) {
              newDataMap[key] = {
                corpId: e.corpId,
                corpName: e.corpName,
                dayDataList: [],
              };
            }
            newDataMap[key].dayDataList.push({
              ...e,
              generateDate: dayjs(e.billDateSign.toString(), 'YYYYMMDD')
            });
          }
          const newDataSource = [];
          for (var k of Object.keys(newDataMap)) {
            newDataSource.push(newDataMap[k]);
          }
          setDataSource(newDataSource);
        }
        setLoading(false);
      });

      // getWorkspaceAcAmmeterMonthStat(queryDate.format('YYYYMM')).then((res: any) => {
      //   const { resultCode, data } = res.data;
      //   console.log('resultCode: ', resultCode, data);
      //   if (resultCode === 0) {
      //     let newDataSource: Array<any> = [];
      //     console.log('data: ', data.length);
      //     data.forEach((d: any) => {
      //       const { subList, ...roomData } = d;
      //       // if (roomData.dayDataList.length > 0) {
      //       //   newDataSource.push(roomData);
      //       //   return;
      //       // }

      //       newDataSource = newDataSource.concat(subList);
      //       console.log('newDataSource: ', newDataSource.length);
      //     });

      //     setDataSource(newDataSource);
      //   }
      //   setLoading(false);
      // }).catch(() => {
      //   setLoading(false);
      // })
    }
  }, [queryDate])

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      const list = Object.keys(res.data).sort((prev: string, next: string) => {
        return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
      }) || []
      setFloorList(list.map(item => ({
        value: item,
        text: item
      })));
      setFloorRoomMap(res.data);
    })
  }, [])

  useEffect(() => {
    if (floorNo) {
      setRoomList(floorRoomMap[floorNo].filter((item: string) => !item.includes('-')).map((item: string) => ({
        value: item,
        text: item
      })))
    }
  }, [floorNo])

  return (
    <Container>
      <SearchBar>
        <Space>
          <DatePicker picker="month" value={queryDate} onChange={val => setQueryDate(val)} />
          {/* <Select label="所属楼层" value={floorNo} options={floorList} onChange={val => setFloorNo(val)} />
          <Select label="所属房间" value={roomNo} options={roomList} onChange={val => setRoomNo(val)} /> */}
          <Input placeholder="公司名称" onChange={e => setCorpName(e.target.value)} />
        </Space>
        <Space>
          <Link to="/energy/monthlybill">表格视图</Link>
          <Divider type="vertical" />
          <Link to="/energy/dailybill">日账单<RightOutlined /></Link>
        </Space>
      </SearchBar>
      <Spin spinning={loading}>
        <Header>
          <RoomName>公司名称</RoomName>
          <TimesContainer>
            {
              dayList.map(d => <Cell key={`day_${d}`}>{d}</Cell>)
            }
          </TimesContainer>
        </Header>
        {
          dataSource.filter(e => corpName ? e.corpName.includes(corpName) : true).map((item: any, index: number) => {
            return (
              <TableRow key={`table_row_time_${index}`}>
                <RoomName>{`${item.corpName}`}</RoomName>
                <TimesContainer>
                  {
                    dayList.map(t => {
                      const dayData = getDayDataByDate(t, item.dayDataList);
                      return (
                        <Cell key={`cell_${index}_${t}`}>
                          {
                            dayData ? (
                              <Popover
                                placement="right"
                                trigger="click"
                                content={<CalendarDetail data={dayData} />}
                              >
                                <div className="used"></div>
                              </Popover>
                            ) : null
                          }
                        </Cell>
                      )
                    })
                  }
                </TimesContainer>
              </TableRow>
            )
          })
        }
      </Spin>
      
    </Container>
  )
}

export default MonthlyBill;