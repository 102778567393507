import bg from '@/assets/energy/floors/6/6.png';
import img_600_1_common from '@/assets/energy/floors/6/common/600-1.png';
import img_600_2_common from '@/assets/energy/floors/6/common/600-2.png';
import img_600_3_common from '@/assets/energy/floors/6/common/600-3.png';
import img_600_4_common from '@/assets/energy/floors/6/common/600-4.png';
import img_600_5_common from '@/assets/energy/floors/6/common/600-5.png';
import img_600_6_common from '@/assets/energy/floors/6/common/600-6.png';
import img_600_7_common from '@/assets/energy/floors/6/common/600-7.png';
import img_600_8_common from '@/assets/energy/floors/6/common/600-8.png';
import img_600_9_common from '@/assets/energy/floors/6/common/600-9.png';
import img_600_10_common from '@/assets/energy/floors/6/common/600-10.png';
import img_601_1_common from '@/assets/energy/floors/6/common/601-1.png';
import img_601_2_common from '@/assets/energy/floors/6/common/601-2.png';
import img_601_3_common from '@/assets/energy/floors/6/common/601-3.png';
import img_602_1_common from '@/assets/energy/floors/6/common/602-1.png';
import img_602_2_common from '@/assets/energy/floors/6/common/602-2.png';
import img_603_1_common from '@/assets/energy/floors/6/common/603-1.png';
import img_603_2_common from '@/assets/energy/floors/6/common/603-2.png';
import img_603_3_common from '@/assets/energy/floors/6/common/603-3.png';
import img_605_1_common from '@/assets/energy/floors/6/common/605-1.png';
import img_605_2_common from '@/assets/energy/floors/6/common/605-2.png';
import img_605_3_common from '@/assets/energy/floors/6/common/605-3.png';
import img_606_common from '@/assets/energy/floors/6/common/606.png';
import img_607_1_common from '@/assets/energy/floors/6/common/607-1.png';
import img_607_2_common from '@/assets/energy/floors/6/common/607-2.png';
import img_607_3_common from '@/assets/energy/floors/6/common/607-3.png';
import img_608_1_common from '@/assets/energy/floors/6/common/608-1.png';
import img_608_2_common from '@/assets/energy/floors/6/common/608-2.png';
import img_608_3_common from '@/assets/energy/floors/6/common/608-3.png';
import img_609_1_common from '@/assets/energy/floors/6/common/609-1.png';
import img_609_2_common from '@/assets/energy/floors/6/common/609-2.png';
import img_609_3_common from '@/assets/energy/floors/6/common/609-3.png';
import img_610_common from '@/assets/energy/floors/6/common/610.png';
import img_611_common from '@/assets/energy/floors/6/common/611.png';
import img_612_1_common from '@/assets/energy/floors/6/common/612-1.png';
import img_612_2_common from '@/assets/energy/floors/6/common/612-2.png';
import img_612_3_common from '@/assets/energy/floors/6/common/612-3.png';
import img_613_1_common from '@/assets/energy/floors/6/common/613-1.png';
import img_613_2_common from '@/assets/energy/floors/6/common/613-2.png';
import img_613_3_common from '@/assets/energy/floors/6/common/613-3.png';
import img_615_1_common from '@/assets/energy/floors/6/common/615-1.png';
import img_615_2_common from '@/assets/energy/floors/6/common/615-2.png';
import img_615_3_common from '@/assets/energy/floors/6/common/615-3.png';
import img_616_1_common from '@/assets/energy/floors/6/common/616-1.png';
import img_616_3_common from '@/assets/energy/floors/6/common/616-3.png';
import img_617_1_common from '@/assets/energy/floors/6/common/617-1.png';
import img_617_3_common from '@/assets/energy/floors/6/common/617-3.png';
import img_618_1_common from '@/assets/energy/floors/6/common/618-1.png';
import img_618_2_common from '@/assets/energy/floors/6/common/618-2.png';
import img_618_3_common from '@/assets/energy/floors/6/common/618-3.png';
import img_619_1_common from '@/assets/energy/floors/6/common/619-1.png';
import img_619_2_common from '@/assets/energy/floors/6/common/619-2.png';
import img_619_3_common from '@/assets/energy/floors/6/common/619-3.png';
import img_620_1_common from '@/assets/energy/floors/6/common/620-1.png';
import img_620_2_common from '@/assets/energy/floors/6/common/620-2.png';
import img_620_3_common from '@/assets/energy/floors/6/common/620-3.png';
import img_621_1_common from '@/assets/energy/floors/6/common/621-1.png';
import img_621_2_common from '@/assets/energy/floors/6/common/621-2.png';
import img_621_3_common from '@/assets/energy/floors/6/common/621-3.png';
import img_622_1_common from '@/assets/energy/floors/6/common/622-1.png';
import img_622_2_common from '@/assets/energy/floors/6/common/622-2.png';
import img_622_3_common from '@/assets/energy/floors/6/common/622-3.png';
import img_623_1_common from '@/assets/energy/floors/6/common/623-1.png';
import img_623_2_common from '@/assets/energy/floors/6/common/623-2.png';
import img_623_3_common from '@/assets/energy/floors/6/common/623-3.png';
import img_625_1_common from '@/assets/energy/floors/6/common/625-1.png';
import img_625_2_common from '@/assets/energy/floors/6/common/625-2.png';
import img_630_common from '@/assets/energy/floors/6/common/630.png';
import img_631_common from '@/assets/energy/floors/6/common/631.png';
import img_632_common from '@/assets/energy/floors/6/common/632.png';
import img_633_common from '@/assets/energy/floors/6/common/633.png';
import img_634_common from '@/assets/energy/floors/6/common/634.png';
import img_635_common from '@/assets/energy/floors/6/common/635.png';
import img_636_common from '@/assets/energy/floors/6/common/636.png';
import img_637_common from '@/assets/energy/floors/6/common/637.png';
import img_638_common from '@/assets/energy/floors/6/common/638.png';
import img_639_common from '@/assets/energy/floors/6/common/639.png';
import img_640_common from '@/assets/energy/floors/6/common/640.png';
import img_641_common from '@/assets/energy/floors/6/common/641.png';
import img_642_common from '@/assets/energy/floors/6/common/642.png';
import img_643_common from '@/assets/energy/floors/6/common/643.png';
import img_645_common from '@/assets/energy/floors/6/common/645.png';
import img_646_common from '@/assets/energy/floors/6/common/646.png';
import img_647_common from '@/assets/energy/floors/6/common/647.png';
import img_648_common from '@/assets/energy/floors/6/common/648.png';
import img_649_common from '@/assets/energy/floors/6/common/649.png';

import img_600_1_selected from '@/assets/energy/floors/6/selected/600-1.png';
import img_600_2_selected from '@/assets/energy/floors/6/selected/600-2.png';
import img_600_3_selected from '@/assets/energy/floors/6/selected/600-3.png';
import img_600_4_selected from '@/assets/energy/floors/6/selected/600-4.png';
import img_600_5_selected from '@/assets/energy/floors/6/selected/600-5.png';
import img_600_6_selected from '@/assets/energy/floors/6/selected/600-6.png';
import img_600_7_selected from '@/assets/energy/floors/6/selected/600-7.png';
import img_600_8_selected from '@/assets/energy/floors/6/selected/600-8.png';
import img_600_9_selected from '@/assets/energy/floors/6/selected/600-9.png';
import img_600_10_selected from '@/assets/energy/floors/6/selected/600-10.png';
import img_601_1_selected from '@/assets/energy/floors/6/selected/601-1.png';
import img_601_2_selected from '@/assets/energy/floors/6/selected/601-2.png';
import img_601_3_selected from '@/assets/energy/floors/6/selected/601-3.png';
import img_602_1_selected from '@/assets/energy/floors/6/selected/602-1.png';
import img_602_3_selected from '@/assets/energy/floors/6/selected/602-3.png';
import img_603_1_selected from '@/assets/energy/floors/6/selected/603-1.png';
import img_603_2_selected from '@/assets/energy/floors/6/selected/603-2.png';
import img_603_3_selected from '@/assets/energy/floors/6/selected/603-3.png';
import img_605_1_selected from '@/assets/energy/floors/6/selected/605-1.png';
import img_605_2_selected from '@/assets/energy/floors/6/selected/605-2.png';
import img_605_3_selected from '@/assets/energy/floors/6/selected/605-3.png';
import img_606_selected from '@/assets/energy/floors/6/selected/606.png';
import img_607_1_selected from '@/assets/energy/floors/6/selected/607-1.png';
import img_607_2_selected from '@/assets/energy/floors/6/selected/607-2.png';
import img_607_3_selected from '@/assets/energy/floors/6/selected/607-3.png';
import img_608_1_selected from '@/assets/energy/floors/6/selected/608-1.png';
import img_608_2_selected from '@/assets/energy/floors/6/selected/608-2.png';
import img_608_3_selected from '@/assets/energy/floors/6/selected/608-3.png';
import img_609_1_selected from '@/assets/energy/floors/6/selected/609-1.png';
import img_609_2_selected from '@/assets/energy/floors/6/selected/609-2.png';
import img_609_3_selected from '@/assets/energy/floors/6/selected/609-3.png';
import img_610_selected from '@/assets/energy/floors/6/selected/610.png';
import img_611_selected from '@/assets/energy/floors/6/selected/611.png';
import img_612_1_selected from '@/assets/energy/floors/6/selected/612-1.png';
import img_612_2_selected from '@/assets/energy/floors/6/selected/612-2.png';
import img_612_3_selected from '@/assets/energy/floors/6/selected/612-3.png';
import img_613_1_selected from '@/assets/energy/floors/6/selected/613-1.png';
import img_613_2_selected from '@/assets/energy/floors/6/selected/613-2.png';
import img_613_3_selected from '@/assets/energy/floors/6/selected/613-3.png';
import img_615_1_selected from '@/assets/energy/floors/6/selected/615-1.png';
import img_615_2_selected from '@/assets/energy/floors/6/selected/615-2.png';
import img_615_3_selected from '@/assets/energy/floors/6/selected/615-3.png';
import img_616_1_selected from '@/assets/energy/floors/6/selected/616-1.png';
import img_616_3_selected from '@/assets/energy/floors/6/selected/616-3.png';
import img_617_1_selected from '@/assets/energy/floors/6/selected/617-1.png';
import img_617_3_selected from '@/assets/energy/floors/6/selected/617-3.png';
import img_618_1_selected from '@/assets/energy/floors/6/selected/618-1.png';
import img_618_2_selected from '@/assets/energy/floors/6/selected/618-2.png';
import img_618_3_selected from '@/assets/energy/floors/6/selected/618-3.png';
import img_619_1_selected from '@/assets/energy/floors/6/selected/619-1.png';
import img_619_2_selected from '@/assets/energy/floors/6/selected/619-2.png';
import img_619_3_selected from '@/assets/energy/floors/6/selected/619-3.png';
import img_620_1_selected from '@/assets/energy/floors/6/selected/620-1.png';
import img_620_2_selected from '@/assets/energy/floors/6/selected/620-2.png';
import img_620_3_selected from '@/assets/energy/floors/6/selected/620-3.png';
import img_621_1_selected from '@/assets/energy/floors/6/selected/621-1.png';
import img_621_2_selected from '@/assets/energy/floors/6/selected/621-2.png';
import img_621_3_selected from '@/assets/energy/floors/6/selected/621-3.png';
import img_622_1_selected from '@/assets/energy/floors/6/selected/622-1.png';
import img_622_2_selected from '@/assets/energy/floors/6/selected/622-2.png';
import img_622_3_selected from '@/assets/energy/floors/6/selected/622-3.png';
import img_623_1_selected from '@/assets/energy/floors/6/selected/623-1.png';
import img_623_2_selected from '@/assets/energy/floors/6/selected/623-2.png';
import img_623_3_selected from '@/assets/energy/floors/6/selected/623-3.png';
import img_625_1_selected from '@/assets/energy/floors/6/selected/625-1.png';
import img_625_2_selected from '@/assets/energy/floors/6/selected/625-2.png';
import img_630_selected from '@/assets/energy/floors/6/selected/630.png';
import img_631_selected from '@/assets/energy/floors/6/selected/631.png';
import img_632_selected from '@/assets/energy/floors/6/selected/632.png';
import img_633_selected from '@/assets/energy/floors/6/selected/633.png';
import img_634_selected from '@/assets/energy/floors/6/selected/634.png';
import img_635_selected from '@/assets/energy/floors/6/selected/635.png';
import img_636_selected from '@/assets/energy/floors/6/selected/636.png';
import img_637_selected from '@/assets/energy/floors/6/selected/637.png';
import img_638_selected from '@/assets/energy/floors/6/selected/638.png';
import img_639_selected from '@/assets/energy/floors/6/selected/639.png';
import img_640_selected from '@/assets/energy/floors/6/selected/640.png';
import img_641_selected from '@/assets/energy/floors/6/selected/641.png';
import img_642_selected from '@/assets/energy/floors/6/selected/642.png';
import img_643_selected from '@/assets/energy/floors/6/selected/643.png';
import img_645_selected from '@/assets/energy/floors/6/selected/645.png';
import img_646_selected from '@/assets/energy/floors/6/selected/646.png';
import img_647_selected from '@/assets/energy/floors/6/selected/647.png';
import img_648_selected from '@/assets/energy/floors/6/selected/648.png';
import img_649_selected from '@/assets/energy/floors/6/selected/649.png';



const width = (w: number) => `${100 * (w / 980)}%`;
const left = (l: number) => `${100 * (l - 297) / 980}%`;
const top = (t: number) => `${100 * (t - 310) / 328}%`;
const fontSize = (w: number, f: number) => f > 12 ? '12px' : '9px'

// {
//   title: '6xx-',
//   roomNo: '6xx-',
//   width: width(w),
//   left: left(),
//   top: top(),
//   fontSize: fontSize(w, 12),
//   color: '#808080',
//   image: img_6xx__common,
//   selectedImage: img_6xx__selected,
// },

const initialRoomList = [
  {
    title: '601-1',
    titleTop: `${100 * (365 - 315) / 110}%`,
    roomNo: '601-1',
    width: width(110),
    left: left(300),
    top: top(315),
    fontSize: fontSize(110, 16),
    image: img_601_1_common,
    selectedImage: img_601_1_selected,
  },
  {
    title: '601-2',
    roomNo: '601-2',
    width: width(32),
    left: left(338),
    top: top(315),
    fontSize: '12px',
    image: img_601_2_common,
  },
  {
    title: '601-3',
    roomNo: '601-3',
    width: width(38),
    left: left(372),
    top: top(315),
    fontSize: fontSize(38, 12),
    image: img_601_3_common,
  },
  {
    title: '603-1',
    titleTop: `${100 * (365 - 315) / 88}%`,
    roomNo: '603-1',
    width: width(88),
    left: left(412),
    top: top(315),
    fontSize: fontSize(88, 16),
    image: img_603_1_common,
    selectedImage: img_603_1_selected,
  },
  {
    title: '603-2',
    roomNo: '603-2',
    width: width(32),
    left: left(452),
    top: top(315),
    fontSize: fontSize(32, 12),
    image: img_603_2_common,
  },
  {
    title: '603-3',
    roomNo: '603-3',
    width: width(38),
    left: left(412),
    top: top(315),
    fontSize: fontSize(38, 12),
    image: img_603_3_common,
  },
  {
    title: '605-1',
    titleTop: `${100 * (365 - 315) / 98}%`,
    roomNo: '605-1',
    width: width(98),
    left: left(502),
    top: top(315),
    fontSize: fontSize(98, 16),
    image: img_605_1_common,
    selectedImage: img_605_1_selected,
  },
  {
    title: '605-2',
    roomNo: '605-2',
    width: width(32),
    left: left(528),
    top: top(315),
    fontSize: fontSize(32, 12),
    image: img_605_2_common,
  },
  {
    title: '605-3',
    roomNo: '605-3',
    width: width(38),
    left: left(562),
    top: top(315),
    fontSize: fontSize(38, 12),
    image: img_605_3_common,
  },
  {
    title: '607-1',
    titleTop: `${100 * (365 - 315) / 94}%`,
    roomNo: '607-1',
    width: width(94),
    left: left(602),
    top: top(315),
    fontSize: fontSize(94, 16),
    image: img_607_1_common,
    selectedImage: img_607_1_selected,
  },
  {
    title: '607-2',
    roomNo: '607-2',
    width: width(32),
    left: left(642),
    top: top(315),
    fontSize: fontSize(32, 12),
    image: img_607_2_common,
  },
  {
    title: '607-3',
    roomNo: '607-3',
    width: width(38),
    left: left(602),
    top: top(315),
    fontSize: fontSize(38, 12),
    image: img_607_3_common,
  },
  {
    title: '新风机房',
    roomNo: '634',
    width: width(38),
    left: left(698),
    top: top(315),
    fontSize: fontSize(38, 12),
    writingMode: 'vertical-lr',
    image: img_634_common,
    selectedImage: img_634_selected,
  },
  {
    title: '606',
    roomNo: '606',
    width: width(52),
    left: left(738),
    top: top(315),
    fontSize: fontSize(52, 16),
    image: img_606_common,
    selectedImage: img_606_selected,
  },
  {
    title: '608-1',
    titleTop: `${100 * (365 - 315) / 94}%`,
    roomNo: '608-1',
    width: width(94),
    left: left(792),
    top: top(315),
    fontSize: fontSize(94, 16),
    image: img_608_1_common,
    selectedImage: img_608_1_selected,
  },
  {
    title: '608-2',
    roomNo: '608-2',
    width: width(32),
    left: left(814),
    top: top(315),
    fontSize: fontSize(32, 12),
    image: img_608_2_common,
  },
  {
    title: '608-3',
    roomNo: '608-3',
    width: width(38),
    left: left(848),
    top: top(315),
    fontSize: fontSize(38, 12),
    image: img_608_3_common,
  },
  {
    title: '609-1',
    titleTop: `${100 * (365 - 315) / 94}%`,
    roomNo: '609-1',
    width: width(94),
    left: left(888),
    top: top(315),
    fontSize: fontSize(94, 16),
    image: img_609_1_common,
    selectedImage: img_609_1_selected,
  },
  {
    title: '609-2',
    roomNo: '609-2',
    width: width(32),
    left: left(928),
    top: top(315),
    fontSize: fontSize(32, 12),
    image: img_609_2_common,
  },
  {
    title: '609-3',
    roomNo: '609-3',
    width: width(38),
    left: left(888),
    top: top(315),
    fontSize: fontSize(38, 12),
    image: img_609_3_common,
  },
  {
    title: '茶水休闲区',
    roomNo: '600-4',
    width: width(90),
    left: left(984),
    top: top(315),
    fontSize: fontSize(90, 16),
    image: img_600_4_common,
    selectedImage: img_600_4_selected,
  },
  {
    title: '610',
    roomNo: '610',
    width: width(48),
    left: left(1076),
    top: top(315),
    fontSize: fontSize(48, 16),
    image: img_610_common,
    selectedImage: img_610_selected,
  },
  {
    title: '611',
    roomNo: '611',
    width: width(48),
    left: left(1126),
    top: top(315),
    fontSize: fontSize(48, 16),
    image: img_611_common,
    selectedImage: img_611_selected,
  },
  {
    title: '612-1',
    titleLeft: `${100 * (1218 - 1176) / 98}%`,
    titleTop: `${100 * (365 - 315) / 98}%`,
    titleWidth: 'auto',
    roomNo: '612-1',
    width: width(98),
    left: left(1176),
    top: top(315),
    fontSize: fontSize(98, 16),
    image: img_612_1_common,
    selectedImage: img_612_1_selected,
  },
  {
    title: '612-3',
    roomNo: '612-3',
    width: width(32),
    left: left(1176),
    top: top(315),
    fontSize: fontSize(32, 12),
    image: img_612_3_common,
  },
  {
    title: '612-2',
    roomNo: '612-2',
    width: width(40),
    left: left(1210),
    top: top(315),
    fontSize: fontSize(40, 12),
    image: img_612_2_common,
  },
  {
    title: '新风机房',
    roomNo: '640',
    width: width(70),
    left: left(1204),
    top: top(399),
    fontSize: 'small',
    image: img_640_common,
    selectedImage: img_640_selected,
  },
  {
    title: '613-1',
    titleTop: `${100 * (507 - 431) / 70}%`,
    roomNo: '613-1',
    width: width(70),
    left: left(1204),
    top: top(431),
    fontSize: fontSize(70, 16),
    image: img_613_1_common,
    selectedImage: img_613_1_selected,
  },
  {
    title: '613-2',
    roomNo: '613-2',
    width: width(50),
    left: left(1224),
    top: top(457),
    fontSize: fontSize(50, 12),
    image: img_613_2_common,
    // selectedImage: img_613_2_selected,
  },
  {
    title: '613-3',
    roomNo: '613-3',
    width: width(50),
    left: left(1224),
    top: top(431),
    fontSize: fontSize(50, 12),
    image: img_613_3_common,
    // selectedImage: img_613_3_selected,
  },
  {
    title: '615-1',
    titleLeft: `${100 * (1218 - 1174) / 100}%`,
    titleTop: `${100 * (587 - 557) / 100}%`,
    titleWidth: 'auto',
    roomNo: '615-1',
    width: width(100),
    left: left(1174),
    top: top(557),
    fontSize: fontSize(100, 16),
    image: img_615_1_common,
    selectedImage: img_615_1_selected,
  },
  {
    title: '615-3',
    roomNo: '615-3',
    width: width(32),
    left: left(1174),
    top: top(571),
    fontSize: fontSize(32, 12),
    image: img_615_3_common,
    // selectedImage: img_615_3_selected,
  },
  {
    title: '615-2',
    roomNo: '615-2',
    width: width(40),
    left: left(1208),
    top: top(613),
    fontSize: fontSize(40, 12),
    image: img_615_2_common,
    // selectedImage: img_615_2_selected,
  },
  {
    title: '616-1',
    titleLeft: `${100 * (1090 - 1082) / 90}%`,
    titleTop: `${100 * (587 - 557) / 90}%`,
    titleWidth: 'auto',
    roomNo: '616-1',
    width: width(90),
    left: left(1082),
    top: top(557),
    fontSize: fontSize(90, 16),
    image: img_616_1_common,
    selectedImage: img_616_1_selected,
  },
  {
    title: '616-3',
    roomNo: '616-3',
    width: width(32),
    left: left(1140),
    top: top(571),
    fontSize: fontSize(32, 12),
    image: img_616_3_common,
    // selectedImage: img_616_3_selected,
  },
  {
    title: '茶水休闲区',
    roomNo: '600-3',
    width: width(92),
    left: left(988),
    top: top(557),
    fontSize: fontSize(92, 12),
    image: img_600_3_common,
    selectedImage: img_600_3_selected,
  },
  {
    title: '617-1',
    titleLeft: `${100 * (900 - 888) / 98}%`,
    titleTop: `${100 * (587 - 557) / 98}%`,
    titleWidth: 'auto',
    roomNo: '617-1',
    width: width(98),
    left: left(888),
    top: top(557),
    fontSize: fontSize(98, 16),
    image: img_617_1_common,
    selectedImage: img_617_1_selected,
  },
  {
    title: '617-3',
    roomNo: '617-3',
    width: width(32),
    left: left(954),
    top: top(571),
    fontSize: fontSize(32, 12),
    image: img_617_3_common,
    // selectedImage: img_617_3_selected,
  },
  {
    title: '618-1',
    titleTop: `${100 * (567 - 557) / 92}%`,
    roomNo: '618-1',
    width: width(92),
    left: left(794),
    top: top(557),
    fontSize: fontSize(92, 16),
    image: img_618_1_common,
    selectedImage: img_618_1_selected,
  },
  {
    title: '618-2',
    roomNo: '618-2',
    width: width(32),
    left: left(834),
    top: top(599),
    fontSize: fontSize(32, 12),
    image: img_618_2_common,
    // selectedImage: img_618_2_selected,
  },
  {
    title: '618-3',
    roomNo: '618-3',
    width: width(38),
    left: left(794),
    top: top(599),
    fontSize: fontSize(38, 12),
    image: img_618_3_common,
    // selectedImage: img_618_3_selected,
  },
  {
    title: '619-1',
    titleTop: `${100 * (567 - 557) / 94}%`,
    roomNo: '619-1',
    width: width(94),
    left: left(698),
    top: top(557),
    fontSize: fontSize(94, 16),
    image: img_619_1_common,
    selectedImage: img_619_1_selected,
  },
  {
    title: '619-2',
    roomNo: '619-2',
    width: width(32),
    left: left(720),
    top: top(599),
    fontSize: fontSize(32, 12),
    image: img_619_2_common,
    // selectedImage: img_619_2_selected,
  },
  {
    title: '619-3',
    roomNo: '619-3',
    width: width(38),
    left: left(754),
    top: top(599),
    fontSize: fontSize(38, 12),
    image: img_619_3_common,
    // selectedImage: img_619_3_selected,
  },
  {
    title: '620-1',
    titleTop: `${100 * (567 - 557) / 94}%`,
    roomNo: '620-1',
    width: width(94),
    left: left(602),
    top: top(557),
    fontSize: fontSize(94, 16),
    image: img_620_1_common,
    // selectedImage: img_620_1_selected,
  },
  {
    title: '620-2',
    roomNo: '620-2',
    width: width(32),
    left: left(642),
    top: top(599),
    fontSize: fontSize(32, 12),
    image: img_620_2_common,
    // selectedImage: img_620_2_selected,
  },
  {
    title: '620-3',
    roomNo: '620-3',
    width: width(38),
    left: left(602),
    top: top(599),
    fontSize: fontSize(38, 12),
    image: img_620_3_common,
    // selectedImage: img_620_3_selected,
  },
  {
    title: '621-1',
    titleTop: `${100 * (567 - 557) / 98}%`,
    roomNo: '621-1',
    width: width(98),
    left: left(502),
    top: top(557),
    fontSize: fontSize(98, 16),
    image: img_621_1_common,
    selectedImage: img_621_1_selected,
  },
  {
    title: '621-2',
    roomNo: '621-2',
    width: width(32),
    left: left(528),
    top: top(599),
    fontSize: fontSize(32, 12),
    image: img_621_2_common,
    // selectedImage: img_621_2_selected,
  },
  {
    title: '621-3',
    roomNo: '621-3',
    width: width(38),
    left: left(562),
    top: top(599),
    fontSize: fontSize(38, 12),
    image: img_621_3_common,
    // selectedImage: img_621_3_selected,
  },
  {
    title: '622-1',
    titleTop: `${100 * (567 - 557) / 88}%`,
    roomNo: '622-1',
    width: width(88),
    left: left(412),
    top: top(557),
    fontSize: fontSize(88, 16),
    image: img_622_1_common,
    selectedImage: img_622_1_selected,
  },
  {
    title: '622-2',
    roomNo: '622-2',
    width: width(32),
    left: left(412),
    top: top(599),
    fontSize: fontSize(32, 12),
    image: img_622_2_common,
    // selectedImage: img_622_2_selected,
  },
  {
    title: '622-3',
    roomNo: '622-3',
    width: width(38),
    left: left(446),
    top: top(599),
    fontSize: fontSize(38, 12),
    image: img_622_3_common,
    // selectedImage: img_622_3_selected,
  },
  {
    title: '623-1',
    titleTop: `${100 * (567 - 557) / 110}%`,
    roomNo: '623-1',
    width: width(110),
    left: left(300),
    top: top(557),
    fontSize: fontSize(110, 12),
    image: img_623_1_common,
    selectedImage: img_623_1_selected,
  },
  {
    title: '623-2',
    roomNo: '623-2',
    width: width(32),
    left: left(338),
    top: top(599),
    fontSize: fontSize(32, 12),
    image: img_623_2_common,
    // selectedImage: img_623_2_selected,
  },
  {
    title: '623-3',
    roomNo: '623-3',
    width: width(38),
    left: left(372),
    top: top(599),
    fontSize: fontSize(38, 12),
    image: img_623_3_common,
    // selectedImage: img_623_3_selected,
  },
  {
    title: '625-1',
    roomNo: '625-1',
    width: width(118),
    left: left(300),
    top: top(478),
    fontSize: fontSize(118, 16),
    image: img_625_1_common,
    selectedImage: img_625_1_selected,
  },
  {
    title: '625-2',
    roomNo: '625-2',
    width: width(40),
    left: left(300),
    top: top(478),
    fontSize: fontSize(40, 12),
    image: img_625_2_common,
    // selectedImage: img_625_2_selected,
  },
  {
    title: '602-1',
    roomNo: '602-1',
    width: width(118),
    left: left(300),
    top: top(424),
    fontSize: fontSize(118, 16),
    image: img_602_1_common,
    selectedImage: img_602_1_selected,
  },
  {
    title: '602-2',
    roomNo: '602-2',
    width: width(40),
    left: left(300),
    top: top(424),
    fontSize: fontSize(40, 12),
    image: img_602_2_common,
    // selectedImage: img_602_2_selected,
  },
  {
    title: '630',
    roomNo: '630',
    width: width(58),
    left: left(444),
    top: top(424),
    fontSize: fontSize(58, 12),
    image: img_630_common,
    selectedImage: img_630_selected,
  },
  {
    title: '649',
    roomNo: '649',
    width: width(58),
    left: left(444),
    top: top(478),
    fontSize: fontSize(58, 12),
    image: img_649_common,
    selectedImage: img_649_selected,
  },
  {
    title: '631',
    roomNo: '631',
    width: width(17),
    left: left(548),
    top: top(423),
    fontSize: fontSize(17, 12),
    image: img_631_common,
    selectedImage: img_631_selected,
  },
  {
    title: '632',
    roomNo: '632',
    width: width(40),
    left: left(568),
    top: top(424),
    fontSize: fontSize(40, 12),
    image: img_632_common,
    selectedImage: img_632_selected,
  },
  {
    title: '648',
    roomNo: '648',
    width: width(40),
    left: left(568),
    top: top(470),
    fontSize: fontSize(40, 12),
    image: img_648_common,
    selectedImage: img_648_selected,
  },
  {
    title: '633',
    roomNo: '633',
    width: width(58),
    left: left(634),
    top: top(424),
    fontSize: fontSize(58, 16),
    image: img_633_common,
    selectedImage: img_633_selected,
  },
  {
    title: '647',
    roomNo: '647',
    width: width(48),
    left: left(634),
    top: top(478),
    fontSize: fontSize(48, 16),
    image: img_647_common,
    selectedImage: img_647_selected,
  },
  {
    title: '635',
    roomNo: '635',
    width: width(58),
    left: left(694),
    top: top(424),
    fontSize: fontSize(58, 16),
    image: img_635_common,
    selectedImage: img_635_selected,
  },
  {
    title: '646',
    roomNo: '646',
    width: width(58),
    left: left(694),
    top: top(478),
    fontSize: fontSize(58, 16),
    image: img_646_common,
    selectedImage: img_646_selected,
  },
  {
    title: '636',
    roomNo: '636',
    width: width(34),
    left: left(778),
    top: top(424),
    fontSize: fontSize(34, 12),
    image: img_636_common,
    selectedImage: img_636_selected,
  },
  {
    title: '637',
    roomNo: '637',
    width: width(42),
    left: left(814),
    top: top(424),
    fontSize: fontSize(42, 16),
    image: img_637_common,
    selectedImage: img_637_selected,
  },
  {
    title: '645',
    roomNo: '645',
    width: width(60),
    left: left(796),
    top: top(478),
    fontSize: fontSize(60, 16),
    image: img_645_common,
    selectedImage: img_645_selected,
  },
  {
    title: '638',
    roomNo: '638',
    width: width(58),
    left: left(882),
    top: top(22),
    fontSize: fontSize(58, 16),
    image: img_638_common,
    selectedImage: img_638_selected,
  },
  {
    title: '643',
    roomNo: '643',
    width: width(58),
    left: left(882),
    top: top(488),
    fontSize: fontSize(58, 16),
    image: img_643_common,
    selectedImage: img_643_selected,
  },
  {
    title: '642',
    roomNo: '642',
    width: width(34),
    left: left(983),
    top: top(502),
    fontSize: fontSize(34, 12),
    image: img_642_common,
    selectedImage: img_642_selected,
  },
  {
    title: '639',
    roomNo: '639',
    width: width(18),
    left: left(1086),
    top: top(424),
    fontSize: fontSize(18, 12),
    writingMode: 'vertical-lr',
    image: img_639_common,
    selectedImage: img_639_selected,
  },
  {
    title: '641',
    roomNo: '641',
    width: width(26),
    left: left(1082),
    top: top(489),
    fontSize: fontSize(26, 12),
    image: img_641_common,
    selectedImage: img_641_selected,
  },
  {
    title: '600-2',
    roomNo: '600-2',
    width: width(856),
    left: left(344),
    top: top(396),
    fontSize: fontSize(856, 12),
    color: '#808080',
    image: img_600_2_common,
    selectedImage: img_600_2_selected,
  },
  {
    title: '600-1',
    roomNo: '600-1',
    width: width(856),
    left: left(344),
    top: top(529),
    fontSize: fontSize(856, 12),
    color: '#808080',
    image: img_600_1_common,
    selectedImage: img_600_1_selected,
  },
  {
    title: '600-10',
    roomNo: '600-10',
    width: width(18),
    left: left(424),
    top: top(424),
    fontSize: fontSize(18, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_600_10_common,
    selectedImage: img_600_10_selected,
  },
  {
    title: '600-9',
    roomNo: '600-9',
    width: width(18),
    left: left(612),
    top: top(424),
    fontSize: fontSize(18, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_600_9_common,
    selectedImage: img_600_9_selected,
  },
  {
    title: '600-8',
    roomNo: '600-8',
    width: width(18),
    left: left(756),
    top: top(424),
    fontSize: fontSize(18, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_600_8_common,
    selectedImage: img_600_8_selected,
  },
  {
    title: '600-7',
    roomNo: '600-7',
    width: width(18),
    left: left(860),
    top: top(424),
    fontSize: fontSize(18, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_600_7_common,
    selectedImage: img_600_7_selected,
  },
  {
    title: '600-6',
    roomNo: '600-6',
    width: width(22),
    left: left(1022),
    top: top(424),
    fontSize: fontSize(22, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_600_6_common,
    selectedImage: img_600_6_selected,
  },
  {
    title: '600-5',
    roomNo: '600-5',
    width: width(18),
    left: left(1182),
    top: top(424),
    fontSize: fontSize(18, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_600_6_common,
    selectedImage: img_600_6_selected,
  },
]

export default {
  bg,
  initialRoomList
}