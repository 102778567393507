import { useState, useEffect } from 'react';
import { Table, Space, DatePicker, Button, Select as AntSelect, Divider, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs'
import Select from '@/components/Select';
import { getBillList, getBillListCount } from '@/services/bill';
import { getFloorRoomList } from '@/services/workspace';
import RoomMonthlyDetail from '../RoomMonthlyDetail';
import { useSelector } from 'react-redux';
import { getSelectedTextFromList } from '@/utils/transform';
import { billItemTypeMap } from '@/common/constants'; 

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
`

const { Option, OptGroup } = AntSelect;
const { Column } = Table;

const MonthlyBill = () => {
  const [loading, setLoading] = useState(false);
  const [queryStartDate, setQueryStartDate] = useState<dayjs.Dayjs | null>(null);
  const [queryEndDate, setQueryEndDate] = useState<dayjs.Dayjs | null>(null);
  const [roomNo, setRoomNo] = useState('');
  const [floorRoomMap, setFloorRoomMap] = useState<any>({});
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataCount, setDataCount] = useState(0);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [billDateSign, setBillDateSign] = useState<string>('202405');
  const businessTypeList = useSelector((state: any) => state.common.businessTypeList);
  const [corpName, setCorpName] = useState('');

  const expandedRowRender = (record: any, index: number) => (
    <Table dataSource={record.itemList} pagination={false}>
      <Column title="账单标题" dataIndex="itemTitle" />
      <Column title="房间编号" dataIndex="roomNo" />
      <Column title="明细类型" dataIndex="itemType" render={(data) => billItemTypeMap[data] ?? ''} />
      <Column
        title="状态" 
        dataIndex="itemStatus"
        render={(_: number) => _ === 0 ? '无效' : '有效'}
      />
      <Column title="减免金额" dataIndex="freeAmount" />
      <Column title="应付金额" dataIndex="payableAmount" />
      <Column title="总金额" dataIndex="totalAmount" />
      <Column title="备注" dataIndex="cmt" />
    </Table>
  )

  const getData = (pageIndex = 1, pageSize = 30) => {
    setLoading(true);
    const params = {
      // roomNo,
      corpName,
      billDateSign: billDateSign,
    }
    getBillList({
      currentPage: pageIndex,
      pageSize,
      ...params
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data.map((d: any) => ({...d, key: d.billId})));
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
    getBillListCount(params).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataCount(data);
      }
    })
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  }

  const onPagination = (page: number, pageSize_?: number | undefined) => {
    getData(page, pageSize_)
  }

  useEffect(() => {
    getData();
  }, [billDateSign, roomNo, corpName])

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      setFloorRoomMap(res.data);
    })
  }, [])

  return (
    <div>
      <SearchBar>
        <Space>
          <DatePicker picker="month" onChange={(date, dateString) => {
            if (date) {
              console.log('vals: ', date.format('YYYYMM'), dateString)
              setBillDateSign(date.format('YYYYMM'));
            }
          }} />
          {/* <Select
            showSearch
            label="所属房间"
            value={roomNo}
            onChange={val => setRoomNo(val)}
            optionsRender={() => Object.keys(floorRoomMap).sort((prev: string, next: string) => {
              return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
            }).map((floorName: string, floorIndex: number) => (
              <OptGroup key={`device_edit_floow_${floorIndex}`} label={floorName}>
                {
                  floorRoomMap[floorName].map((roomName: string, roomIndex: number) => (
                    <Option key={`device_edit_${floorIndex}room_${roomIndex}`} value={roomName}>{roomName}</Option>
                  ))
                }
              </OptGroup>
            ))}
          /> */}
          <Input 
            placeholder="公司名称"
            onChange={e => setCorpName(e.target.value)} 
          />
        </Space>
        <Space>
          <Link to="/energy/monthlybillc">日历视图</Link>
          <Divider type="vertical" />
          <Link to="/energy/dailybill">日账单<RightOutlined /></Link>
        </Space>
      </SearchBar>
      <Table
        dataSource={dataSource}
        expandable={{ expandedRowRender }}
        loading={loading}
        pagination={{
          total: dataCount,
          pageSize: pageSize,
          current: pageIndex,
          onChange: onPagination,
          showSizeChanger: true
        }}
        scroll={{ x: 1500 }}
      >
        <Column title="账单标题" dataIndex="billTitle" />
        <Column title="公司名称" dataIndex="corpName" />
        <Column title="房间号" dataIndex="roomNo" width={100} />
        <Column title="账单名称" dataIndex="billTitle" />
        <Column title="减免金额" dataIndex="freeAmount" width={100} />
        <Column title="应付金额" dataIndex="payableAmount" width={100} />
        <Column title="实付金额" dataIndex="paidAmount" width={100} />
        <Column title="总金额" dataIndex="totalAmount" width={100} />
        <Column title="收款方" dataIndex="payeeName" width={120} />
        <Column title="账单时间" dataIndex="billDateSign" width={100} />
        {/* <Column
          title="操作" 
          render={(_: string, record: any) => (
            <Button type="link" onClick={() => {
              setSelectedRecord(record)
              setIsShowDetail(true)
            }}>详情</Button>
          )}
        /> */}
      </Table>
      <RoomMonthlyDetail
        visible={isShowDetail}
        billId={selectedRecord.billId}
        onClose={() => setIsShowDetail(false)}
      />
    </div>
  )
}

export default MonthlyBill;