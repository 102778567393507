import styled from 'styled-components';

interface StyledProps {
  actived?: boolean;
}

const Container = styled.div<StyledProps>`
  height: 33px;
  background: ${(props) => props.actived ? '#EBF3FF' : '#F1F2F4'};
  border-radius: 16px;
  border: 1px solid ${(props) => props.actived ? '#3371FF' : '#F1F2F4'};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding-left: 9px;
  padding-right: 15px;
  cursor: pointer;
`

const Circle = styled.div<StyledProps>`
  background-color: #FFFFFF;
  border: ${(props) => props.actived ? '5px solid #3371FF' : '1px solid #C2C5CA'};
  border-radius: 50%;
  width: 16px;
  height: 16px;
`

const Text = styled.div`
  padding-left: 10px;
  font-size: 13px;
  color: #1F2329;
`

interface Props {
  mode: 'edit' | 'readonly'
  title: string;
  actived?: boolean;
  onClick: () => void;
}

const CorpStatus: React.FC<Props> = ({
  mode,
  title,
  actived,
  onClick
}) => {
  if (mode === 'readonly' && !actived) {
    return <></>
  }
  return (
    <Container actived={actived} onClick={onClick}>
      <Circle actived={actived} />
      <Text>{title}</Text>
    </Container>
  )
}

export default CorpStatus;