import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Company } from "../../types/common";

type CompanyState = {
  companyList: Company[];
  selectedCorpId: number | string;
  pageIndex: number;
  pageSize: number;
  corpName: string;
  contractStatus: string;
}

const initialState: CompanyState = {
  companyList: [],
  selectedCorpId: 0,
  pageIndex: 1,
  pageSize: 100,
  corpName: '',
  contractStatus: '',
}

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setSelectedCorpId(state, action: PayloadAction<number | string | undefined>) {
      state.selectedCorpId = action.payload ?? ''
    },
    setPagination(state, action: PayloadAction<{pageIndex: number, pageSize: number}>) {
      state.pageIndex = action.payload.pageIndex
      state.pageSize = action.payload.pageSize
    },
    setCorpName(state, action: PayloadAction<string>) {
      state.corpName = action.payload
    },
    setCorpContractStatus(state, action: PayloadAction<string>) {
      state.contractStatus = action.payload
    }
  }
})

export const { setSelectedCorpId, setPagination, setCorpName, setCorpContractStatus } = companySlice.actions

export default companySlice.reducer