import bg from '@/assets/energy/floors/7/7.png';
import img_700_1_common from '@/assets/energy/floors/7/common/700-1.png';
import img_700_2_common from '@/assets/energy/floors/7/common/700-2.png';
import img_700_3_common from '@/assets/energy/floors/7/common/700-3.png';
import img_700_4_common from '@/assets/energy/floors/7/common/700-4.png';
import img_700_5_common from '@/assets/energy/floors/7/common/700-5.png';
import img_700_6_common from '@/assets/energy/floors/7/common/700-6.png';
import img_700_7_common from '@/assets/energy/floors/7/common/700-7.png';
import img_700_8_common from '@/assets/energy/floors/7/common/700-8.png';
import img_701_1_common from '@/assets/energy/floors/7/common/701-1.png';
import img_701_2_common from '@/assets/energy/floors/7/common/701-2.png';
import img_701_3_common from '@/assets/energy/floors/7/common/701-3.png';
import img_702_1_common from '@/assets/energy/floors/7/common/702-1.png';
import img_702_2_common from '@/assets/energy/floors/7/common/702-2.png';
import img_702_3_common from '@/assets/energy/floors/7/common/702-3.png';
import img_703_1_common from '@/assets/energy/floors/7/common/703-1.png';
import img_703_2_common from '@/assets/energy/floors/7/common/703-2.png';
import img_703_3_common from '@/assets/energy/floors/7/common/703-3.png';
import img_703_4_common from '@/assets/energy/floors/7/common/703-4.png';
import img_705_1_common from '@/assets/energy/floors/7/common/705-1.png';
import img_705_2_common from '@/assets/energy/floors/7/common/705-2.png';
import img_705_3_common from '@/assets/energy/floors/7/common/705-3.png';
import img_705_4_common from '@/assets/energy/floors/7/common/705-4.png';
import img_706_1_common from '@/assets/energy/floors/7/common/706-1.png';
import img_706_2_common from '@/assets/energy/floors/7/common/706-2.png';
import img_706_3_common from '@/assets/energy/floors/7/common/706-3.png';
import img_706_4_common from '@/assets/energy/floors/7/common/706-4.png';
import img_707_1_common from '@/assets/energy/floors/7/common/707-1.png';
import img_707_2_common from '@/assets/energy/floors/7/common/707-2.png';
import img_707_3_common from '@/assets/energy/floors/7/common/707-3.png';
import img_708_1_common from '@/assets/energy/floors/7/common/708-1.png';
import img_708_2_common from '@/assets/energy/floors/7/common/708-2.png';
import img_708_3_common from '@/assets/energy/floors/7/common/708-3.png';
import img_709_1_common from '@/assets/energy/floors/7/common/709-1.png';
import img_709_2_common from '@/assets/energy/floors/7/common/709-2.png';
import img_709_3_common from '@/assets/energy/floors/7/common/709-3.png';
import img_710_1_common from '@/assets/energy/floors/7/common/710-1.png';
import img_710_2_common from '@/assets/energy/floors/7/common/710-2.png';
import img_710_3_common from '@/assets/energy/floors/7/common/710-3.png';
import img_711_1_common from '@/assets/energy/floors/7/common/711-1.png';
import img_711_2_common from '@/assets/energy/floors/7/common/711-2.png';
import img_711_3_common from '@/assets/energy/floors/7/common/711-3.png';
import img_711_4_common from '@/assets/energy/floors/7/common/711-4.png';
import img_712_1_common from '@/assets/energy/floors/7/common/712-1.png';
import img_712_2_common from '@/assets/energy/floors/7/common/712-2.png';
import img_712_3_common from '@/assets/energy/floors/7/common/712-3.png';
import img_713_1_common from '@/assets/energy/floors/7/common/713-1.png';
import img_713_2_common from '@/assets/energy/floors/7/common/713-2.png';
import img_713_3_common from '@/assets/energy/floors/7/common/713-3.png';
import img_713_4_common from '@/assets/energy/floors/7/common/713-4.png';
import img_715_1_common from '@/assets/energy/floors/7/common/715-1.png';
import img_715_2_common from '@/assets/energy/floors/7/common/715-2.png';
import img_715_3_common from '@/assets/energy/floors/7/common/715-3.png';
import img_715_4_common from '@/assets/energy/floors/7/common/715-4.png';
import img_730_common from '@/assets/energy/floors/7/common/730.png';
import img_731_common from '@/assets/energy/floors/7/common/731.png';
import img_732_common from '@/assets/energy/floors/7/common/732.png';
import img_733_common from '@/assets/energy/floors/7/common/733.png';
import img_734_common from '@/assets/energy/floors/7/common/734.png';
import img_735_common from '@/assets/energy/floors/7/common/735.png';
import img_736_common from '@/assets/energy/floors/7/common/736.png';
import img_737_common from '@/assets/energy/floors/7/common/737.png';
import img_738_common from '@/assets/energy/floors/7/common/738.png';
import img_739_common from '@/assets/energy/floors/7/common/739.png';
import img_740_common from '@/assets/energy/floors/7/common/740.png';
import img_741_common from '@/assets/energy/floors/7/common/741.png';
import img_742_common from '@/assets/energy/floors/7/common/742.png';
import img_743_common from '@/assets/energy/floors/7/common/743.png';
import img_744_common from '@/assets/energy/floors/7/common/744.png';
import img_745_common from '@/assets/energy/floors/7/common/745.png';
import img_746_common from '@/assets/energy/floors/7/common/746.png';
import img_747_common from '@/assets/energy/floors/7/common/747.png';
import img_748_common from '@/assets/energy/floors/7/common/748.png';
import img_749_common from '@/assets/energy/floors/7/common/749.png';

import img_700_1_selected from '@/assets/energy/floors/7/selected/700-1.png';
import img_700_2_selected from '@/assets/energy/floors/7/selected/700-2.png';
import img_700_3_selected from '@/assets/energy/floors/7/selected/700-3.png';
import img_700_4_selected from '@/assets/energy/floors/7/selected/700-4.png';
import img_700_5_selected from '@/assets/energy/floors/7/selected/700-5.png';
import img_700_6_selected from '@/assets/energy/floors/7/selected/700-6.png';
import img_700_7_selected from '@/assets/energy/floors/7/selected/700-7.png';
import img_700_8_selected from '@/assets/energy/floors/7/selected/700-8.png';
import img_701_1_selected from '@/assets/energy/floors/7/selected/701-1.png';
import img_701_2_selected from '@/assets/energy/floors/7/selected/701-2.png';
import img_701_3_selected from '@/assets/energy/floors/7/selected/701-3.png';
import img_702_1_selected from '@/assets/energy/floors/7/selected/702-1.png';
import img_702_2_selected from '@/assets/energy/floors/7/selected/702-2.png';
import img_702_3_selected from '@/assets/energy/floors/7/selected/702-3.png';
import img_703_1_selected from '@/assets/energy/floors/7/selected/703-1.png';
import img_703_2_selected from '@/assets/energy/floors/7/selected/703-2.png';
import img_703_3_selected from '@/assets/energy/floors/7/selected/703-3.png';
import img_703_4_selected from '@/assets/energy/floors/7/selected/703-4.png';
import img_705_1_selected from '@/assets/energy/floors/7/selected/705-1.png';
import img_705_2_selected from '@/assets/energy/floors/7/selected/705-2.png';
import img_705_3_selected from '@/assets/energy/floors/7/selected/705-3.png';
import img_705_4_selected from '@/assets/energy/floors/7/selected/705-4.png';
import img_706_1_selected from '@/assets/energy/floors/7/selected/706-1.png';
import img_706_2_selected from '@/assets/energy/floors/7/selected/706-2.png';
import img_706_3_selected from '@/assets/energy/floors/7/selected/706-3.png';
import img_706_4_selected from '@/assets/energy/floors/7/selected/706-4.png';
import img_707_1_selected from '@/assets/energy/floors/7/selected/707-1.png';
import img_707_2_selected from '@/assets/energy/floors/7/selected/707-2.png';
import img_707_3_selected from '@/assets/energy/floors/7/selected/707-3.png';
import img_708_1_selected from '@/assets/energy/floors/7/selected/708-1.png';
import img_708_2_selected from '@/assets/energy/floors/7/selected/708-2.png';
import img_708_3_selected from '@/assets/energy/floors/7/selected/708-3.png';
import img_709_1_selected from '@/assets/energy/floors/7/selected/709-1.png';
import img_709_2_selected from '@/assets/energy/floors/7/selected/709-2.png';
import img_709_3_selected from '@/assets/energy/floors/7/selected/709-3.png';
import img_710_1_selected from '@/assets/energy/floors/7/selected/710-1.png';
import img_710_2_selected from '@/assets/energy/floors/7/selected/710-2.png';
import img_710_3_selected from '@/assets/energy/floors/7/selected/710-3.png';
import img_711_1_selected from '@/assets/energy/floors/7/selected/711-1.png';
import img_711_2_selected from '@/assets/energy/floors/7/selected/711-2.png';
import img_711_3_selected from '@/assets/energy/floors/7/selected/711-3.png';
import img_711_4_selected from '@/assets/energy/floors/7/selected/711-4.png';
import img_712_1_selected from '@/assets/energy/floors/7/selected/712-1.png';
import img_712_2_selected from '@/assets/energy/floors/7/selected/712-2.png';
import img_712_3_selected from '@/assets/energy/floors/7/selected/712-3.png';
import img_713_1_selected from '@/assets/energy/floors/7/selected/713-1.png';
import img_713_2_selected from '@/assets/energy/floors/7/selected/713-2.png';
import img_713_3_selected from '@/assets/energy/floors/7/selected/713-3.png';
import img_713_4_selected from '@/assets/energy/floors/7/selected/713-4.png';
import img_715_1_selected from '@/assets/energy/floors/7/selected/715-1.png';
import img_715_2_selected from '@/assets/energy/floors/7/selected/715-2.png';
import img_715_3_selected from '@/assets/energy/floors/7/selected/715-3.png';
import img_715_4_selected from '@/assets/energy/floors/7/selected/715-4.png';
import img_730_selected from '@/assets/energy/floors/7/selected/730.png';
import img_731_selected from '@/assets/energy/floors/7/selected/731.png';
import img_732_selected from '@/assets/energy/floors/7/selected/732.png';
import img_733_selected from '@/assets/energy/floors/7/selected/733.png';
import img_734_selected from '@/assets/energy/floors/7/selected/734.png';
import img_735_selected from '@/assets/energy/floors/7/selected/735.png';
import img_736_selected from '@/assets/energy/floors/7/selected/736.png';
import img_737_selected from '@/assets/energy/floors/7/selected/737.png';
import img_738_selected from '@/assets/energy/floors/7/selected/738.png';
import img_739_selected from '@/assets/energy/floors/7/selected/739.png';
import img_740_selected from '@/assets/energy/floors/7/selected/740.png';
import img_741_selected from '@/assets/energy/floors/7/selected/741.png';
import img_742_selected from '@/assets/energy/floors/7/selected/742.png';
import img_743_selected from '@/assets/energy/floors/7/selected/743.png';
import img_744_selected from '@/assets/energy/floors/7/selected/744.png';
import img_745_selected from '@/assets/energy/floors/7/selected/745.png';
import img_746_selected from '@/assets/energy/floors/7/selected/746.png';
import img_747_selected from '@/assets/energy/floors/7/selected/747.png';
import img_748_selected from '@/assets/energy/floors/7/selected/748.png';
import img_749_selected from '@/assets/energy/floors/7/selected/749.png';

const width = (w: number) => `${100 * (w / 987)}%`;
const left = (l: number) => `${100 * (l - 300) / 987}%`;
const top = (t: number) => `${100 * (t - 276) / 404}%`;
const fontSize = (w: number, f: number) => f > 12 ? '12px' : '9px'


// {
//   title: '7xx-',
//   roomNo: '7xx-',
//   width: width(w),
//   left: left(),
//   top: top(),
//   fontSize: fontSize(w, 12),
//   color: '#808080',
//   image: img_7xx__common,
//   selectedImage: img_7xx__selected,
// },

const initialRoomList = [
  {
    title: '701-1',
    titleLeft: `${100 * (327 - 303) / 137}%`,
    titleWidth: 'atuo',
    roomNo: '701-1',
    width: width(137),
    left: left(303),
    top: top(279),
    fontSize: fontSize(137, 16),
    image: img_701_1_common,
    selectedImage: img_701_1_selected,
  },
  {
    title: '701-2',
    roomNo: '701-2',
    width: width(48),
    left: left(392),
    top: top(319),
    fontSize: fontSize(48, 12),
    image: img_701_2_common,
    // selectedImage: img_701_2_selected,
  },
  {
    title: '701-3',
    roomNo: '701-3',
    width: width(48),
    left: left(392),
    top: top(279),
    fontSize: fontSize(48, 12),
    image: img_701_3_common,
    // selectedImage: img_701_3_selected,
  },
  {
    title: '702-1',
    titleLeft: `${100 * (504 - 442) / 118}%`,
    titleWidth: 'atuo',
    roomNo: '702-1',
    width: width(118),
    left: left(442),
    top: top(279),
    fontSize: fontSize(118, 16),
    image: img_702_1_common,
    selectedImage: img_702_1_selected,
  },
  {
    title: '702-2',
    roomNo: '702-2',
    width: width(48),
    left: left(442),
    top: top(319),
    fontSize: fontSize(48, 12),
    image: img_702_2_common,
    // selectedImage: img_702_2_selected,
  },
  {
    title: '702-3',
    roomNo: '702-3',
    width: width(48),
    left: left(442),
    top: top(279),
    fontSize: fontSize(48, 12),
    image: img_702_3_common,
    // selectedImage: img_702_3_selected,
  },
  {
    title: '新风机房',
    roomNo: '732',
    width: width(46),
    left: left(562),
    top: top(279),
    fontSize: fontSize(46, 12),
    writingMode: 'vertical-lr',
    image: img_732_common,
    selectedImage: img_732_selected,
  },
  {
    title: '703-1',
    titleLeft: `${100 * (702 - 610) / 176}%`,
    titleWidth: 'atuo',
    roomNo: '703-1',
    width: width(176),
    left: left(610),
    top: top(279),
    fontSize: fontSize(176, 16),
    image: img_703_1_common,
    selectedImage: img_703_1_selected,
  },
  {
    title: '703-2',
    roomNo: '703-2',
    width: width(30),
    left: left(630),
    top: top(339),
    fontSize: fontSize(30, 12),
    image: img_703_2_common,
    // selectedImage: img_703_2_selected,
  },
  {
    title: '703-3',
    roomNo: '703-3',
    width: width(50),
    left: left(610),
    top: top(279),
    fontSize: fontSize(50, 12),
    image: img_703_3_common,
    // selectedImage: img_703_3_selected,
  },
  {
    title: '703-4',
    roomNo: '703-4',
    width: width(42),
    left: left(662),
    top: top(353),
    fontSize: fontSize(42, 12),
    image: img_703_4_common,
    // selectedImage: img_703_4_selected,
  },
  {
    title: '733',
    roomNo: '733',
    width: width(18),
    left: left(610),
    top: top(339),
    fontSize: fontSize(18, 12),
    writingMode: 'vertical-lr',
    image: img_733_common,
    selectedImage: img_733_selected,
  },
  {
    title: '705-1',
    titleLeft: `${100 * (830 - 788) / 176}%`,
    titleWidth: 'atuo',
    roomNo: '705-1',
    width: width(176),
    left: left(788),
    top: top(279),
    fontSize: fontSize(176, 16),
    image: img_705_1_common,
    selectedImage: img_705_1_selected,
  },
  {
    title: '705-2',
    roomNo: '705-2',
    width: width(30),
    left: left(914),
    top: top(339),
    fontSize: fontSize(30, 12),
    image: img_705_2_common,
    // selectedImage: img_705_2_selected,
  },
  {
    title: '705-3',
    roomNo: '705-3',
    width: width(50),
    left: left(914),
    top: top(279),
    fontSize: fontSize(50, 12),
    image: img_705_3_common,
    // selectedImage: img_705_3_selected,
  },
  {
    title: '705-4',
    roomNo: '705-4',
    width: width(42),
    left: left(870),
    top: top(353),
    fontSize: fontSize(42, 12),
    image: img_705_4_common,
    // selectedImage: img_705_4_selected,
  },
  {
    title: '706-1',
    titleLeft: `${100 * (1058 - 966) / 176}%`,
    titleWidth: 'atuo',
    roomNo: '706-1',
    width: width(176),
    left: left(966),
    top: top(279),
    fontSize: fontSize(176, 16),
    image: img_706_1_common,
    selectedImage: img_706_1_selected,
  },
  {
    title: '706-2',
    roomNo: '706-2',
    width: width(30),
    left: left(986),
    top: top(339),
    fontSize: fontSize(30, 12),
    image: img_706_2_common,
    // selectedImage: img_706_2_selected,
  },
  {
    title: '706-3',
    roomNo: '706-3',
    width: width(50),
    left: left(966),
    top: top(279),
    fontSize: fontSize(50, 12),
    image: img_706_3_common,
    // selectedImage: img_706_3_selected,
  },
  {
    title: '706-4',
    roomNo: '706-4',
    width: width(42),
    left: left(1018),
    top: top(353),
    fontSize: fontSize(42, 12),
    image: img_706_4_common,
    // selectedImage: img_706_4_selected,
  },
  {
    title: '737',
    roomNo: '737',
    width: width(38),
    left: left(946),
    top: top(339),
    fontSize: fontSize(38, 12),
    image: img_737_common,
    selectedImage: img_737_selected,
  },
  {
    title: '707-1',
    titleLeft: `${100 * (1218 - 1144) / 140}%`,
    titleWidth: 'atuo',
    roomNo: '707-1',
    width: width(140),
    left: left(1144),
    top: top(279),
    fontSize: fontSize(140, 16),
    image: img_707_1_common,
    selectedImage: img_707_1_selected,
  },
  {
    title: '707-2',
    roomNo: '707-2',
    width: width(48),
    left: left(1144),
    top: top(319),
    fontSize: fontSize(48, 12),
    image: img_707_2_common,
    // selectedImage: img_707_2_selected,
  },
  {
    title: '707-3',
    roomNo: '707-3',
    width: width(48),
    left: left(1144),
    top: top(279),
    fontSize: fontSize(48, 12),
    image: img_707_3_common,
    // selectedImage: img_707_3_selected,
  },
  {
    title: '新风机房',
    roomNo: '739',
    width: width(100),
    left: left(1184),
    top: top(381),
    fontSize: fontSize(100, 12),
    image: img_739_common,
    selectedImage: img_739_selected,
  },
  {
    title: '708-1',
    titleTop: `${100 * (453 - 421) / 100}%`,
    roomNo: '708-1',
    width: width(100),
    left: left(1184),
    top: top(421),
    fontSize: fontSize(100, 16),
    image: img_708_1_common,
    selectedImage: img_708_1_selected,
  },
  {
    title: '708-2',
    roomNo: '708-2',
    width: width(34),
    left: left(1206),
    top: top(505),
    fontSize: fontSize(34, 12),
    image: img_708_2_common,
    // selectedImage: img_708_2_selected,
  },
  {
    title: '708-3',
    roomNo: '708-3',
    width: width(42),
    left: left(1242),
    top: top(505),
    fontSize: fontSize(42, 12),
    image: img_708_3_common,
    // selectedImage: img_708_3_selected,
  },
  {
    title: '709-1',
    titleLeft: `${100 * (1218 - 1144) / 140}%`,
    titleWidth: 'atuo',
    roomNo: '709-1',
    width: width(140),
    left: left(1144),
    top: top(577),
    fontSize: fontSize(140, 16),
    image: img_709_1_common,
    selectedImage: img_709_1_selected,
  },
  {
    title: '709-2',
    roomNo: '709-2',
    width: width(48),
    left: left(1144),
    top: top(607),
    fontSize: fontSize(48, 12),
    image: img_709_2_common,
    // selectedImage: img_709_2_selected,
  },
  {
    title: '709-3',
    roomNo: '709-3',
    width: width(48),
    left: left(1144),
    top: top(639),
    fontSize: fontSize(48, 12),
    image: img_709_3_common,
    // selectedImage: img_709_3_selected,
  },
  {
    title: '710-1',
    titleLeft: `${100 * (1040 - 1028) / 114}%`,
    titleWidth: 'atuo',
    roomNo: '710-1',
    width: width(114),
    left: left(1028),
    top: top(577),
    fontSize: fontSize(114, 16),
    image: img_710_1_common,
    selectedImage: img_710_1_selected,
  },
  {
    title: '710-2',
    roomNo: '710-2',
    width: width(48),
    left: left(1094),
    top: top(607),
    fontSize: fontSize(48, 12),
    image: img_710_2_common,
    // selectedImage: img_710_2_selected,
  },
  {
    title: '710-3',
    roomNo: '710-3',
    width: width(48),
    left: left(1094),
    top: top(639),
    fontSize: fontSize(48, 12),
    image: img_710_3_common,
    // selectedImage: img_710_3_selected,
  },
  {
    title: '茶水休闲区',
    roomNo: '700-3',
    width: width(114),
    left: left(912),
    top: top(577),
    fontSize: fontSize(114, 16),
    image: img_700_3_common,
    selectedImage: img_700_3_selected,
  },
  {
    title: '712-1',
    titleLeft: `${100 * (856 - 794) / 116}%`,
    titleWidth: 'atuo',
    roomNo: '712-1',
    width: width(116),
    left: left(794),
    top: top(577),
    fontSize: fontSize(116, 16),
    image: img_712_1_common,
    selectedImage: img_712_1_selected,
  },
  {
    title: '712-2',
    roomNo: '712-2',
    width: width(48),
    left: left(794),
    top: top(607),
    fontSize: fontSize(48, 12),
    image: img_712_2_common,
    // selectedImage: img_712_2_selected,
  },
  {
    title: '712-3',
    roomNo: '712-3',
    width: width(48),
    left: left(794),
    top: top(639),
    fontSize: fontSize(48, 12),
    image: img_712_3_common,
    // selectedImage: img_712_3_selected,
  },
  {
    title: '711-1',
    titleLeft: `${100 * (710 - 620) / 172}%`,
    titleWidth: 'atuo',
    roomNo: '711-1',
    width: width(172),
    left: left(620),
    top: top(577),
    fontSize: fontSize(172, 16),
    image: img_711_1_common,
    selectedImage: img_711_1_selected,
  },
  {
    title: '711-2',
    roomNo: '711-2',
    width: width(30),
    left: left(640),
    top: top(577),
    fontSize: fontSize(30, 12),
    image: img_711_2_common,
    // selectedImage: img_711_2_selected,
  },
  {
    title: '711-3',
    roomNo: '711-3',
    width: width(50),
    left: left(620),
    top: top(619),
    fontSize: fontSize(50, 12),
    image: img_711_3_common,
    // selectedImage: img_711_3_selected,
  },
  {
    title: '711-4',
    roomNo: '711-4',
    width: width(42),
    left: left(672),
    top: top(577),
    fontSize: fontSize(42, 12),
    image: img_711_4_common,
    // selectedImage: img_711_4_selected,
  },
  {
    title: '713-1',
    titleLeft: `${100 * (486 - 446) / 172}%`,
    titleWidth: 'atuo',
    roomNo: '713-1',
    width: width(172),
    left: left(446),
    top: top(577),
    fontSize: fontSize(172, 16),
    image: img_713_1_common,
    selectedImage: img_713_1_selected,
  },
  {
    title: '713-2',
    roomNo: '713-2',
    width: width(30),
    left: left(568),
    top: top(577),
    fontSize: fontSize(30, 12),
    image: img_713_2_common,
    // selectedImage: img_713_2_selected,
  },
  {
    title: '713-3',
    roomNo: '713-3',
    width: width(50),
    left: left(568),
    top: top(619),
    fontSize: fontSize(50, 12),
    image: img_713_3_common,
    // selectedImage: img_713_3_selected,
  },
  {
    title: '713-4',
    roomNo: '713-4',
    width: width(42),
    left: left(524),
    top: top(5771),
    fontSize: fontSize(42, 12),
    image: img_713_4_common,
    // selectedImage: img_713_4_selected,
  },
  {
    title: '洽谈室',
    roomNo: '746',
    width: width(38),
    left: left(600),
    top: top(577),
    fontSize: fontSize(38, 12),
    image: img_746_common,
    selectedImage: img_746_selected,
  },
  {
    title: '715-1',
    titleLeft: `${100 * (329 - 303) / 141}%`,
    titleWidth: 'atuo',
    roomNo: '715-1',
    width: width(141),
    left: left(303),
    top: top(577),
    fontSize: fontSize(141, 16),
    image: img_715_1_common,
    // selectedImage: img_715_1_selected,
  },
  {
    title: '715-2',
    roomNo: '715-2',
    width: width(48),
    left: left(396),
    top: top(607),
    fontSize: fontSize(48, 12),
    image: img_715_2_common,
    // selectedImage: img_715_2_selected,
  },
  {
    title: '715-3',
    roomNo: '715-3',
    width: width(48),
    left: left(396),
    top: top(639),
    fontSize: fontSize(48, 12),
    image: img_715_3_common,
    // selectedImage: img_715_3_selected,
  },
  {
    title: '715-4',
    roomNo: '715-4',
    width: width(73),
    left: left(303),
    top: top(545),
    fontSize: fontSize(73, 12),
    image: img_715_4_common,
    // selectedImage: img_715_4_selected,
  },
  {
    title: '749',
    roomNo: '749',
    width: width(18),
    left: left(304),
    top: top(414),
    fontSize: fontSize(18, 12),
    writingMode: 'vertical-lr',
    image: img_749_common,
    selectedImage: img_749_selected,
  },
  {
    title: '730',
    roomNo: '730',
    width: width(48),
    left: left(400),
    top: top(413),
    fontSize: fontSize(48, 12),
    image: img_730_common,
    selectedImage: img_730_selected,
  },
  {
    title: '748',
    roomNo: '748',
    width: width(48),
    left: left(400),
    top: top(473),
    fontSize: fontSize(48, 12),
    image: img_748_common,
    selectedImage: img_748_selected,
  },
  {
    title: '茶水休闲区',
    roomNo: '700-4',
    width: width(82),
    left: left(482),
    top: top(413),
    fontSize: fontSize(82, 16),
    image: img_700_4_common,
    selectedImage: img_700_4_selected,
  },
  {
    title: '储藏室',
    roomNo: '731',
    width: width(50),
    left: left(566),
    top: top(413),
    fontSize: fontSize(50, 12),
    image: img_731_common,
    selectedImage: img_731_selected,
  },
  {
    title: '储藏室',
    roomNo: '747',
    width: width(50),
    left: left(566),
    top: top(487),
    fontSize: fontSize(50, 12),
    image: img_747_common,
    selectedImage: img_747_selected,
  },
  {
    title: '洽谈室',
    roomNo: '734',
    width: width(54),
    left: left(618),
    top: top(413),
    fontSize: fontSize(54, 12),
    image: img_734_common,
    selectedImage: img_734_selected,
  },
  {
    title: '洽谈室',
    roomNo: '745',
    width: width(54),
    left: left(618),
    top: top(487),
    fontSize: fontSize(54, 12),
    image: img_745_common,
    selectedImage: img_745_selected,
  },
  {
    title: '735',
    roomNo: '735',
    width: width(80),
    left: left(674),
    top: top(413),
    fontSize: fontSize(80, 16),
    image: img_735_common,
    selectedImage: img_735_selected,
  },
  {
    title: '744',
    roomNo: '744',
    width: width(80),
    left: left(674),
    top: top(487),
    fontSize: fontSize(80, 16),
    image: img_744_common,
    selectedImage: img_744_selected,
  },
  {
    title: '736',
    roomNo: '736',
    width: width(70),
    left: left(788),
    top: top(413),
    fontSize: fontSize(70, 12),
    image: img_736_common,
    selectedImage: img_736_selected,
  },
  {
    title: '742',
    roomNo: '742',
    width: width(70),
    left: left(788),
    top: top(495),
    fontSize: fontSize(70, 12),
    image: img_742_common,
    selectedImage: img_742_selected,
  },
  {
    title: '743',
    roomNo: '743',
    width: width(36),
    left: left(822),
    top: top(463),
    fontSize: fontSize(36, 12),
    image: img_743_common,
    selectedImage: img_743_selected,
  },
  {
    title: '741',
    roomNo: '741',
    width: width(42),
    left: left(910),
    top: top(511),
    fontSize: fontSize(42, 12),
    image: img_741_common,
    selectedImage: img_741_selected,
  },
  {
    title: '740',
    roomNo: '740',
    width: width(32),
    left: left(1032),
    top: top(494),
    fontSize: fontSize(32, 12),
    image: img_740_common,
    selectedImage: img_740_selected,
  },
  {
    title: '738',
    roomNo: '738',
    width: width(24),
    left: left(1036),
    top: top(413),
    fontSize: fontSize(24, 12),
    writingMode: 'vertical-lr',
    image: img_738_common,
    selectedImage: img_738_selected,
  },
  {
    title: '700-1',
    roomNo: '700-1',
    width: width(904),
    left: left(380),
    top: top(547),
    fontSize: fontSize(904, 12),
    color: '#808080',
    image: img_700_1_common,
    selectedImage: img_700_1_selected,
  },
  {
    title: '700-2',
    roomNo: '700-2',
    width: width(863),
    left: left(317),
    top: top(383),
    fontSize: fontSize(863, 12),
    color: '#808080',
    image: img_700_2_common,
    selectedImage: img_700_2_selected,
  },
  {
    title: '700-5',
    roomNo: '700-5',
    width: width(26),
    left: left(1154),
    top: top(413),
    fontSize: fontSize(26, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_700_5_common,
    selectedImage: img_700_5_selected,
  },
  {
    title: '700-6',
    roomNo: '700-6',
    width: width(29),
    left: left(957),
    top: top(413),
    fontSize: fontSize(29, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_700_6_common,
    selectedImage: img_700_6_selected,
  },
  {
    title: '700-7',
    roomNo: '700-7',
    width: width(26),
    left: left(758),
    top: top(413),
    fontSize: fontSize(26, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_700_7_common,
    selectedImage: img_700_7_selected,
  },
  {
    title: '700-8',
    roomNo: '700-8',
    width: width(26),
    left: left(452),
    top: top(413),
    fontSize: fontSize(26, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_700_8_common,
    selectedImage: img_700_8_selected,
  },
]

export default {
  bg,
  initialRoomList
}