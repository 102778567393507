import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { JobStatus } from "../../types/common";

type EmployeeState = {
  corpId: number;
  corpName: string;
  pageIndex: number;
  pageSize: number;
  jobStatus: JobStatus;
}

const initialState: EmployeeState = {
  corpId: 0,
  corpName: '',
  pageIndex: 1,
  pageSize: 100,
  jobStatus: JobStatus.OnTheJob
}

const employeeSlice = createSlice({
  name: 'emplopyee',
  initialState,
  reducers: {
    setCorpInfo(state, action: PayloadAction<{corpId: number, corpName: string}>) {
      state.corpId = action.payload.corpId
      state.corpName = action.payload.corpName
    },
    setPagination(state, action: PayloadAction<{pageIndex: number, pageSize: number}>) {
      state.pageIndex = action.payload.pageIndex
      state.pageSize = action.payload.pageSize
    },
    setJobStatus(state, action: PayloadAction<JobStatus>) {
      state.jobStatus = action.payload
    },
  }
})

export const { setCorpInfo, setPagination, setJobStatus } = employeeSlice.actions

export default employeeSlice.reducer