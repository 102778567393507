import { useState, useEffect } from 'react';
import { Row, Col, Select, Button, Space, Form, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Title from '@/components/Form/Title';
import Input from '@/components/Form/Input';
// import { DeviceStatusList, NetworkStatusList } from '@/common/constants';
import { ValueAndText } from '@/types/common';
import { addDevice, getDeviceInfo, updateDevice } from '@/services/device';
import { getFloorRoomList } from '@/services/workspace';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`;

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const layout = {
  labelCol: { span: 24 }
}

interface Props {
  type: 'add' | 'edit';
}

const DeviceAdd: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<any>();
  const [floorRoomList, setFloorRoomList] = useState<any>({});
  const deviceStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.deviceStatusList);
  const deviceTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.deviceTypeList);
  const networkStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.networkStatusList);
  const assetTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.assetTypeList);

  const save = (values: any) => {
    console.log(values)
    if (props.type === 'edit') {
      updateDevice({
        ...values,
        deviceId: id
      }).then((res: any) => {
        const { resultCode } = res.data;
        if (resultCode == 0) {
          message.success('设备修改成功');
          navigate(-1)
        }
      })
    }
    else {
      addDevice({
        ...values,
        belongFloor: 4,
      }).then((res: any) => {
        const { resultCode, resultMsg } = res.data;
        if (resultCode === 0) {
          message.success('设备添加成功');
          navigate(-1);
          return;
        }
        message.error(resultMsg);
      })
    }
  }
  const goBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      setFloorRoomList(res.data)
    })

    if (props.type === 'edit') {
      getDeviceInfo(id).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          data.deviceStatus = data.deviceStatus;
          data.networkStatus = data.networkStatus;
          data.deviceType = data.deviceType;
          form.setFieldsValue(data)
        }
      })
    }
  }, [])

  return (
    <>
      <CloseButtonContainer>
        <Button type="text" onClick={() => navigate(-1) }><CloseOutlined /></Button>
      </CloseButtonContainer>
      <Container>
        <Panel>
          <Form {...layout} form={form} onFinish={save}>
            <Title>基本信息</Title>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem
                  label="设备名称"
                  name="deviceName"
                  rules={[{ required: true, message: '请输入设备名称' }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="设备编号"
                  name="deviceNo"
                  rules={[{ required: true, message: '请输入设备编号' }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="型号"
                  name="deviceModel"
                  rules={[{ required: true, message: '请输入型号' }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="关联空间"
                  name="belongRoom"
                  rules={[{ required: true, message: '请选择关联空间' }]}
                >
                  <Select>
                    {
                      Object.keys(floorRoomList).sort((prev: string, next: string) => {
                        return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
                      }).map((floorName: string, floorIndex: number) => (
                        <OptGroup key={`device_edit_floow_${floorIndex}`} label={floorName}>
                          {
                            floorRoomList[floorName].map((roomName: string, roomIndex: number) => (
                              <Option key={`device_edit_${floorIndex}room_${roomIndex}`} value={roomName}>{roomName}</Option>
                            ))
                          }
                        </OptGroup>
                      ))
                    }
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="使用状态"
                  name="deviceStatus"
                  rules={[{ required: true, message: '请选择使用状态' }]}
                >
                  <Select>
                    {
                      deviceStatusList.map((item, index) => (
                        <Option key={`device_add_devicestatus_opt_${index}`} value={item.value}>{item.text}</Option>
                      ))
                    }
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="联网状态"
                  name="networkStatus"
                  rules={[{ required: true, message: '请选择联网状态' }]}
                >
                  <Select>
                    {
                      networkStatusList.map((item, index) => (
                        <Option key={`device_add_networkstatus_opt_${index}`} value={item.value}>{item.text}</Option>
                      ))
                    }
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="设备类型"
                  name="deviceType"
                  rules={[{ required: true, message: '请选择设备类型' }]}
                >
                  <Select>
                    {
                      deviceTypeList.map((item, index) => (
                        <Option key={`device_add_devicetype_opt_${index}`} value={item.value}>{item.text}</Option>
                      ))
                    }
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="资产类型"
                  name="assetType"
                  rules={[{ required: true, message: '请选择资产类型' }]}
                >
                  <Select
                    options={assetTypeList.map(item => ({
                      label: item.text,
                      value: item.value
                    }))}
                  />
                </FormItem>
              </Col>
            </Row>
            <ButtonBar>
              <Space>
                <Button onClick={goBack}>取消</Button>
                <Button type="primary" htmlType="submit">确定</Button>
              </Space>
            </ButtonBar>
          </Form>
        </Panel>
      </Container>
    </>
  )
}

export default DeviceAdd;