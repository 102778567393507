import { useState, useEffect } from "react";
import { Row, Col, Button, Form } from 'antd';
import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Title from '@/components/Form/Title';
import FormItem from '@/components/Form/Item';
import { ValueAndText } from '@/types/common';
import { getSelectedTextFromList } from '@/utils/transform';
import { getDeviceInfo } from '@/services/device';

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`;

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const Content = styled.div`
  background: #F4F5F9;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #1F2329;
  padding: 5px 10px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Detail = () => {
  const { id } = useParams<any>();
  const navigate = useNavigate();
  const [deviceDetail, setDeviceDetail] = useState<any>({});
  const deviceStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.deviceStatusList);
  const deviceTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.deviceTypeList);
  const networkStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.networkStatusList);

  useEffect(() => {
    getDeviceInfo(id ?? '').then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDeviceDetail(data);
      }
    })
  }, [])

  return (
    <>
    <ButtonBar>
      <Button type="text" onClick={() => { navigate(-1) }}><CloseOutlined /></Button>
    </ButtonBar>
      <Container>
        <Panel>
          <TitleContainer>
            <Title>基本信息</Title>
            <Link to={`/device/edit/${id}`}><Button>编辑</Button></Link>
          </TitleContainer>
          <Row gutter={[16, 24]}>
            <Col span={12}>
              <FormItem label="设备名称">
                <Content>{deviceDetail.deviceName}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="设备编号">
                <Content>{deviceDetail.deviceNo}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="型号">
                <Content>{deviceDetail.deviceModel}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="关联空间">
                <Content>{`${deviceDetail.belongFloor} - ${deviceDetail.belongRoom}`}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="使用状态">
                <Content>{getSelectedTextFromList(deviceStatusList, deviceDetail.deviceStatus)}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="联网状态">
                <Content>{getSelectedTextFromList(networkStatusList, deviceDetail.networkStatus)}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="设备类型">
                <Content>{getSelectedTextFromList(deviceTypeList, deviceDetail.deviceType)}</Content>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="服务周期">
                <Content>永久</Content>
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Container>
    </>
  )
}

export default Detail;